import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
    ProfileView,
    useReplyToContentMutation
} from '../../../api/client'
import { isActionApplied } from '../../content/content-card/actions'
import BaseActionButton from './BaseActionButton'

import { useState } from 'react'
import ReplyModal from '../../reply/ReplyModal'
import { toastError } from '../../Notification'

type Props = {
    content: ContentOutcomeView
    onActionClick: (
        contentActions: ContentActionView[],
        queued?: boolean
    ) => void
    profileCache: ProfileView[]
    actionCacheMap: Map<number, ContentActionView[]>
    isMoreButton?: boolean
}

export default function ReplyButton(props: Props) {
    const { content, onActionClick, profileCache, actionCacheMap } = props
    const [open, setOpen] = useState(false)

    const [replyContentMutation, { loading: savingReply }] =
        useReplyToContentMutation({
            onError: (error) => {
                toastError(error.message)
            }
        })

    // Identify the relevant actions from the action cache map
    const allExistingContentActions = actionCacheMap.get(content.id) || []

    const actionAlreadyApplied = isActionApplied(
        allExistingContentActions,
        ContentAction.Reply
    )

    return (
        <>
            <ReplyModal
                open={open}
                setOpen={(open: boolean) => {
                    setOpen(open)
                }}
                content={content}
                onReply={async (message: string) => {
                    const result = await replyContentMutation({
                        variables: {
                            message: message,
                            contentId: content.id
                        }
                    })

                    if (!!!result.errors && result.data?.replyToContent) {
                        onActionClick([result.data?.replyToContent])
                    }
                }}
                savingReply={savingReply}
                onActionClick={onActionClick}
                profileCache={profileCache}
                actionCacheMap={actionCacheMap}
            />

            {props.isMoreButton ? (
                <button
                    className="text-primary hover:text-primary-900"
                    onClick={async () => {
                        setOpen(true)
                    }}
                >
                    more
                </button>
            ) : (
                <BaseActionButton
                    tooltip="Reply"
                    // This button, instead of taking an immediate action, just pens a modal
                    // As such there is no need to display a loading indicator around the button
                    loading={false}
                    colour="blue"
                    actionAlreadyApplied={actionAlreadyApplied}
                    icon={<ChatBubbleLeftRightIcon />}
                    onActionClick={async () => {
                        setOpen(true)
                    }}
                    isActionQueued={false}
                />
            )}
        </>
    )
}
