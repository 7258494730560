import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import useQueryParams from '../hooks/useQueryParams'
import AboutPage from '../pages/AboutPage'
import AddInstagramErrorPage from '../pages/accountOnboarding/AddInstagramErrorPage'
import AuthoriseResponsePage from '../pages/accountOnboarding/AuthoriseResponsePage'
import NewFacebookAccountPage from '../pages/accountOnboarding/NewFacebookAccountPage'
import NewInstagramAccountPage from '../pages/accountOnboarding/NewInstagramAccountPage'
import NewTwitterAccountPage from '../pages/accountOnboarding/NewTwitterAccountPage'
import NewYouTubeAccountPage from '../pages/accountOnboarding/NewYouTubeAccountPage'
import AccountPage from '../pages/AccountPage'
import CustomerPaymentPortalPage from '../pages/CustomerPaymentPortalPage'
import FetchHistoricContentPage from '../pages/FetchHistoricContentPage'
import IntelligencePage from '../pages/IntelligencePage'
import ModerationBoundariesPage from '../pages/ModerationBoundariesPage'
import NotFoundPage from '../pages/NotFoundPage'
import SupportPage from '../pages/SupportPage'
import TeamPage from '../pages/TeamPage'
import {
    canViewIntelligencePageState,
    isUserAdminState,
    userState
} from '../store/DomainStore'
import Sidebar from './Sidebar'

import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import AccountAuthorisationPage from '../pages/AccountAuthorisationPage'
import AuthorisationFailurePage from '../pages/accountOnboarding/AuthorisationFailurePage'
import NewAccountAuthorisedPage from '../pages/accountOnboarding/NewAccountAuthorisedPage'
import NewAccountPage from '../pages/accountOnboarding/NewAccountPage'
import NewTikApiAccountPage from '../pages/accountOnboarding/NewTikApiAccountPage'
import NewTikTokAccountPage from '../pages/accountOnboarding/NewTikTokAccountPage'
import AccountSettingsPage from '../pages/AccountSettingsPage'
import AccountShadowBanningSettingsPage from '../pages/AccountShadowBanningSettingsPage'
import AccountValidationPage from '../pages/AccountValidationPage'
import CommenterListPage from '../pages/admin/CommenterListPage'
import KeywordCreatePage from '../pages/admin/KeywordCreatePage'
import KeywordListPage from '../pages/admin/KeywordListPage'
import LlmReplySettingsPage from '../pages/admin/prompt/LlmReplySettingsPage'
import PromptCreatePage from '../pages/admin/prompt/PromptCreatePage'
import PromptEditPage from '../pages/admin/prompt/PromptEditPage'
import SpamModelPage from '../pages/admin/spam/SpamModelPage'
import TeamCreatePage from '../pages/admin/TeamCreatePage'
import TeamEditPage from '../pages/admin/TeamEditPage'
import TeamListPage from '../pages/admin/TeamListPage'
import UserCreatePage from '../pages/admin/UserCreatePage'
import UserEditPage from '../pages/admin/UserEditPage'
import UserEventsPage from '../pages/admin/UserEventsPage'
import UserListPage from '../pages/admin/UserListPage'
import RevokeAuthorisationPage from '../pages/authorisation/revocation/RevokeAuthorisationPage'
import {
    default as ContentSearchPage,
    default as ModeratePage
} from '../pages/ContentSearchPage'
import InstagramProfileConfigurationHelpPage from '../pages/InstagramProfileConfigurationHelpPage'
import PromptTestingPage from '../pages/PromptTestingPage'
import ReportFormPage from '../pages/reporting/ReportFormPage'
import ReportListPage from '../pages/reporting/ReportListPage'
import ReportViewPage from '../pages/reporting/ReportViewPage'
import { Tooltip } from 'react-tooltip'
import HomePage from '../pages/HomePage'
import ContentFeedPage from '../pages/ContentFeedPage'
import ContentPage from '../pages/ContentPage'

export default function Main() {
    const location = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        // We use a HashRouter which isn't really supported by the Hosted UI used for SSO.
        // Following auth the Hosted UI redirects back to the main app with a token in the URL.
        // The Cognito/Amplify libs pick this up and use it for future auth but the resulting
        // URL confuses the HashRouter. This is a workaround to redirect to the correct URL.
        if (!location.pathname.startsWith('/')) {
            console.log('Redirecting to /')

            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const query = useQueryParams()
    const [admin] = useRecoilState(isUserAdminState)
    const [intelligencePageEnabled] = useRecoilState(
        canViewIntelligencePageState
    )

    const homepage = function () {
        if (
            hasTwitterAuthParams() ||
            hasFacebookAuthParams() ||
            hasYouTubeAuthParams() ||
            hasTikTokAdAuthParams() ||
            hasTikTokUserAuthParams()
        ) {
            return <AuthoriseResponsePage />
        } else {
            return <HomePage />
        }
    }

    const hasTwitterAuthParams = function () {
        return query.get('accountId') && query.get('source')
    }

    const hasFacebookAuthParams = function () {
        return query.get('state') && query.get('code')
    }

    const hasYouTubeAuthParams = function () {
        return query.get('scope') && query.get('code')
    }

    const hasTikTokAdAuthParams = function () {
        return query.get('auth_code') && query.get('code')
    }

    const hasTikTokUserAuthParams = function () {
        return query.get('scopes') && query.get('code')
    }

    const [user] = useRecoilState(userState)

    return (
        <>
            <Sidebar>
                <div>
                    <Toaster />
                </div>
                <div
                    className="main bg-gradient-to-br to-gray-200"
                    style={{ overflowX: 'auto' }}
                >
                    <main
                        className="stretchy py-0"
                        style={{
                            display: 'flex',
                            flexGrow: '1'
                        }}
                    >
                        <Routes>
                            {user.shouldProvisionAccess && (
                                <Route>
                                    <Route
                                        path="/"
                                        element={homepage()}
                                    ></Route>
                                    <Route
                                        path="/content/:contentId"
                                        element={<ContentPage />}
                                    />
                                    <Route
                                        path="/content-search"
                                        element={<ModeratePage />}
                                    ></Route>
                                    <Route path="/account">
                                        <Route path=":accountId">
                                            <Route
                                                index
                                                element={<AccountPage />}
                                            ></Route>
                                            {admin ? (
                                                <Route path="keywords">
                                                    <Route
                                                        index
                                                        element={
                                                            <KeywordListPage />
                                                        }
                                                    ></Route>
                                                    <Route
                                                        path="create"
                                                        element={
                                                            <KeywordCreatePage />
                                                        }
                                                    />
                                                </Route>
                                            ) : null}
                                            {admin ? (
                                                <Route
                                                    path="moderation-boundaries"
                                                    element={
                                                        <ModerationBoundariesPage />
                                                    }
                                                />
                                            ) : null}
                                            {admin ? (
                                                <Route
                                                    path="fetch-historic"
                                                    element={
                                                        <FetchHistoricContentPage />
                                                    }
                                                />
                                            ) : null}

                                            {admin ? (
                                                <Route path="settings">
                                                    <Route
                                                        index
                                                        element={
                                                            <AccountSettingsPage />
                                                        }
                                                    ></Route>
                                                    <Route
                                                        path="prompt/:promptId"
                                                        element={
                                                            <PromptEditPage category="classify" />
                                                        }
                                                    ></Route>
                                                    <Route
                                                        path="prompt/create"
                                                        element={
                                                            <PromptCreatePage />
                                                        }
                                                    ></Route>
                                                    <Route
                                                        path="llm-reply"
                                                        element={
                                                            <LlmReplySettingsPage />
                                                        }
                                                    />
                                                    <Route
                                                        path="validation"
                                                        element={
                                                            <AccountValidationPage />
                                                        }
                                                    />
                                                    <Route
                                                        path="shadow-banning"
                                                        element={
                                                            <AccountShadowBanningSettingsPage />
                                                        }
                                                    />
                                                </Route>
                                            ) : null}

                                            {/* SOMEDAY: we really want a single page to cover all authorisation issues. 
                                        So we'll want to fold this into AuthoriseAccountPage */}
                                            <Route
                                                path="authorisation/revoke"
                                                element={
                                                    <RevokeAuthorisationPage />
                                                }
                                            />
                                            <Route
                                                path="authorisation"
                                                element={
                                                    <AccountAuthorisationPage />
                                                }
                                            />
                                            <Route
                                                path="unauthorised"
                                                element={
                                                    <AuthorisationFailurePage />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={`new/twitter`}
                                            element={<NewTwitterAccountPage />}
                                        ></Route>
                                        <Route
                                            path={`new/instagram`}
                                            element={
                                                <NewInstagramAccountPage />
                                            }
                                        ></Route>
                                        <Route
                                            path={`new/facebook_page`}
                                            element={<NewFacebookAccountPage />}
                                        ></Route>
                                        <Route
                                            path={`new/youtube`}
                                            element={<NewYouTubeAccountPage />}
                                        ></Route>
                                        <Route
                                            path={`new/tiktok_ad`}
                                            element={
                                                <NewTikTokAccountPage
                                                    isAdsPage
                                                />
                                            }
                                        ></Route>
                                        <Route
                                            path={`new/tiktok_user`}
                                            element={<NewTikTokAccountPage />}
                                        ></Route>
                                        <Route
                                            path={`new/tikapi`}
                                            element={<NewTikApiAccountPage />}
                                        ></Route>
                                        <Route
                                            path="new/authorised"
                                            element={
                                                <NewAccountAuthorisedPage />
                                            }
                                        />
                                        {/* This "new" route needs to come after the "new/<platform>" routes */}
                                        <Route
                                            path="new"
                                            element={<NewAccountPage />}
                                        ></Route>
                                    </Route>

                                    <Route
                                        path="/content-search"
                                        element={<ContentSearchPage />}
                                    />
                                    <Route
                                        path="/content-search/feed/:contentFeedId"
                                        element={<ContentFeedPage />}
                                    />

                                    <Route
                                        path={`/team`}
                                        element={<TeamPage />}
                                    ></Route>

                                    {intelligencePageEnabled || admin ? (
                                        <Route
                                            path={'/engage'}
                                            element={<IntelligencePage />}
                                        ></Route>
                                    ) : null}

                                    {user.dashboardEnabled ? (
                                        <Route path="/report">
                                            <Route
                                                index
                                                element={<ReportListPage />}
                                            />
                                            <Route
                                                path="new"
                                                element={<ReportFormPage />}
                                            />
                                            <Route
                                                path=":dashboardId"
                                                element={<ReportViewPage />}
                                            ></Route>
                                            <Route
                                                path=":dashboardId/edit"
                                                element={<ReportFormPage />}
                                            ></Route>
                                        </Route>
                                    ) : null}

                                    {admin ? (
                                        <>
                                            <Route
                                                path={`/spam-model`}
                                                element={<SpamModelPage />}
                                            ></Route>
                                            <Route path={`/admin`}>
                                                <Route
                                                    path={`team`}
                                                    element={<TeamListPage />}
                                                ></Route>
                                                <Route
                                                    path={`team/create`}
                                                    element={<TeamCreatePage />}
                                                ></Route>
                                                {/* SOMEDAY: should be team/:teamId/edit */}
                                                <Route
                                                    path={`team/edit/:teamId`}
                                                    element={<TeamEditPage />}
                                                ></Route>
                                                <Route
                                                    path={`user/`}
                                                    element={<UserListPage />}
                                                ></Route>
                                                <Route
                                                    path={`user/create`}
                                                    element={<UserCreatePage />}
                                                ></Route>
                                                {/* SOMEDAY: should be user/:userId/edit */}
                                                <Route
                                                    path={`user/edit/:userId`}
                                                    element={<UserEditPage />}
                                                ></Route>
                                                <Route
                                                    path={`user/events/:userId`}
                                                    element={<UserEventsPage />}
                                                ></Route>
                                                <Route
                                                    path={`commenter/`}
                                                    element={
                                                        <CommenterListPage />
                                                    }
                                                ></Route>
                                                <Route
                                                    path={`prompt-testing/`}
                                                    element={
                                                        <PromptTestingPage />
                                                    }
                                                ></Route>
                                            </Route>
                                        </>
                                    ) : null}

                                    <Route
                                        path={`/customer-payment-portal`}
                                        element={<CustomerPaymentPortalPage />}
                                    ></Route>

                                    <Route
                                        path="/error-adding-instagram"
                                        element={<AddInstagramErrorPage />}
                                    ></Route>

                                    <Route
                                        path="not-found"
                                        element={<NotFoundPage />}
                                    />
                                    <Route
                                        path="*"
                                        element={<NotFoundPage />}
                                    />
                                </Route>
                            )}

                            <Route
                                path="/about"
                                element={<AboutPage />}
                            ></Route>
                            <Route
                                path="/support/instagram-profile-configuration/"
                                element={
                                    <InstagramProfileConfigurationHelpPage />
                                }
                            ></Route>
                            <Route
                                path="/support"
                                element={<SupportPage />}
                            ></Route>
                        </Routes>
                    </main>
                </div>
            </Sidebar>
            <Tooltip
                id="tooltip-id"
                delayShow={300}
                className="overflow-hidden"
            />
        </>
    )
}
