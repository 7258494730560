import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never
      }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    AWSDateTime: { input: any; output: any }
    AWSJSON: { input: any; output: any }
    AWSPhone: { input: any; output: any }
    /**
     * Implement the DateTime<Utc> scalar
     *
     * The input/output is a string in RFC3339 format.
     */
    DateTime: { input: any; output: any }
    /** A scalar that can represent any JSON Object value. */
    JSONObject: { input: any; output: any }
    /**
     * ISO 8601 calendar date without timezone.
     * Format: %Y-%m-%d
     *
     * # Examples
     *
     * * `1994-11-13`
     * * `2000-02-24`
     */
    NaiveDate: { input: any; output: any }
    /**
     * ISO 8601 combined date and time without timezone.
     *
     * # Examples
     *
     * * `2015-07-01T08:59:60.123`,
     */
    NaiveDateTime: { input: any; output: any }
}

export type Account = {
    __typename?: 'Account'
    accountName?: Maybe<Scalars['String']['output']>
    adsLastFetched?: Maybe<Scalars['AWSDateTime']['output']>
    authorised: Scalars['Boolean']['output']
    autoDeleteSevereContent: Scalars['Boolean']['output']
    autoFetchContent: Scalars['Boolean']['output']
    autoHideAdComments?: Maybe<Scalars['Boolean']['output']>
    autoHideSevereContent: Scalars['Boolean']['output']
    autoMuteSevereAccount: Scalars['Boolean']['output']
    createdOn?: Maybe<Scalars['AWSDateTime']['output']>
    fetchAds: Scalars['Boolean']['output']
    fetchPosts: Scalars['Boolean']['output']
    id: Scalars['Int']['output']
    intelligenceEnabled: Scalars['Boolean']['output']
    isAuthorisationFailing?: Maybe<Scalars['Boolean']['output']>
    isSubscribed: Scalars['Boolean']['output']
    lastFetched?: Maybe<Scalars['AWSDateTime']['output']>
    llmClassificationEnabled: Scalars['Boolean']['output']
    llmEnableSuggestedReplies: Scalars['Boolean']['output']
    ownedBy?: Maybe<Scalars['String']['output']>
    postsLastFetched?: Maybe<Scalars['AWSDateTime']['output']>
    profileCollectionEnabled: Scalars['Boolean']['output']
    profileModerationEnabled: Scalars['Boolean']['output']
    scrapeContent: Scalars['Boolean']['output']
    serviceAccountId: Scalars['String']['output']
    serviceAccountUsername?: Maybe<Scalars['String']['output']>
    socialMediaService: SocialMediaService
    team?: Maybe<Team>
}

export type AccountConnection = {
    __typename?: 'AccountConnection'
    items: Array<Account>
    total: Scalars['Int']['output']
}

/** Maps to `AccountRecord` */
export type AccountRecord = {
    __typename?: 'AccountRecord'
    accountName?: Maybe<Scalars['String']['output']>
    adsLastFetched?: Maybe<Scalars['NaiveDateTime']['output']>
    autoDeleteSevereContent: Scalars['Boolean']['output']
    autoHideAllAdComments?: Maybe<Scalars['Boolean']['output']>
    autoHideSevereContent: Scalars['Boolean']['output']
    autoMuteSevereAccount: Scalars['Boolean']['output']
    classifyContent: Scalars['Boolean']['output']
    createdOn: Scalars['DateTime']['output']
    fetchAds: Scalars['Boolean']['output']
    fetchContent: Scalars['Boolean']['output']
    fetchPosts: Scalars['Boolean']['output']
    hasAuthorisation: Scalars['Boolean']['output']
    id: Scalars['Int']['output']
    intelligenceEnabled: Scalars['Boolean']['output']
    isSubscribed: Scalars['Boolean']['output']
    lastFetched?: Maybe<Scalars['DateTime']['output']>
    latestFetchedServiceContentId?: Maybe<Scalars['String']['output']>
    llmClassificationEnabled: Scalars['Boolean']['output']
    llmEnableSuggestedReplies: Scalars['Boolean']['output']
    ownedBy?: Maybe<Scalars['String']['output']>
    pageId?: Maybe<Scalars['String']['output']>
    postsLastFetched?: Maybe<Scalars['NaiveDateTime']['output']>
    profileCollectionEnabled: Scalars['Boolean']['output']
    profileModerationEnabled: Scalars['Boolean']['output']
    scrapeContent: Scalars['Boolean']['output']
    serviceAccountId: Scalars['String']['output']
    serviceAccountUsername?: Maybe<Scalars['String']['output']>
    shadowBanningEnabled: Scalars['Boolean']['output']
    shadowBanningRangeInDays: Scalars['Int']['output']
    shadowBanningThreshold: Scalars['Int']['output']
    socialMediaServiceDisplayName?: Maybe<Scalars['String']['output']>
    socialMediaServiceId: Scalars['Int']['output']
    socialMediaServiceShortName?: Maybe<Scalars['String']['output']>
    teamId?: Maybe<Scalars['Int']['output']>
    teamName?: Maybe<Scalars['String']['output']>
    updatedOn: Scalars['DateTime']['output']
    userId: Scalars['Int']['output']
}

export type AddYouTubeSubcription = {
    __typename?: 'AddYouTubeSubcription'
    success?: Maybe<Scalars['Boolean']['output']>
}

export enum AdsOrOrganicFilterView {
    Ads = 'ADS',
    Both = 'BOTH',
    Organic = 'ORGANIC'
}

export enum AuthorisationStatusFilter {
    All = 'ALL',
    AuthorisationFailing = 'AUTHORISATION_FAILING',
    Authorised = 'AUTHORISED',
    Unauthorised = 'UNAUTHORISED'
}

/**
 * This represents an authorised account. On the server-side this struct incldues
 * the tokens, but this struct is a view of that, and it goes to the browser, so it
 * must not include the tokens, which are secrets.
 */
export type AuthorisedAccountView = {
    __typename?: 'AuthorisedAccountView'
    accountName?: Maybe<Scalars['String']['output']>
    failureDate?: Maybe<Scalars['NaiveDateTime']['output']>
    id: Scalars['Int']['output']
    isFailing: Scalars['Boolean']['output']
    lastFailureNotificationDate?: Maybe<Scalars['NaiveDateTime']['output']>
    pageId?: Maybe<Scalars['String']['output']>
    serviceAccountId: Scalars['String']['output']
    serviceAccountUsername?: Maybe<Scalars['String']['output']>
    socialMediaServiceName: Scalars['String']['output']
}

export type BooleanFilterInput = {
    eq?: InputMaybe<Scalars['Boolean']['input']>
    ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type ClassificationResult = {
    __typename?: 'ClassificationResult'
    classification: Scalars['Float']['output']
    classificationServiceResponse?: Maybe<ClassificationServiceResponse>
    classifier?: Maybe<ClassifierDetails>
    contentId: Scalars['Int']['output']
    id: Scalars['Int']['output']
    severity?: Maybe<Severity>
}

export type ClassificationResultsConnection = {
    __typename?: 'ClassificationResultsConnection'
    result?: Maybe<Array<ClassificationResult>>
}

export type ClassificationService = {
    __typename?: 'ClassificationService'
    displayName: Scalars['String']['output']
    id: Scalars['Int']['output']
    longDescription?: Maybe<Scalars['String']['output']>
    shortDescription?: Maybe<Scalars['String']['output']>
    shortName: Scalars['String']['output']
}

export type ClassificationServiceResponse = {
    __typename?: 'ClassificationServiceResponse'
    classificationDate: Scalars['AWSDateTime']['output']
    classificationService?: Maybe<ClassificationService>
    id: Scalars['Int']['output']
    moderationCompleted: Scalars['Boolean']['output']
}

export type ClassifierDetails = {
    __typename?: 'ClassifierDetails'
    displayName: Scalars['String']['output']
    id?: Maybe<Scalars['Int']['output']>
    longDescription?: Maybe<Scalars['String']['output']>
    shortDescription: Scalars['String']['output']
    shortName: Scalars['String']['output']
}

export type ClassifierSearchParamsInput = {
    category?: InputMaybe<Scalars['String']['input']>
    classifierName: Scalars['String']['input']
    threshold?: InputMaybe<Scalars['Float']['input']>
}

export type ClassifierSearchParamsView = {
    __typename?: 'ClassifierSearchParamsView'
    category?: Maybe<Scalars['String']['output']>
    classifierName: Scalars['String']['output']
    threshold?: Maybe<Scalars['Float']['output']>
}

export type ClassifierView = {
    __typename?: 'ClassifierView'
    displayName: Scalars['String']['output']
    id: Scalars['Int']['output']
    longDescription: Scalars['String']['output']
    shortDescription: Scalars['String']['output']
    shortName: Scalars['String']['output']
}

export type Content = {
    __typename?: 'Content'
    account?: Maybe<Account>
    accountId: Scalars['Int']['output']
    actions: Array<ContentAction>
    classificationResults?: Maybe<Array<Maybe<ClassificationResult>>>
    content: Scalars['String']['output']
    datePosted: Scalars['AWSDateTime']['output']
    id: Scalars['Int']['output']
    inReplyToStatusId?: Maybe<Scalars['String']['output']>
    inReplyToUserId?: Maybe<Scalars['String']['output']>
    inReplyToUsername?: Maybe<Scalars['String']['output']>
    is_owned?: Maybe<Scalars['Boolean']['output']>
    media: Array<ContentMedia>
    moderations: Array<Moderation>
    originalPostAuthorId?: Maybe<Scalars['String']['output']>
    originalPostAuthorName?: Maybe<Scalars['String']['output']>
    originalPostId?: Maybe<Scalars['String']['output']>
    originalPostLink?: Maybe<Scalars['String']['output']>
    originalPostLinkId?: Maybe<Scalars['String']['output']>
    public: Scalars['Boolean']['output']
    queuedActions: Array<ContentAction>
    serviceAuthorId?: Maybe<Scalars['String']['output']>
    serviceAuthorName?: Maybe<Scalars['String']['output']>
    serviceAuthorUsername?: Maybe<Scalars['String']['output']>
    serviceContentId: Scalars['String']['output']
}

export enum ContentAction {
    Block = 'BLOCK',
    ConfirmSpam = 'CONFIRM_SPAM',
    Delete = 'DELETE',
    Dismiss = 'DISMISS',
    Hide = 'HIDE',
    Like = 'LIKE',
    Mute = 'MUTE',
    Reply = 'REPLY',
    Report = 'REPORT',
    Unblock = 'UNBLOCK'
}

export type ContentActionBusiness = {
    __typename?: 'ContentActionBusiness'
    action: Scalars['String']['output']
    actionOn: Scalars['AWSDateTime']['output']
    active: Scalars['Boolean']['output']
    automated: Scalars['Boolean']['output']
    contentId: Scalars['Int']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id?: Maybe<Scalars['Int']['output']>
    message?: Maybe<Scalars['String']['output']>
    queued: Scalars['Boolean']['output']
    retryAt?: Maybe<Scalars['AWSDateTime']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    userId?: Maybe<Scalars['Int']['output']>
}

export type ContentActionResult = {
    __typename?: 'ContentActionResult'
    contentAction?: Maybe<ContentActionView>
    contentId: Scalars['Int']['output']
    message?: Maybe<Scalars['String']['output']>
    success: Scalars['Boolean']['output']
}

export type ContentActionView = {
    __typename?: 'ContentActionView'
    action: Scalars['String']['output']
    actionOn: Scalars['NaiveDateTime']['output']
    active: Scalars['Boolean']['output']
    automated: Scalars['Boolean']['output']
    contentId: Scalars['Int']['output']
    id: Scalars['Int']['output']
    message?: Maybe<Scalars['String']['output']>
    queued: Scalars['Boolean']['output']
    retryAt?: Maybe<Scalars['NaiveDateTime']['output']>
    userId?: Maybe<Scalars['Int']['output']>
}

export type ContentClassificationView = {
    __typename?: 'ContentClassificationView'
    classification?: Maybe<Scalars['Float']['output']>
    classifierName: Scalars['String']['output']
    severity?: Maybe<Scalars['String']['output']>
}

/**
 * This struct is used to represent the content classifier metadata in the GraphQL schema.
 *
 * Not all classifiers are relevant or used - those we ignore. This list was generated by David and Dan
 */
export type ContentClassifierMetadataView = {
    __typename?: 'ContentClassifierMetadataView'
    classifierDisplayName: Scalars['String']['output']
    classifierId: Scalars['Int']['output']
    classifierName: Scalars['String']['output']
    contentCount: Scalars['Int']['output']
}

export type ContentConnection = {
    __typename?: 'ContentConnection'
    items?: Maybe<Array<Content>>
}

export type ContentFeedCreate = {
    description?: InputMaybe<Scalars['String']['input']>
    displayName: Scalars['String']['input']
    searchParameters: SearchParamsForContentOutcome
    userId?: InputMaybe<Scalars['Int']['input']>
}

export type ContentFeedUpdate = {
    description?: InputMaybe<Scalars['String']['input']>
    displayName?: InputMaybe<Scalars['String']['input']>
    id: Scalars['Int']['input']
    searchParameters?: InputMaybe<SearchParamsForContentOutcome>
    userId?: InputMaybe<Scalars['Int']['input']>
}

export type ContentFeedView = {
    __typename?: 'ContentFeedView'
    createdAt: Scalars['DateTime']['output']
    description?: Maybe<Scalars['String']['output']>
    displayName: Scalars['String']['output']
    id: Scalars['Int']['output']
    searchParameters: ContentSearchParamsView
    updatedAt: Scalars['DateTime']['output']
    userId: Scalars['Int']['output']
}

export type ContentFilterInput = {
    accountIds: Array<Scalars['Int']['input']>
    endDateTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    moderation?: InputMaybe<Scalars['String']['input']>
    socialMediaServiceIds: Array<Scalars['Int']['input']>
    startDateTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type ContentMedia = {
    __typename?: 'ContentMedia'
    mediaClassifications?: Maybe<Array<Maybe<MediaClassification>>>
    mediaType?: Maybe<Scalars['String']['output']>
    publicUrl?: Maybe<Scalars['String']['output']>
    serviceMediaId?: Maybe<Scalars['String']['output']>
}

/**
 * The enums attached to this struct and it's members aren't seralizable
 * by Apollo, so we're going to send them as Strings.
 */
export type ContentMediaView = {
    __typename?: 'ContentMediaView'
    mediaClassifications: Array<ContentClassificationView>
    mediaType: Scalars['String']['output']
    publicUrl: Scalars['String']['output']
    serviceMediaId: Scalars['String']['output']
}

/**
 * This is the model we use when displaying lists of content, i.e. in
 * `frontend/src/components/content/ContentList.tsx`
 *
 * To the frontend it's just `Content`, but that name is currently (2023-09-28)
 * taken by the TypeScript GraphQL model. So for now it's `ContentView`.
 * SOMEDAY: after moving some logic to the server (e.g. link generation) perhaps we
 * can get rid of some of these properties? Review them all again. See
 * here for the original items: frontend/src/components/content/ContentCard.tsx
 */
export type ContentOutcomeView = {
    __typename?: 'ContentOutcomeView'
    account: AccountRecord
    actions: Array<ContentActionView>
    allClassifications: Array<ContentClassificationView>
    authorProfileId?: Maybe<Scalars['Int']['output']>
    authorised: Scalars['Boolean']['output']
    content: Scalars['String']['output']
    contentType: ContentTypeView
    datePosted?: Maybe<Scalars['NaiveDateTime']['output']>
    id: Scalars['Int']['output']
    inReplyToStatusId?: Maybe<Scalars['String']['output']>
    inReplyToUserId?: Maybe<Scalars['String']['output']>
    inReplyToUsername?: Maybe<Scalars['String']['output']>
    isOwned?: Maybe<Scalars['Boolean']['output']>
    isPublic: Scalars['Boolean']['output']
    isReply: Scalars['Boolean']['output']
    media: Array<ContentMediaView>
    moderation?: Maybe<Scalars['String']['output']>
    originalPostAuthorId?: Maybe<Scalars['String']['output']>
    originalPostAuthorName?: Maybe<Scalars['String']['output']>
    originalPostId?: Maybe<Scalars['String']['output']>
    originalPostLink?: Maybe<Scalars['String']['output']>
    originalPostLinkId?: Maybe<Scalars['String']['output']>
    readReceipts: Array<ReadReceiptView>
    serviceAuthorDisplayUsername?: Maybe<Scalars['String']['output']>
    serviceAuthorId?: Maybe<Scalars['String']['output']>
    serviceAuthorName?: Maybe<Scalars['String']['output']>
    serviceContentId: Scalars['String']['output']
    shouldEnrich: Scalars['Boolean']['output']
    socialMediaAuthorLink?: Maybe<Scalars['String']['output']>
    socialMediaContentLink?: Maybe<Scalars['String']['output']>
    socialMediaContentName: Scalars['String']['output']
    socialMediaPostLink?: Maybe<Scalars['String']['output']>
    socialMediaServiceName: Scalars['String']['output']
}

/** This is the output parameter list associated with the GraphQL endpoints. It must remain a subset of `ContentSearchParamsRecord` and ideally be identical. We require this and ContentSearchParamsInput because you cannot implement SimpleObject and InputObject on the same struct. */
export type ContentSearchParamsView = {
    __typename?: 'ContentSearchParamsView'
    accountIds?: Maybe<Array<Scalars['Int']['output']>>
    adsOrOrganic?: Maybe<AdsOrOrganicFilterView>
    before?: Maybe<Scalars['DateTime']['output']>
    classifierSearchParams?: Maybe<Array<ClassifierSearchParamsView>>
    containsText?: Maybe<Scalars['String']['output']>
    contentIds?: Maybe<Array<Scalars['Int']['output']>>
    maxResults?: Maybe<Scalars['Int']['output']>
    moderationCode?: Maybe<Array<Scalars['Int']['output']>>
    moderationString?: Maybe<Array<Scalars['String']['output']>>
    owned?: Maybe<Scalars['Boolean']['output']>
    readFilter?: Maybe<ReadFilterView>
    since?: Maybe<Scalars['DateTime']['output']>
    skipResults?: Maybe<Scalars['Int']['output']>
    socialMediaServiceIds?: Maybe<Array<Scalars['Int']['output']>>
    teamIds?: Maybe<Array<Scalars['Int']['output']>>
    timeDuration?: Maybe<Scalars['String']['output']>
    timeOffset?: Maybe<Scalars['String']['output']>
}

/** A summary of content with the usual severe/suspect/safe buckets */
export type ContentSummary = {
    __typename?: 'ContentSummary'
    countSafe: Scalars['Int']['output']
    countSevere: Scalars['Int']['output']
    countSuspect: Scalars['Int']['output']
}

export type ContentToEnrich = {
    accountId: Scalars['Int']['input']
    id: Scalars['Int']['input']
    originalPostId?: InputMaybe<Scalars['String']['input']>
    originalPostLinkId?: InputMaybe<Scalars['String']['input']>
    serviceAuthorId?: InputMaybe<Scalars['String']['input']>
    serviceAuthorName?: InputMaybe<Scalars['String']['input']>
    serviceAuthorUsername?: InputMaybe<Scalars['String']['input']>
    serviceContentId: Scalars['String']['input']
    socialMediaServiceShortName: Scalars['String']['input']
}

export enum ContentTypeView {
    FacebookAdComment = 'FACEBOOK_AD_COMMENT',
    FacebookPagePostComment = 'FACEBOOK_PAGE_POST_COMMENT',
    InstagramAdComment = 'INSTAGRAM_AD_COMMENT',
    InstagramPostComment = 'INSTAGRAM_POST_COMMENT',
    TikTokAdComment = 'TIK_TOK_AD_COMMENT',
    TikTokUserVideoComment = 'TIK_TOK_USER_VIDEO_COMMENT',
    TwitterPostReply = 'TWITTER_POST_REPLY',
    Unknown = 'UNKNOWN',
    YouTubeVideoComment = 'YOU_TUBE_VIDEO_COMMENT'
}

export type CountContentConnection = {
    __typename?: 'CountContentConnection'
    total?: Maybe<Scalars['Int']['output']>
}

export type CreateKeywordParamsView = {
    accountId: Scalars['Int']['input']
    allowableRegex?: InputMaybe<Scalars['String']['input']>
    classifierDetailId: Scalars['Int']['input']
    description?: InputMaybe<Scalars['String']['input']>
    keyword: Scalars['String']['input']
    regex: Scalars['Boolean']['input']
}

export type CustomerPaymentPortal = {
    __typename?: 'CustomerPaymentPortal'
    url?: Maybe<Scalars['String']['output']>
}

export type DashboardView = {
    __typename?: 'DashboardView'
    createdAt: Scalars['DateTime']['output']
    description: Scalars['String']['output']
    displayName: Scalars['String']['output']
    extEmbeddedId: Scalars['String']['output']
    id: Scalars['Int']['output']
    updatedAt: Scalars['DateTime']['output']
}

export type DateMapView = {
    __typename?: 'DateMapView'
    contentCount: Scalars['Int']['output']
    date: Scalars['NaiveDate']['output']
}

export type DebugTokenResponseView = {
    __typename?: 'DebugTokenResponseView'
    appId: Scalars['String']['output']
    application: Scalars['String']['output']
    dataAccessExpiresAt: Scalars['Int']['output']
    dataType: Scalars['String']['output']
    expiresAt: Scalars['Int']['output']
    isValid: Scalars['Boolean']['output']
    issuedAt?: Maybe<Scalars['Int']['output']>
    scopes: Array<Scalars['String']['output']>
    userId: Scalars['String']['output']
}

export type DetectedLanguageView = {
    __typename?: 'DetectedLanguageView'
    detectedSourceLanguage?: Maybe<Scalars['String']['output']>
    sourceText: Scalars['String']['output']
}

export type EditableDashboardView = {
    __typename?: 'EditableDashboardView'
    dashboard: DashboardView
    users: Array<UserView>
}

export type EmbeddedDashboardView = {
    __typename?: 'EmbeddedDashboardView'
    dashboard: DashboardView
    guestToken: Scalars['String']['output']
    workspaceUrl: Scalars['String']['output']
}

export type EnrichedContent = {
    __typename?: 'EnrichedContent'
    id: Scalars['Int']['output']
    originalPostId?: Maybe<Scalars['String']['output']>
    originalPostLinkId?: Maybe<Scalars['String']['output']>
    serviceAuthorName?: Maybe<Scalars['String']['output']>
    serviceAuthorUsername?: Maybe<Scalars['String']['output']>
}

export type FetchUserDetailsConnection = {
    __typename?: 'FetchUserDetailsConnection'
    error?: Maybe<FetchUserDetailsErrorDetails>
    userDetails?: Maybe<FetchedUserDetails>
}

export enum FetchUserDetailsError {
    UserNotFound = 'USER_NOT_FOUND'
}

export type FetchUserDetailsErrorDetails = {
    __typename?: 'FetchUserDetailsErrorDetails'
    message?: Maybe<Scalars['String']['output']>
    type?: Maybe<FetchUserDetailsError>
}

export type FetchedUserDetails = {
    __typename?: 'FetchedUserDetails'
    name?: Maybe<Scalars['String']['output']>
    serviceAccountId?: Maybe<Scalars['String']['output']>
    username?: Maybe<Scalars['String']['output']>
}

export type FloatFilterInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
    contains?: InputMaybe<Scalars['Float']['input']>
    eq?: InputMaybe<Scalars['Float']['input']>
    ge?: InputMaybe<Scalars['Float']['input']>
    gt?: InputMaybe<Scalars['Float']['input']>
    le?: InputMaybe<Scalars['Float']['input']>
    lt?: InputMaybe<Scalars['Float']['input']>
    ne?: InputMaybe<Scalars['Float']['input']>
    notContains?: InputMaybe<Scalars['Float']['input']>
}

export type GetAccountsInTeamParamsView = {
    teamId: Scalars['Int']['input']
}

/** Params for filtering content for calculating summary metrics. */
export type GetSummaryMetricsParams = {
    accountIds: Array<Scalars['Int']['input']>
    classifierSearchParams?: InputMaybe<Array<ClassifierSearchParamsInput>>
    fromDatePosted?: InputMaybe<Scalars['DateTime']['input']>
    keyword?: InputMaybe<Scalars['String']['input']>
    socialMediaServiceIds: Array<Scalars['Int']['input']>
    teamIds: Array<Scalars['Int']['input']>
    timeDuration?: InputMaybe<Scalars['String']['input']>
    timeOffset?: InputMaybe<Scalars['String']['input']>
    toDatePosted?: InputMaybe<Scalars['DateTime']['input']>
}

export type HeatmapView = {
    __typename?: 'HeatmapView'
    atmosphereAdmiration: Scalars['Int']['output']
    atmosphereAmusement: Scalars['Int']['output']
    atmosphereAnger: Scalars['Int']['output']
    atmosphereAnnoyance: Scalars['Int']['output']
    atmosphereApproval: Scalars['Int']['output']
    atmosphereCaring: Scalars['Int']['output']
    atmosphereConfusion: Scalars['Int']['output']
    atmosphereCuriosity: Scalars['Int']['output']
    atmosphereDesire: Scalars['Int']['output']
    atmosphereDisappointment: Scalars['Int']['output']
    atmosphereDisapproval: Scalars['Int']['output']
    atmosphereDisgust: Scalars['Int']['output']
    atmosphereEmbarrassment: Scalars['Int']['output']
    atmosphereExcitement: Scalars['Int']['output']
    atmosphereFear: Scalars['Int']['output']
    atmosphereGratitude: Scalars['Int']['output']
    atmosphereGrief: Scalars['Int']['output']
    atmosphereJoy: Scalars['Int']['output']
    atmosphereLove: Scalars['Int']['output']
    atmosphereNervousness: Scalars['Int']['output']
    atmosphereNeutral: Scalars['Int']['output']
    atmosphereOptimism: Scalars['Int']['output']
    atmospherePride: Scalars['Int']['output']
    atmosphereRealization: Scalars['Int']['output']
    atmosphereRelief: Scalars['Int']['output']
    atmosphereRemorse: Scalars['Int']['output']
    atmosphereSadness: Scalars['Int']['output']
    atmosphereSurprise: Scalars['Int']['output']
    eventsAdmiration: Scalars['Int']['output']
    eventsAmusement: Scalars['Int']['output']
    eventsAnger: Scalars['Int']['output']
    eventsAnnoyance: Scalars['Int']['output']
    eventsApproval: Scalars['Int']['output']
    eventsCaring: Scalars['Int']['output']
    eventsConfusion: Scalars['Int']['output']
    eventsCuriosity: Scalars['Int']['output']
    eventsDesire: Scalars['Int']['output']
    eventsDisappointment: Scalars['Int']['output']
    eventsDisapproval: Scalars['Int']['output']
    eventsDisgust: Scalars['Int']['output']
    eventsEmbarrassment: Scalars['Int']['output']
    eventsExcitement: Scalars['Int']['output']
    eventsFear: Scalars['Int']['output']
    eventsGratitude: Scalars['Int']['output']
    eventsGrief: Scalars['Int']['output']
    eventsJoy: Scalars['Int']['output']
    eventsLove: Scalars['Int']['output']
    eventsNervousness: Scalars['Int']['output']
    eventsNeutral: Scalars['Int']['output']
    eventsOptimism: Scalars['Int']['output']
    eventsPride: Scalars['Int']['output']
    eventsRealization: Scalars['Int']['output']
    eventsRelief: Scalars['Int']['output']
    eventsRemorse: Scalars['Int']['output']
    eventsSadness: Scalars['Int']['output']
    eventsSurprise: Scalars['Int']['output']
    facilitiesAdmiration: Scalars['Int']['output']
    facilitiesAmusement: Scalars['Int']['output']
    facilitiesAnger: Scalars['Int']['output']
    facilitiesAnnoyance: Scalars['Int']['output']
    facilitiesApproval: Scalars['Int']['output']
    facilitiesCaring: Scalars['Int']['output']
    facilitiesConfusion: Scalars['Int']['output']
    facilitiesCuriosity: Scalars['Int']['output']
    facilitiesDesire: Scalars['Int']['output']
    facilitiesDisappointment: Scalars['Int']['output']
    facilitiesDisapproval: Scalars['Int']['output']
    facilitiesDisgust: Scalars['Int']['output']
    facilitiesEmbarrassment: Scalars['Int']['output']
    facilitiesExcitement: Scalars['Int']['output']
    facilitiesFear: Scalars['Int']['output']
    facilitiesGratitude: Scalars['Int']['output']
    facilitiesGrief: Scalars['Int']['output']
    facilitiesJoy: Scalars['Int']['output']
    facilitiesLove: Scalars['Int']['output']
    facilitiesNervousness: Scalars['Int']['output']
    facilitiesNeutral: Scalars['Int']['output']
    facilitiesOptimism: Scalars['Int']['output']
    facilitiesPride: Scalars['Int']['output']
    facilitiesRealization: Scalars['Int']['output']
    facilitiesRelief: Scalars['Int']['output']
    facilitiesRemorse: Scalars['Int']['output']
    facilitiesSadness: Scalars['Int']['output']
    facilitiesSurprise: Scalars['Int']['output']
    foodAdmiration: Scalars['Int']['output']
    foodAmusement: Scalars['Int']['output']
    foodAnger: Scalars['Int']['output']
    foodAnnoyance: Scalars['Int']['output']
    foodApproval: Scalars['Int']['output']
    foodCaring: Scalars['Int']['output']
    foodConfusion: Scalars['Int']['output']
    foodCuriosity: Scalars['Int']['output']
    foodDesire: Scalars['Int']['output']
    foodDisappointment: Scalars['Int']['output']
    foodDisapproval: Scalars['Int']['output']
    foodDisgust: Scalars['Int']['output']
    foodEmbarrassment: Scalars['Int']['output']
    foodExcitement: Scalars['Int']['output']
    foodFear: Scalars['Int']['output']
    foodGratitude: Scalars['Int']['output']
    foodGrief: Scalars['Int']['output']
    foodJoy: Scalars['Int']['output']
    foodLove: Scalars['Int']['output']
    foodNervousness: Scalars['Int']['output']
    foodNeutral: Scalars['Int']['output']
    foodOptimism: Scalars['Int']['output']
    foodPride: Scalars['Int']['output']
    foodRealization: Scalars['Int']['output']
    foodRelief: Scalars['Int']['output']
    foodRemorse: Scalars['Int']['output']
    foodSadness: Scalars['Int']['output']
    foodSurprise: Scalars['Int']['output']
    serviceAdmiration: Scalars['Int']['output']
    serviceAmusement: Scalars['Int']['output']
    serviceAnger: Scalars['Int']['output']
    serviceAnnoyance: Scalars['Int']['output']
    serviceApproval: Scalars['Int']['output']
    serviceCaring: Scalars['Int']['output']
    serviceConfusion: Scalars['Int']['output']
    serviceCuriosity: Scalars['Int']['output']
    serviceDesire: Scalars['Int']['output']
    serviceDisappointment: Scalars['Int']['output']
    serviceDisapproval: Scalars['Int']['output']
    serviceDisgust: Scalars['Int']['output']
    serviceEmbarrassment: Scalars['Int']['output']
    serviceExcitement: Scalars['Int']['output']
    serviceFear: Scalars['Int']['output']
    serviceGratitude: Scalars['Int']['output']
    serviceGrief: Scalars['Int']['output']
    serviceJoy: Scalars['Int']['output']
    serviceLove: Scalars['Int']['output']
    serviceNervousness: Scalars['Int']['output']
    serviceNeutral: Scalars['Int']['output']
    serviceOptimism: Scalars['Int']['output']
    servicePride: Scalars['Int']['output']
    serviceRealization: Scalars['Int']['output']
    serviceRelief: Scalars['Int']['output']
    serviceRemorse: Scalars['Int']['output']
    serviceSadness: Scalars['Int']['output']
    serviceSurprise: Scalars['Int']['output']
    venueAdmiration: Scalars['Int']['output']
    venueAmusement: Scalars['Int']['output']
    venueAnger: Scalars['Int']['output']
    venueAnnoyance: Scalars['Int']['output']
    venueApproval: Scalars['Int']['output']
    venueCaring: Scalars['Int']['output']
    venueConfusion: Scalars['Int']['output']
    venueCuriosity: Scalars['Int']['output']
    venueDesire: Scalars['Int']['output']
    venueDisappointment: Scalars['Int']['output']
    venueDisapproval: Scalars['Int']['output']
    venueDisgust: Scalars['Int']['output']
    venueEmbarrassment: Scalars['Int']['output']
    venueExcitement: Scalars['Int']['output']
    venueFear: Scalars['Int']['output']
    venueGratitude: Scalars['Int']['output']
    venueGrief: Scalars['Int']['output']
    venueJoy: Scalars['Int']['output']
    venueLove: Scalars['Int']['output']
    venueNervousness: Scalars['Int']['output']
    venueNeutral: Scalars['Int']['output']
    venueOptimism: Scalars['Int']['output']
    venuePride: Scalars['Int']['output']
    venueRealization: Scalars['Int']['output']
    venueRelief: Scalars['Int']['output']
    venueRemorse: Scalars['Int']['output']
    venueSadness: Scalars['Int']['output']
    venueSurprise: Scalars['Int']['output']
}

export type IdFilterInput = {
    beginsWith?: InputMaybe<Scalars['ID']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contains?: InputMaybe<Scalars['ID']['input']>
    eq?: InputMaybe<Scalars['ID']['input']>
    ge?: InputMaybe<Scalars['ID']['input']>
    gt?: InputMaybe<Scalars['ID']['input']>
    le?: InputMaybe<Scalars['ID']['input']>
    lt?: InputMaybe<Scalars['ID']['input']>
    ne?: InputMaybe<Scalars['ID']['input']>
    notContains?: InputMaybe<Scalars['ID']['input']>
}

export type IntFilterInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    contains?: InputMaybe<Scalars['Int']['input']>
    eq?: InputMaybe<Scalars['Int']['input']>
    ge?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    le?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
    ne?: InputMaybe<Scalars['Int']['input']>
    notContains?: InputMaybe<Scalars['Int']['input']>
}

export type KeywordParamsList = {
    accountId: Scalars['Int']['input']
}

export type KeywordView = {
    __typename?: 'KeywordView'
    accountId: Scalars['Int']['output']
    allowableRegex?: Maybe<Scalars['String']['output']>
    classifierDetailsDisplayName: Scalars['String']['output']
    classifierDetailsId: Scalars['Int']['output']
    createdAt: Scalars['DateTime']['output']
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['Int']['output']
    keyword: Scalars['String']['output']
    regex: Scalars['Boolean']['output']
}

export type MediaClassification = {
    __typename?: 'MediaClassification'
    classification?: Maybe<Scalars['Float']['output']>
    classifierName: Scalars['String']['output']
    severity?: Maybe<Severity>
}

export type Moderation = {
    __typename?: 'Moderation'
    dismissed?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['Int']['output']
    moderatedOn: Scalars['AWSDateTime']['output']
    moderation?: Maybe<Scalars['String']['output']>
}

export type ModerationBoundaryCreate = {
    accountId: Scalars['Int']['input']
    classifierId: Scalars['Int']['input']
    probability?: InputMaybe<Scalars['Float']['input']>
    queue: Scalars['String']['input']
    severityCategory?: InputMaybe<Scalars['String']['input']>
    type: Scalars['String']['input']
}

export type ModerationBoundaryInput = {
    id: Scalars['Int']['input']
    probability?: InputMaybe<Scalars['Float']['input']>
    severityCategory?: InputMaybe<Scalars['String']['input']>
    type: Scalars['String']['input']
}

export type ModerationBoundaryView = {
    __typename?: 'ModerationBoundaryView'
    account: Scalars['Int']['output']
    classifier: ClassifierView
    id: Scalars['Int']['output']
    probability?: Maybe<Scalars['Float']['output']>
    queue: Scalars['String']['output']
    severityCategory?: Maybe<Scalars['String']['output']>
    type: Scalars['String']['output']
}

export type Mutation = {
    __typename?: 'Mutation'
    addDefaultKeywords?: Maybe<Scalars['Boolean']['output']>
    addTwitterSubscription?: Maybe<Scalars['Boolean']['output']>
    addYouTubeSubscription: AddYouTubeSubcription
    bulkUpdateModerationBoundary: Scalars['Boolean']['output']
    classifyContent: Scalars['Int']['output']
    copyKeywordsToAccount: Scalars['Boolean']['output']
    copyModerationBoundaries: Scalars['Boolean']['output']
    createAccount?: Maybe<Account>
    createContentFeed: ContentFeedView
    createDashboard: DashboardView
    createKeyword: KeywordView
    createLlmClassificationPrompt: PromptView
    createModerationBoundary: ModerationBoundaryView
    createNotificationConfiguration: NotificationConfigurationView
    createPaymentPortal: Scalars['String']['output']
    createReadReceipt: ReadReceiptView
    createReadReceipts: Array<ReadReceiptView>
    createTeam: TeamView
    /**
     * This is the final step in the OAuth2 authorisation grant flow. The user will have selected
     * which ad accounts they want to add to Arwen, and will call this function with the key we gave them
     * to finally create the accounts.
     */
    createTiktokAccounts: Array<AuthorisedAccountView>
    createUser: Scalars['Boolean']['output']
    deleteAccount?: Maybe<Scalars['Boolean']['output']>
    deleteAuthorisation?: Maybe<Scalars['Boolean']['output']>
    deleteContentFeed: ContentFeedView
    deleteDashboard: Scalars['Boolean']['output']
    deleteKeyword: Scalars['Boolean']['output']
    deleteLlmClassificationPrompt: Scalars['Boolean']['output']
    deleteModerationBoundary: ModerationBoundaryView
    deleteNotificationConfiguration: NotificationConfigurationView
    deleteReadReceipt: ReadReceiptView
    deleteReadReceipts: Array<ReadReceiptView>
    deleteTeam: Scalars['Boolean']['output']
    deleteUser: Scalars['Boolean']['output']
    editAccountTeam: AccountRecord
    editTeam: TeamView
    editUser: Scalars['Boolean']['output']
    fetchHistoricContent?: Maybe<Scalars['Boolean']['output']>
    fetchHistoricYouTubeVideos?: Maybe<Scalars['Boolean']['output']>
    giveUserAccessToDashboard: Scalars['Boolean']['output']
    likeContent: Array<ContentActionResult>
    metaHistoricFetch: Scalars['Boolean']['output']
    moderateContent: Scalars['Int']['output']
    refreshToken: Scalars['Boolean']['output']
    removeItemFromSpamModel?: Maybe<Scalars['Boolean']['output']>
    removeSpamItems: Scalars['Boolean']['output']
    removeTwitterSubscription?: Maybe<Scalars['Boolean']['output']>
    replyToContent: ContentActionView
    saveAuthorisationCode?: Maybe<SaveAuthorisationCodeResponse>
    saveLlmReplyPrompt: PromptView
    takeContentAction?: Maybe<TakeContentActionResponse>
    /**
     * This is a step in the OAuth2 authorisation grant flow. The user will have authorised
     * us on TikTok's site, and TikTok will have redirected the browser back to us, with the auth code.
     * The browser then calls this function, which exchanges the auth code for a long-lived access token,
     * and also fetches and returns ad accounts. Each ad account may become an Arwen account, depending
     * on the user's choice (a mutation elsewhere).
     */
    tiktokAuthCodeToAccessToken: TikTokAccountSelectionView
    tiktokCreateUserAccountWithAuth: TikTokUserAccountSelectionView
    updateAccount?: Maybe<Scalars['Boolean']['output']>
    updateAutoFetchContent?: Maybe<Account>
    updateAutoHideAdComments?: Maybe<Account>
    updateContentFeed: ContentFeedView
    updateDashboard: Scalars['Boolean']['output']
    updateFetchAds?: Maybe<Account>
    updateFetchPosts?: Maybe<Account>
    updateIntelligenceEnabled?: Maybe<Account>
    updateLlmClassificationEnabled?: Maybe<Account>
    updateLlmClassificationPrompt: PromptView
    updateLlmSuggestedReplyEnabled?: Maybe<Account>
    updateModerationBoundary: ModerationBoundaryView
    updateNotificationConfiguration: NotificationConfigurationView
    updateProfileCollectionEnabled?: Maybe<Account>
    updateProfileModerationEnabled?: Maybe<Account>
    updateScrapeContent?: Maybe<Scalars['Boolean']['output']>
    updateShadowBanning: AccountRecord
    validateUserIdentity: UserView
}

export type MutationAddDefaultKeywordsArgs = {
    accountId: Scalars['Int']['input']
    reset: Scalars['Boolean']['input']
}

export type MutationAddTwitterSubscriptionArgs = {
    accountId: Scalars['Int']['input']
}

export type MutationAddYouTubeSubscriptionArgs = {
    channelId?: InputMaybe<Scalars['String']['input']>
}

export type MutationBulkUpdateModerationBoundaryArgs = {
    accountId: Scalars['Int']['input']
    moderationBoundaries: Array<ModerationBoundaryCreate>
}

export type MutationClassifyContentArgs = {
    reason?: InputMaybe<Scalars['String']['input']>
}

export type MutationCopyKeywordsToAccountArgs = {
    sourceAccountId: Scalars['Int']['input']
    targetAccountId: Scalars['Int']['input']
}

export type MutationCopyModerationBoundariesArgs = {
    sourceAccountId: Scalars['Int']['input']
    targetAccountIds: Array<Scalars['Int']['input']>
}

export type MutationCreateAccountArgs = {
    accountName: Scalars['String']['input']
    description?: InputMaybe<Scalars['String']['input']>
    serviceAccountId: Scalars['String']['input']
    serviceAccountUsername: Scalars['String']['input']
    serviceName: Scalars['String']['input']
}

export type MutationCreateContentFeedArgs = {
    contentFeed: ContentFeedCreate
}

export type MutationCreateDashboardArgs = {
    dashboard: NewDashboardView
}

export type MutationCreateKeywordArgs = {
    params: CreateKeywordParamsView
}

export type MutationCreateLlmClassificationPromptArgs = {
    accountId: Scalars['Int']['input']
    category: Scalars['String']['input']
    prompt: Scalars['String']['input']
}

export type MutationCreateModerationBoundaryArgs = {
    moderationBoundary: ModerationBoundaryCreate
}

export type MutationCreateNotificationConfigurationArgs = {
    accountId: Scalars['Int']['input']
    notificationTemplateId: Scalars['String']['input']
    recipientListId: Scalars['String']['input']
}

export type MutationCreatePaymentPortalArgs = {
    cancelUrl: Scalars['String']['input']
    successUrl: Scalars['String']['input']
}

export type MutationCreateReadReceiptArgs = {
    contentId: Scalars['Int']['input']
}

export type MutationCreateReadReceiptsArgs = {
    contentIds: Array<Scalars['Int']['input']>
}

export type MutationCreateTeamArgs = {
    teamName: Scalars['String']['input']
}

export type MutationCreateTiktokAccountsArgs = {
    adAccountIds: Array<Scalars['String']['input']>
    sessionKey: Scalars['String']['input']
}

export type MutationCreateUserArgs = {
    userParams: UserCreateParamsView
}

export type MutationDeleteAccountArgs = {
    accountId: Scalars['Int']['input']
    authCode?: InputMaybe<Scalars['String']['input']>
}

export type MutationDeleteAuthorisationArgs = {
    accountId: Scalars['Int']['input']
}

export type MutationDeleteContentFeedArgs = {
    contentFeedId: Scalars['Int']['input']
}

export type MutationDeleteDashboardArgs = {
    dashboardId: Scalars['Int']['input']
}

export type MutationDeleteKeywordArgs = {
    keywordId: Scalars['Int']['input']
}

export type MutationDeleteLlmClassificationPromptArgs = {
    promptId: Scalars['Int']['input']
}

export type MutationDeleteModerationBoundaryArgs = {
    moderationBoundaryId: Scalars['Int']['input']
}

export type MutationDeleteNotificationConfigurationArgs = {
    notificationConfigurationId: Scalars['Int']['input']
}

export type MutationDeleteReadReceiptArgs = {
    contentId: Scalars['Int']['input']
}

export type MutationDeleteReadReceiptsArgs = {
    contentIds: Array<Scalars['Int']['input']>
}

export type MutationDeleteTeamArgs = {
    teamId: Scalars['Int']['input']
}

export type MutationDeleteUserArgs = {
    userId: Scalars['Int']['input']
}

export type MutationEditAccountTeamArgs = {
    accountId: Scalars['Int']['input']
    teamId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationEditTeamArgs = {
    name: Scalars['String']['input']
    teamId: Scalars['Int']['input']
}

export type MutationEditUserArgs = {
    userParams: UserEditParamsView
}

export type MutationFetchHistoricContentArgs = {
    accountId: Scalars['Int']['input']
    tweetsRequested: Scalars['Int']['input']
}

export type MutationFetchHistoricYouTubeVideosArgs = {
    accountId: Scalars['Int']['input']
}

export type MutationGiveUserAccessToDashboardArgs = {
    dashboardId: Scalars['Int']['input']
    userId: Scalars['Int']['input']
}

export type MutationLikeContentArgs = {
    contentIds: Array<Scalars['Int']['input']>
    like: Scalars['Boolean']['input']
}

export type MutationMetaHistoricFetchArgs = {
    accountId: Scalars['Int']['input']
    from: Scalars['DateTime']['input']
    to: Scalars['DateTime']['input']
}

export type MutationModerateContentArgs = {
    reason?: InputMaybe<Scalars['String']['input']>
}

export type MutationRefreshTokenArgs = {
    accountId: Scalars['Int']['input']
}

export type MutationRemoveItemFromSpamModelArgs = {
    contentId: Scalars['Int']['input']
}

export type MutationRemoveSpamItemsArgs = {
    contentIds: Array<Scalars['Int']['input']>
}

export type MutationRemoveTwitterSubscriptionArgs = {
    accountId: Scalars['Int']['input']
}

export type MutationReplyToContentArgs = {
    contentId: Scalars['Int']['input']
    message: Scalars['String']['input']
}

export type MutationSaveAuthorisationCodeArgs = {
    accountId?: InputMaybe<Scalars['Int']['input']>
    authCode: Scalars['String']['input']
    authVerifier?: InputMaybe<Scalars['String']['input']>
    baseUrl?: InputMaybe<Scalars['String']['input']>
    socialMediaServiceName?: InputMaybe<Scalars['String']['input']>
}

export type MutationSaveLlmReplyPromptArgs = {
    accountId: Scalars['Int']['input']
    prompt: Scalars['String']['input']
}

export type MutationTakeContentActionArgs = {
    action: ContentAction
    baseUrl?: InputMaybe<Scalars['String']['input']>
    contentId: Scalars['Int']['input']
    toggle: Scalars['Boolean']['input']
}

export type MutationTiktokAuthCodeToAccessTokenArgs = {
    authCode: Scalars['String']['input']
    code: Scalars['String']['input']
}

export type MutationTiktokCreateUserAccountWithAuthArgs = {
    authCode: Scalars['String']['input']
    code: Scalars['String']['input']
    redirectUri: Scalars['String']['input']
}

export type MutationUpdateAccountArgs = {
    accountName?: InputMaybe<Scalars['String']['input']>
    autoDeleteSevereContent?: InputMaybe<Scalars['Boolean']['input']>
    autoHideSevereContent?: InputMaybe<Scalars['Boolean']['input']>
    autoMuteSevereAccount?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['Int']['input']
    isSubscribed?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateAutoFetchContentArgs = {
    accountId: Scalars['Int']['input']
    autoFetchContent?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateAutoHideAdCommentsArgs = {
    accountId: Scalars['Int']['input']
    autoHideAdComments?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateContentFeedArgs = {
    contentFeed: ContentFeedUpdate
}

export type MutationUpdateDashboardArgs = {
    dashboard: UpdatedDashboardView
}

export type MutationUpdateFetchAdsArgs = {
    accountId: Scalars['Int']['input']
    fetchAds?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateFetchPostsArgs = {
    accountId: Scalars['Int']['input']
    fetchPosts?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateIntelligenceEnabledArgs = {
    accountId: Scalars['Int']['input']
    intelligenceEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateLlmClassificationEnabledArgs = {
    accountId: Scalars['Int']['input']
    llmClassificationEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateLlmClassificationPromptArgs = {
    category: Scalars['String']['input']
    prompt: Scalars['String']['input']
    promptId: Scalars['Int']['input']
}

export type MutationUpdateLlmSuggestedReplyEnabledArgs = {
    accountId: Scalars['Int']['input']
    llmSuggestedReplyEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateModerationBoundaryArgs = {
    moderationBoundary: ModerationBoundaryInput
}

export type MutationUpdateNotificationConfigurationArgs = {
    accountId: Scalars['Int']['input']
    id: Scalars['Int']['input']
    notificationTemplateId: Scalars['String']['input']
    recipientListId: Scalars['String']['input']
}

export type MutationUpdateProfileCollectionEnabledArgs = {
    accountId: Scalars['Int']['input']
    profileCollectionEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateProfileModerationEnabledArgs = {
    accountId: Scalars['Int']['input']
    profileModerationEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateScrapeContentArgs = {
    accountId: Scalars['Int']['input']
    scrapeContent?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateShadowBanningArgs = {
    accountId: Scalars['Int']['input']
    enabled: Scalars['Boolean']['input']
    rangeInDays: Scalars['Int']['input']
    threshold: Scalars['Int']['input']
}

export type NewDashboardView = {
    description: Scalars['String']['input']
    displayName: Scalars['String']['input']
    extEmbeddedId: Scalars['String']['input']
    userIds: Array<Scalars['Int']['input']>
}

export type NotificationConfigurationView = {
    __typename?: 'NotificationConfigurationView'
    accountId: Scalars['Int']['output']
    id: Scalars['Int']['output']
    notificationTemplateId: Scalars['String']['output']
    recipientListId: Scalars['String']['output']
}

export type Price = {
    __typename?: 'Price'
    active: Scalars['Boolean']['output']
    currency: Scalars['String']['output']
    interval: Scalars['String']['output']
    price: Scalars['String']['output']
    priceId: Scalars['String']['output']
}

export type Product = {
    __typename?: 'Product'
    active: Scalars['Boolean']['output']
    allowedFacebookAccounts?: Maybe<Scalars['Int']['output']>
    allowedInstagramAccounts?: Maybe<Scalars['Int']['output']>
    allowedTotalFollowers?: Maybe<Scalars['String']['output']>
    allowedTwitterAccounts?: Maybe<Scalars['Int']['output']>
    customerType: Scalars['String']['output']
    description: Scalars['String']['output']
    directMessagesIncluded?: Maybe<Scalars['Boolean']['output']>
    languagesCovered?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    order: Scalars['Int']['output']
    prices?: Maybe<Array<Price>>
    productId: Scalars['String']['output']
    reportingLevel?: Maybe<Scalars['String']['output']>
    speedOfRemoval?: Maybe<Scalars['String']['output']>
}

export type ProductConnection = {
    __typename?: 'ProductConnection'
    items: Array<Product>
}

export type ProfileFilterInput = {
    followers?: InputMaybe<Scalars['Int']['input']>
    following?: InputMaybe<Scalars['Int']['input']>
    posts?: InputMaybe<Scalars['Int']['input']>
    username?: InputMaybe<Scalars['String']['input']>
}

export type ProfileSortInput = {
    field: Scalars['String']['input']
    order: Scalars['String']['input']
}

export type ProfileView = {
    __typename?: 'ProfileView'
    followers?: Maybe<Scalars['Int']['output']>
    following?: Maybe<Scalars['Int']['output']>
    id: Scalars['Int']['output']
    posts?: Maybe<Scalars['Int']['output']>
    /** The location of the saved profile image - S3 */
    profileImagePath?: Maybe<Scalars['String']['output']>
    profileImageUrl?: Maybe<Scalars['String']['output']>
    profileUrl: Scalars['String']['output']
    serviceProfileId: Scalars['String']['output']
    username: Scalars['String']['output']
}

export type PromptView = {
    __typename?: 'PromptView'
    category?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['DateTime']['output']
    id: Scalars['Int']['output']
    prompt: Scalars['String']['output']
    updatedAt?: Maybe<Scalars['DateTime']['output']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type Query = {
    __typename?: 'Query'
    checkMetaAuthorisationForAccount: Scalars['Boolean']['output']
    checkMetaSubscriptionForAccount: Scalars['Boolean']['output']
    countContent?: Maybe<CountContentConnection>
    countProfiles: Scalars['Int']['output']
    /**
     * This doesn't change any information in the database so isn't strictly a mutation
     * Also it just makes sense to have it alongside the check query function
     */
    createMetaSubscriptionForAccount: Scalars['Boolean']['output']
    debugMetaAuthorisationForAccount: DebugTokenResponseView
    /**
     * Detects the language of the provided texts.
     * This method uses lingua-rs to detect the language of the provided texts thus avoiding
     * Google Cloud Translation API charges.
     * Note - these charges are very small and the service is very fast so this
     * query is currently not used.
     *
     * This is being kept in case during testing or production we need to move away from
     * the Google Cloud Translation API.
     */
    detectLanguages: Array<DetectedLanguageView>
    enrichContent: Array<EnrichedContent>
    fetchUserDetails?: Maybe<FetchUserDetailsConnection>
    getAccount: AccountRecord
    /** Gets all accounts for the logged in user */
    getAccountsForMe: Array<AccountRecord>
    /** Gets all accounts in the given team */
    getAccountsInTeam: Array<AccountRecord>
    getAuthorisationUrl: Scalars['String']['output']
    getClassificationResults: Array<ClassificationResult>
    getClassifierDetails: Array<ClassifierDetails>
    getContentById: ContentOutcomeView
    /**
     * Retrieves the number of content in each classifier for the specified search.
     *
     * Similar to `get_heatmap` but for the traditional classifiers. Effectively makes the same query at a database level
     */
    getContentClassifierMetadata: Array<ContentClassifierMetadataView>
    getContentFeed: ContentFeedView
    getContentFeedsForUser: Array<ContentFeedView>
    getCurrentAuthorisationValidity: Scalars['Boolean']['output']
    getCustomerPaymentPortalUrl: CustomerPaymentPortal
    /** Gets a dashboard by its ID. */
    getDashboard: DashboardView
    /** Gets all the dashboards that the user has access to. */
    getDashboards: Array<DashboardView>
    /** Gets a dashboard by its ID, along with the users that have access to it. */
    getEditableDashboard: EditableDashboardView
    /** Gets the guest token and the config needed to embed a Preset.io dashboard in an iframe. */
    getEmbeddedDashboard: EmbeddedDashboardView
    getHeatmap: Array<HeatmapView>
    getKeywordsForAccount: Array<KeywordView>
    getLabelledSpam?: Maybe<Array<Maybe<SpamSearchResult>>>
    getLineGraph: Array<DateMapView>
    getLlmClassificationCategory?: Maybe<Scalars['String']['output']>
    getLlmClassificationPrompt: PromptView
    getLlmClassificationPrompts: Array<PromptView>
    getLlmReplyPrompt?: Maybe<PromptView>
    getManyClassificationResults: Array<ClassificationResult>
    getModerationBoundaries: Array<ModerationBoundaryView>
    getMyContentFeeds: Array<ContentFeedView>
    getNotificationConfiguration: NotificationConfigurationView
    getNotificationConfigurationForAccount: Array<NotificationConfigurationView>
    /**
     * Gets the authorisation URL for the OAuth 2 authorisation grant flow.
     * This is the initial platform URL the user visits: they approve our
     * request and are redirected back to us with a code.
     *
     * 2024-02: get_authorisation_url exists in Tenmoku, and gets the OAuth url for the
     * other platforms. New authorisation URLs should be added here, as part of the migration
     * away from Tenmoku and Typescript.
     */
    getOauthUrl: Scalars['String']['output']
    /**
     * Returns the profile by content id.
     *
     * Scrapes and persists the profile if we don't have it.
     *
     * Instagram only.
     */
    getProfile: ProfileView
    getProfilesByIds: Array<ProfileView>
    getSocialMediaServices: Array<SocialMediaService>
    getSuggestedReplies: Array<Scalars['String']['output']>
    /**
     * Returns counts of SAFE/SUSPECT/SEVERE for a given set of content.
     * What content is determined by the given params.
     */
    getSummaryForAccounts: ContentSummary
    getTeam?: Maybe<Team>
    /**
     * Gets a team by ID.
     *
     * Will reject the request if:
     * 1. The user is not an admin OR
     * 2. The user is not in the team.
     */
    getTeamById: TeamView
    /**
     * Gets all the teams that the user has permission to view.
     * For example, an admin can view all teams, a non-admin only the team they are a member of.
     * A non-admin user who is not part of a team just gets an empty string.
     */
    getTeams: Array<TeamView>
    getUserAuthEvents: Array<UserAuthEventView>
    getUserById: UserView
    /**
     * The currently logged in user is allowed to get user details about their other team members.
     * Given how we define our user ids, using sequentially increasing numbers, we can't allow users to
     * get user details about other users. This is a security measure.
     */
    getUserIfAllowed: UserView
    getUserPermissions: UsersPermissionsView
    getUserSubscription: User
    getUserTeams: Array<TeamView>
    getUsersByTeamId: Array<UserView>
    getYouTubeSubscriptionDiagnosticUrl?: Maybe<YouTubeSubscriptionDiagrnostics>
    isSubscribed: Scalars['Boolean']['output']
    listContent?: Maybe<ContentConnection>
    searchAccounts: SearchAccountResponse
    /** Allows searching of the content outcome table. This is the main, denormalised table of content data. */
    searchContentOutcome: Array<ContentOutcomeView>
    /** The count function for `search_content_outcome` */
    searchContentOutcomeCount: Scalars['Int']['output']
    searchProfiles: Array<ProfileView>
    searchTeams: Array<TeamView>
    searchUsers: Array<UserView>
    searchUsersCount: Scalars['Int']['output']
    testLlmClassificationPrompt: Scalars['Float']['output']
    testLlmReplyPrompt: Array<Scalars['String']['output']>
    translate: Array<TranslatedTextView>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryCheckMetaAuthorisationForAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryCheckMetaSubscriptionForAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryCountContentArgs = {
    endDateTime: Scalars['AWSDateTime']['input']
    filter?: InputMaybe<ContentFilterInput>
    keyword?: InputMaybe<Scalars['String']['input']>
    startDateTime: Scalars['AWSDateTime']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryCountProfilesArgs = {
    filter?: InputMaybe<ProfileFilterInput>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryCreateMetaSubscriptionForAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryDebugMetaAuthorisationForAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryDetectLanguagesArgs = {
    sourceTexts: Array<Scalars['String']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryEnrichContentArgs = {
    contents: Array<ContentToEnrich>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryFetchUserDetailsArgs = {
    serviceAccountUsername: Scalars['String']['input']
    socialMediaService: SocialMediaServiceEnum
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetAccountsInTeamArgs = {
    params: GetAccountsInTeamParamsView
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetAuthorisationUrlArgs = {
    accountId: Scalars['Int']['input']
    baseUrl: Scalars['String']['input']
    socialMediaService: SocialMediaServiceEnum
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetClassificationResultsArgs = {
    contentId?: InputMaybe<Scalars['Int']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetContentByIdArgs = {
    id: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetContentClassifierMetadataArgs = {
    params: SearchParamsForContentOutcome
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetContentFeedArgs = {
    contentFeedId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetContentFeedsForUserArgs = {
    userId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetCurrentAuthorisationValidityArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetCustomerPaymentPortalUrlArgs = {
    returnUrl: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetDashboardArgs = {
    dashboardId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetEditableDashboardArgs = {
    dashboardId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetEmbeddedDashboardArgs = {
    dashboardId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetHeatmapArgs = {
    params: SearchParamsForContentOutcome
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetKeywordsForAccountArgs = {
    params: KeywordParamsList
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLabelledSpamArgs = {
    message?: InputMaybe<Scalars['String']['input']>
    platform?: InputMaybe<Scalars['String']['input']>
    threshold?: InputMaybe<Scalars['Float']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLineGraphArgs = {
    params: SearchParamsForContentOutcome
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLlmClassificationCategoryArgs = {
    accountId: Scalars['Int']['input']
    llmClassifierDetailsName: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLlmClassificationPromptArgs = {
    promptId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLlmClassificationPromptsArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetLlmReplyPromptArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetManyClassificationResultsArgs = {
    contentIds: Array<Scalars['Int']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetModerationBoundariesArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetNotificationConfigurationArgs = {
    notificationConfigurationId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetNotificationConfigurationForAccountArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetOauthUrlArgs = {
    baseUrl: Scalars['String']['input']
    socialMediaService: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetProfileArgs = {
    contentId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetProfilesByIdsArgs = {
    profileIds: Array<Scalars['Int']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetSuggestedRepliesArgs = {
    contentId: Scalars['Int']['input']
    numberOfSuggestions?: InputMaybe<Scalars['Int']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetSummaryForAccountsArgs = {
    params: GetSummaryMetricsParams
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetTeamArgs = {
    teamId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetTeamByIdArgs = {
    teamId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserAuthEventsArgs = {
    username: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserByIdArgs = {
    id: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserIfAllowedArgs = {
    id: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserPermissionsArgs = {
    usernames: Array<Scalars['String']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserSubscriptionArgs = {
    referrer?: InputMaybe<Scalars['String']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUserTeamsArgs = {
    userId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetUsersByTeamIdArgs = {
    teamId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryGetYouTubeSubscriptionDiagnosticUrlArgs = {
    channelId?: InputMaybe<Scalars['String']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryIsSubscribedArgs = {
    accountId: Scalars['Int']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryListContentArgs = {
    filter?: InputMaybe<ContentFilterInput>
    keyword?: InputMaybe<Scalars['String']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    offset?: InputMaybe<Scalars['Int']['input']>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchAccountsArgs = {
    params: SearchAccountsParamsView
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchContentOutcomeArgs = {
    params: SearchParamsForContentOutcome
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchContentOutcomeCountArgs = {
    params: SearchParamsForContentOutcome
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchProfilesArgs = {
    filter?: InputMaybe<ProfileFilterInput>
    limit: Scalars['Int']['input']
    offset: Scalars['Int']['input']
    sort?: InputMaybe<ProfileSortInput>
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchTeamsArgs = {
    params: TeamsSearchParamsView
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchUsersArgs = {
    params: UserSearchParams
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QuerySearchUsersCountArgs = {
    params: UserSearchParams
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryTestLlmClassificationPromptArgs = {
    prompt: Scalars['String']['input']
    testMessage: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryTestLlmReplyPromptArgs = {
    numberOfSuggestions?: InputMaybe<Scalars['Int']['input']>
    prompt: Scalars['String']['input']
    testMessage: Scalars['String']['input']
}

/**
 * All the queries our rust graphql server supports.
 *
 * If you add something here make sure you add it to:
 * ```api-models/rust_graphql/src/queries.graphql```
 * If you don't you won't get generated client code, i.e. for TypeScript.
 *
 * When writing queries here you'll probably want to do some of the following:
 * ```ignore
 * // For access to information about the current user.
 * let logged_in_user = get_user(ctx).await?;
 * // For access to services and the database connection pool.
 * let state = ctx.data::<AppState>()?;
 * ```
 */
export type QueryTranslateArgs = {
    sourceTexts: Array<Scalars['String']['input']>
    targetLanguage: Scalars['String']['input']
}

export enum ReadFilterView {
    All = 'ALL',
    ReadByMe = 'READ_BY_ME',
    UnreadByEveryone = 'UNREAD_BY_EVERYONE',
    UnreadByMe = 'UNREAD_BY_ME'
}

export type ReadReceiptView = {
    __typename?: 'ReadReceiptView'
    contentOutcomeId: Scalars['Int']['output']
    createdAt: Scalars['DateTime']['output']
    userId: Scalars['Int']['output']
}

export type Referrer = {
    __typename?: 'Referrer'
    allowTrial: Scalars['Boolean']['output']
    creditCardRequired: Scalars['Boolean']['output']
    id: Scalars['Int']['output']
    isCobranded: Scalars['Boolean']['output']
    logoUrl?: Maybe<Scalars['String']['output']>
    priceId?: Maybe<Scalars['String']['output']>
    referrer: Scalars['String']['output']
    referrerUuid: Scalars['String']['output']
    signUpEnabled: Scalars['Boolean']['output']
    trialDays?: Maybe<Scalars['Int']['output']>
    trialEndDate?: Maybe<Scalars['AWSDateTime']['output']>
    userLimit?: Maybe<Scalars['Int']['output']>
}

export type SaveAuthorisationCodeResponse = {
    __typename?: 'SaveAuthorisationCodeResponse'
    new: Array<Maybe<Scalars['Int']['output']>>
    updated: Array<Maybe<Scalars['Int']['output']>>
}

export type SearchAccountResponse = {
    __typename?: 'SearchAccountResponse'
    accounts: Array<AccountRecord>
    total: Scalars['Int']['output']
}

/** Params for search for accounts */
export type SearchAccountsParamsView = {
    keyword?: InputMaybe<Scalars['String']['input']>
    limit: Scalars['Int']['input']
    offset: Scalars['Int']['input']
    socialMediaServices: Array<Scalars['String']['input']>
    teamIds: Array<Scalars['Int']['input']>
}

/** This is the input parameter list associated with the GraphQL endpoints. It must remain a subset of `ContentSearchParamsRecord` and ideally be identical. We require this and ContentSearchParamsInput because you cannot implement SimpleObject and InputObject on the same struct. */
export type SearchParamsForContentOutcome = {
    accountIds?: InputMaybe<Array<Scalars['Int']['input']>>
    adsOrOrganic?: InputMaybe<AdsOrOrganicFilterView>
    before?: InputMaybe<Scalars['DateTime']['input']>
    classifierSearchParams?: InputMaybe<Array<ClassifierSearchParamsInput>>
    containsText?: InputMaybe<Scalars['String']['input']>
    contentIds?: InputMaybe<Array<Scalars['Int']['input']>>
    maxResults?: InputMaybe<Scalars['Int']['input']>
    moderationCode?: InputMaybe<Array<Scalars['Int']['input']>>
    moderationString?: InputMaybe<Array<Scalars['String']['input']>>
    owned?: InputMaybe<Scalars['Boolean']['input']>
    readFilter?: InputMaybe<ReadFilterView>
    since?: InputMaybe<Scalars['DateTime']['input']>
    skipResults?: InputMaybe<Scalars['Int']['input']>
    socialMediaServiceIds?: InputMaybe<Array<Scalars['Int']['input']>>
    teamIds?: InputMaybe<Array<Scalars['Int']['input']>>
    timeDuration?: InputMaybe<Scalars['String']['input']>
    timeOffset?: InputMaybe<Scalars['String']['input']>
}

export enum Severity {
    Extreme = 'EXTREME',
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM',
    None = 'NONE',
    Notdetected = 'NOTDETECTED'
}

export type SocialMediaService = {
    __typename?: 'SocialMediaService'
    displayName: Scalars['String']['output']
    id: Scalars['Int']['output']
    longDescription?: Maybe<Scalars['String']['output']>
    shortDescription: Scalars['String']['output']
    shortName: Scalars['String']['output']
}

export enum SocialMediaServiceEnum {
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Tikapi = 'TIKAPI',
    Tiktokad = 'TIKTOKAD',
    Tiktokuser = 'TIKTOKUSER',
    Twitter = 'TWITTER',
    Youtube = 'YOUTUBE'
}

export type SpamSearchResult = {
    __typename?: 'SpamSearchResult'
    contentId?: Maybe<Scalars['Int']['output']>
    similarityScore?: Maybe<Scalars['Float']['output']>
}

export type StringFilterInput = {
    beginsWith?: InputMaybe<Scalars['String']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contains?: InputMaybe<Scalars['String']['input']>
    eq?: InputMaybe<Scalars['String']['input']>
    ge?: InputMaybe<Scalars['String']['input']>
    gt?: InputMaybe<Scalars['String']['input']>
    le?: InputMaybe<Scalars['String']['input']>
    lt?: InputMaybe<Scalars['String']['input']>
    ne?: InputMaybe<Scalars['String']['input']>
    notContains?: InputMaybe<Scalars['String']['input']>
}

export type Subscription = {
    __typename?: 'Subscription'
    todo?: Maybe<Scalars['String']['output']>
}

export type SubscriptionConnection = {
    __typename?: 'SubscriptionConnection'
    items: Array<Subscription>
    total?: Maybe<Scalars['Int']['output']>
}

export type TakeContentActionResponse = {
    __typename?: 'TakeContentActionResponse'
    contentAction?: Maybe<ContentActionBusiness>
    queued: Scalars['Boolean']['output']
}

export type Team = {
    __typename?: 'Team'
    accounts?: Maybe<Array<Account>>
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['Int']['output']
    name: Scalars['String']['output']
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    users?: Maybe<Array<User>>
}

export type TeamView = {
    __typename?: 'TeamView'
    createdAt: Scalars['DateTime']['output']
    id: Scalars['Int']['output']
    name: Scalars['String']['output']
}

export type TeamsSearchParamsView = {
    searchTerm: Scalars['String']['input']
}

export type TikTokAccountSelectionView = {
    __typename?: 'TikTokAccountSelectionView'
    adAccounts: Array<TikTokAdAccountView>
    key: TikTokSessionKey
}

export type TikTokAdAccountView = {
    __typename?: 'TikTokAdAccountView'
    advertiserId: Scalars['String']['output']
    advertiserName: Scalars['String']['output']
}

export type TikTokSessionKey = {
    __typename?: 'TikTokSessionKey'
    expiry: Scalars['DateTime']['output']
    key: Scalars['String']['output']
}

export type TikTokUserAccountSelectionView = {
    __typename?: 'TikTokUserAccountSelectionView'
    accountData: AuthorisedAccountView
    key: TikTokSessionKey
}

export type TranslatedTextView = {
    __typename?: 'TranslatedTextView'
    detectedSourceLanguage: Scalars['String']['output']
    sourceText: Scalars['String']['output']
    translatedText: Scalars['String']['output']
}

export type UpdatedDashboardView = {
    description: Scalars['String']['input']
    displayName: Scalars['String']['input']
    extEmbeddedId: Scalars['String']['input']
    id: Scalars['Int']['input']
    userIds: Array<Scalars['Int']['input']>
}

export type User = {
    __typename?: 'User'
    canLikeContent: Scalars['Boolean']['output']
    canReplyToContent: Scalars['Boolean']['output']
    canUseReadStatus: Scalars['Boolean']['output']
    dashboardEnabled?: Maybe<Scalars['Boolean']['output']>
    dashboardId?: Maybe<Scalars['String']['output']>
    feedsEnabled: Scalars['Boolean']['output']
    id: Scalars['Int']['output']
    intelligencePageViewer: Scalars['Boolean']['output']
    referrer?: Maybe<Referrer>
    shouldProvisionAccess: Scalars['Boolean']['output']
    stripeCustomerId?: Maybe<Scalars['String']['output']>
    stripeSubscriptionId?: Maybe<Scalars['String']['output']>
    stripeSubscriptionStatus?: Maybe<Scalars['String']['output']>
    team?: Maybe<Team>
    username: Scalars['String']['output']
}

export type UserAuthEventView = {
    __typename?: 'UserAuthEventView'
    city: Scalars['String']['output']
    country: Scalars['String']['output']
    deviceName: Scalars['String']['output']
    eventResponse: Scalars['String']['output']
    eventTimestamp?: Maybe<Scalars['DateTime']['output']>
    eventType: Scalars['String']['output']
    ipAddress: Scalars['String']['output']
    username: Scalars['String']['output']
}

export type UserCreateParamsView = {
    admin: Scalars['Boolean']['input']
    allowAccessOverride: Scalars['Boolean']['input']
    canLike: Scalars['Boolean']['input']
    canReplyToContent: Scalars['Boolean']['input']
    canUseReadStatus: Scalars['Boolean']['input']
    canViewIntelligencePage: Scalars['Boolean']['input']
    dashboardEnabled: Scalars['Boolean']['input']
    dashboardId?: InputMaybe<Scalars['String']['input']>
    emailAddress: Scalars['String']['input']
    feedsEnabled: Scalars['Boolean']['input']
    selectedTeam?: InputMaybe<Scalars['Int']['input']>
    selectedTeams: Array<Scalars['Int']['input']>
}

export type UserEditParamsView = {
    admin: Scalars['Boolean']['input']
    allowAccessOverride: Scalars['Boolean']['input']
    canLike: Scalars['Boolean']['input']
    canReplyToContent: Scalars['Boolean']['input']
    canUseReadStatus: Scalars['Boolean']['input']
    canViewIntelligencePage: Scalars['Boolean']['input']
    dashboardEnabled: Scalars['Boolean']['input']
    dashboardId?: InputMaybe<Scalars['String']['input']>
    emailAddress: Scalars['String']['input']
    feedsEnabled: Scalars['Boolean']['input']
    id: Scalars['Int']['input']
    selectedTeam?: InputMaybe<Scalars['Int']['input']>
    selectedTeams: Array<Scalars['Int']['input']>
}

export type UserSearchParams = {
    containsText?: InputMaybe<Scalars['String']['input']>
    limit: Scalars['Int']['input']
    offset: Scalars['Int']['input']
}

export type UserView = {
    __typename?: 'UserView'
    admin?: Maybe<Scalars['Boolean']['output']>
    allowAccessOverride?: Maybe<Scalars['Boolean']['output']>
    canLikeContent: Scalars['Boolean']['output']
    canReplyToContent: Scalars['Boolean']['output']
    canUseReadStatus: Scalars['Boolean']['output']
    createdOn: Scalars['DateTime']['output']
    dashboardEnabled?: Maybe<Scalars['Boolean']['output']>
    dashboardId?: Maybe<Scalars['String']['output']>
    feedsEnabled: Scalars['Boolean']['output']
    id: Scalars['Int']['output']
    intelligencePageViewer: Scalars['Boolean']['output']
    referrerId?: Maybe<Scalars['Int']['output']>
    stripeCustomerId?: Maybe<Scalars['String']['output']>
    stripeSubscriptionId?: Maybe<Scalars['String']['output']>
    stripeSubscriptionStatus?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['DateTime']['output']>
    username: Scalars['String']['output']
}

export type UsersPermissionsView = {
    __typename?: 'UsersPermissionsView'
    userPermissions: Scalars['JSONObject']['output']
}

export type YouTubeSubscriptionDiagrnostics = {
    __typename?: 'YouTubeSubscriptionDiagrnostics'
    url?: Maybe<Scalars['String']['output']>
}

export type CreateContentFeedMutationVariables = Exact<{
    contentFeedNew: ContentFeedCreate
}>

export type CreateContentFeedMutation = {
    __typename?: 'Mutation'
    createContentFeed: {
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }
}

export type DeleteContentFeedMutationVariables = Exact<{
    contentFeedId: Scalars['Int']['input']
}>

export type DeleteContentFeedMutation = {
    __typename?: 'Mutation'
    deleteContentFeed: {
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }
}

export type UpdateContentFeedMutationVariables = Exact<{
    contentFeedUpdate: ContentFeedUpdate
}>

export type UpdateContentFeedMutation = {
    __typename?: 'Mutation'
    updateContentFeed: {
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }
}

export type CreateDashboardMutationVariables = Exact<{
    dashboard: NewDashboardView
}>

export type CreateDashboardMutation = {
    __typename?: 'Mutation'
    createDashboard: {
        __typename?: 'DashboardView'
        id: number
        displayName: string
        description: string
        extEmbeddedId: string
        createdAt: any
        updatedAt: any
    }
}

export type DeleteDashboardMutationVariables = Exact<{
    dashboardId: Scalars['Int']['input']
}>

export type DeleteDashboardMutation = {
    __typename?: 'Mutation'
    deleteDashboard: boolean
}

export type UpdateDashboardMutationVariables = Exact<{
    dashboard: UpdatedDashboardView
}>

export type UpdateDashboardMutation = {
    __typename?: 'Mutation'
    updateDashboard: boolean
}

export type GiveUserAccessToDashboardMutationVariables = Exact<{
    userId: Scalars['Int']['input']
    dashboardId: Scalars['Int']['input']
}>

export type GiveUserAccessToDashboardMutation = {
    __typename?: 'Mutation'
    giveUserAccessToDashboard: boolean
}

export type CreateReadReceiptMutationVariables = Exact<{
    contentId: Scalars['Int']['input']
}>

export type CreateReadReceiptMutation = {
    __typename?: 'Mutation'
    createReadReceipt: {
        __typename?: 'ReadReceiptView'
        userId: number
        contentOutcomeId: number
        createdAt: any
    }
}

export type CreateReadReceiptsMutationVariables = Exact<{
    contentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type CreateReadReceiptsMutation = {
    __typename?: 'Mutation'
    createReadReceipts: Array<{
        __typename?: 'ReadReceiptView'
        userId: number
        contentOutcomeId: number
        createdAt: any
    }>
}

export type DeleteReadReceiptMutationVariables = Exact<{
    contentId: Scalars['Int']['input']
}>

export type DeleteReadReceiptMutation = {
    __typename?: 'Mutation'
    deleteReadReceipt: {
        __typename?: 'ReadReceiptView'
        userId: number
        contentOutcomeId: number
        createdAt: any
    }
}

export type DeleteReadReceiptsMutationVariables = Exact<{
    contentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type DeleteReadReceiptsMutation = {
    __typename?: 'Mutation'
    deleteReadReceipts: Array<{
        __typename?: 'ReadReceiptView'
        userId: number
        contentOutcomeId: number
        createdAt: any
    }>
}

export type CreateNotificationConfigurationMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    recipientListId: Scalars['String']['input']
    notificationTemplateId: Scalars['String']['input']
}>

export type CreateNotificationConfigurationMutation = {
    __typename?: 'Mutation'
    createNotificationConfiguration: {
        __typename?: 'NotificationConfigurationView'
        id: number
        accountId: number
        recipientListId: string
        notificationTemplateId: string
    }
}

export type DeleteNotificationConfigurationMutationVariables = Exact<{
    notificationConfigurationId: Scalars['Int']['input']
}>

export type DeleteNotificationConfigurationMutation = {
    __typename?: 'Mutation'
    deleteNotificationConfiguration: {
        __typename?: 'NotificationConfigurationView'
        id: number
        accountId: number
        recipientListId: string
        notificationTemplateId: string
    }
}

export type UpdateNotificationConfigurationMutationVariables = Exact<{
    notificationConfigurationId: Scalars['Int']['input']
    accountId: Scalars['Int']['input']
    recipientListId: Scalars['String']['input']
    notificationTemplateId: Scalars['String']['input']
}>

export type UpdateNotificationConfigurationMutation = {
    __typename?: 'Mutation'
    updateNotificationConfiguration: {
        __typename?: 'NotificationConfigurationView'
        id: number
        accountId: number
        recipientListId: string
        notificationTemplateId: string
    }
}

export type UpdateModerationBoundaryMutationVariables = Exact<{
    moderationBoundaryInput: ModerationBoundaryInput
}>

export type UpdateModerationBoundaryMutation = {
    __typename?: 'Mutation'
    updateModerationBoundary: {
        __typename?: 'ModerationBoundaryView'
        id: number
        account: number
        queue: string
        type: string
        severityCategory?: string | null
        probability?: number | null
        classifier: {
            __typename?: 'ClassifierView'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription: string
        }
    }
}

export type CreateModerationBoundaryMutationVariables = Exact<{
    moderationBoundary: ModerationBoundaryCreate
}>

export type CreateModerationBoundaryMutation = {
    __typename?: 'Mutation'
    createModerationBoundary: {
        __typename?: 'ModerationBoundaryView'
        id: number
        account: number
        queue: string
        type: string
        severityCategory?: string | null
        probability?: number | null
        classifier: {
            __typename?: 'ClassifierView'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription: string
        }
    }
}

export type DeleteModerationBoundaryMutationVariables = Exact<{
    moderationBoundaryId: Scalars['Int']['input']
}>

export type DeleteModerationBoundaryMutation = {
    __typename?: 'Mutation'
    deleteModerationBoundary: {
        __typename?: 'ModerationBoundaryView'
        id: number
        account: number
        queue: string
        type: string
        severityCategory?: string | null
        probability?: number | null
        classifier: {
            __typename?: 'ClassifierView'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription: string
        }
    }
}

export type BulkUpdateModerationBoundaryMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    moderationBoundaries:
        | Array<ModerationBoundaryCreate>
        | ModerationBoundaryCreate
}>

export type BulkUpdateModerationBoundaryMutation = {
    __typename?: 'Mutation'
    bulkUpdateModerationBoundary: boolean
}

export type CopyModerationBoundariesMutationVariables = Exact<{
    sourceAccountId: Scalars['Int']['input']
    targetAccountIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type CopyModerationBoundariesMutation = {
    __typename?: 'Mutation'
    copyModerationBoundaries: boolean
}

export type UpdateLlmClassificationPromptMutationVariables = Exact<{
    promptId: Scalars['Int']['input']
    prompt: Scalars['String']['input']
    category: Scalars['String']['input']
}>

export type UpdateLlmClassificationPromptMutation = {
    __typename?: 'Mutation'
    updateLlmClassificationPrompt: {
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    }
}

export type CreateLlmClassificationPromptMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    prompt: Scalars['String']['input']
    category: Scalars['String']['input']
}>

export type CreateLlmClassificationPromptMutation = {
    __typename?: 'Mutation'
    createLlmClassificationPrompt: {
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    }
}

export type DeleteLlmClassificationPromptMutationVariables = Exact<{
    promptId: Scalars['Int']['input']
}>

export type DeleteLlmClassificationPromptMutation = {
    __typename?: 'Mutation'
    deleteLlmClassificationPrompt: boolean
}

export type SaveLlmReplyPromptMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    prompt: Scalars['String']['input']
}>

export type SaveLlmReplyPromptMutation = {
    __typename?: 'Mutation'
    saveLlmReplyPrompt: {
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    }
}

export type LikeContentMutationVariables = Exact<{
    like: Scalars['Boolean']['input']
    contentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type LikeContentMutation = {
    __typename?: 'Mutation'
    likeContent: Array<{
        __typename?: 'ContentActionResult'
        success: boolean
        contentId: number
        message?: string | null
        contentAction?: {
            __typename?: 'ContentActionView'
            id: number
            action: string
            actionOn: any
            automated: boolean
            active: boolean
            contentId: number
            userId?: number | null
            queued: boolean
            retryAt?: any | null
            message?: string | null
        } | null
    }>
}

export type ReplyToContentMutationVariables = Exact<{
    message: Scalars['String']['input']
    contentId: Scalars['Int']['input']
}>

export type ReplyToContentMutation = {
    __typename?: 'Mutation'
    replyToContent: {
        __typename?: 'ContentActionView'
        id: number
        action: string
        actionOn: any
        automated: boolean
        active: boolean
        contentId: number
        userId?: number | null
        queued: boolean
        retryAt?: any | null
        message?: string | null
    }
}

export type EditAccountTeamMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    teamId?: InputMaybe<Scalars['Int']['input']>
}>

export type EditAccountTeamMutation = {
    __typename?: 'Mutation'
    editAccountTeam: {
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }
}

export type UpdateShadowBanningMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    enabled: Scalars['Boolean']['input']
    threshold: Scalars['Int']['input']
    rangeInDays: Scalars['Int']['input']
}>

export type UpdateShadowBanningMutation = {
    __typename?: 'Mutation'
    updateShadowBanning: {
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }
}

export type RemoveSpamItemsMutationVariables = Exact<{
    contentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type RemoveSpamItemsMutation = {
    __typename?: 'Mutation'
    removeSpamItems: boolean
}

export type AccountRecordItemFragment = {
    __typename?: 'AccountRecord'
    id: number
    accountName?: string | null
    autoDeleteSevereContent: boolean
    autoHideSevereContent: boolean
    autoMuteSevereAccount: boolean
    autoHideAllAdComments?: boolean | null
    classifyContent: boolean
    createdOn: any
    fetchContent: boolean
    isSubscribed: boolean
    lastFetched?: any | null
    latestFetchedServiceContentId?: string | null
    pageId?: string | null
    scrapeContent: boolean
    serviceAccountId: string
    serviceAccountUsername?: string | null
    socialMediaServiceId: number
    teamId?: number | null
    updatedOn: any
    userId: number
    profileCollectionEnabled: boolean
    profileModerationEnabled: boolean
    llmClassificationEnabled: boolean
    llmEnableSuggestedReplies: boolean
    intelligenceEnabled: boolean
    socialMediaServiceShortName?: string | null
    socialMediaServiceDisplayName?: string | null
    hasAuthorisation: boolean
    teamName?: string | null
    ownedBy?: string | null
    adsLastFetched?: any | null
    postsLastFetched?: any | null
    fetchPosts: boolean
    fetchAds: boolean
    shadowBanningEnabled: boolean
    shadowBanningThreshold: number
    shadowBanningRangeInDays: number
}

export type ContentActionResultFragment = {
    __typename?: 'ContentActionResult'
    success: boolean
    contentId: number
    message?: string | null
    contentAction?: {
        __typename?: 'ContentActionView'
        id: number
        action: string
        actionOn: any
        automated: boolean
        active: boolean
        contentId: number
        userId?: number | null
        queued: boolean
        retryAt?: any | null
        message?: string | null
    } | null
}

export type ContentActionViewFragment = {
    __typename?: 'ContentActionView'
    id: number
    action: string
    actionOn: any
    automated: boolean
    active: boolean
    contentId: number
    userId?: number | null
    queued: boolean
    retryAt?: any | null
    message?: string | null
}

export type CreateTeamMutationVariables = Exact<{
    teamName: Scalars['String']['input']
}>

export type CreateTeamMutation = {
    __typename?: 'Mutation'
    createTeam: {
        __typename?: 'TeamView'
        id: number
        name: string
        createdAt: any
    }
}

export type EditTeamMutationVariables = Exact<{
    teamId: Scalars['Int']['input']
    name: Scalars['String']['input']
}>

export type EditTeamMutation = {
    __typename?: 'Mutation'
    editTeam: { __typename?: 'TeamView'; id: number; name: string }
}

export type DeleteTeamMutationVariables = Exact<{
    teamId: Scalars['Int']['input']
}>

export type DeleteTeamMutation = {
    __typename?: 'Mutation'
    deleteTeam: boolean
}

export type CreateKeywordMutationVariables = Exact<{
    params: CreateKeywordParamsView
}>

export type CreateKeywordMutation = {
    __typename?: 'Mutation'
    createKeyword: {
        __typename?: 'KeywordView'
        keyword: string
        regex: boolean
        allowableRegex?: string | null
        description?: string | null
        accountId: number
        classifierDetailsId: number
    }
}

export type DeleteKeywordMutationVariables = Exact<{
    keywordId: Scalars['Int']['input']
}>

export type DeleteKeywordMutation = {
    __typename?: 'Mutation'
    deleteKeyword: boolean
}

export type CopyKeywordsToAccountMutationVariables = Exact<{
    sourceAccountId: Scalars['Int']['input']
    targetAccountId: Scalars['Int']['input']
}>

export type CopyKeywordsToAccountMutation = {
    __typename?: 'Mutation'
    copyKeywordsToAccount: boolean
}

export type ValidateUserIdentityMutationVariables = Exact<{
    [key: string]: never
}>

export type ValidateUserIdentityMutation = {
    __typename?: 'Mutation'
    validateUserIdentity: {
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }
}

export type CreateUserMutationVariables = Exact<{
    userParams: UserCreateParamsView
}>

export type CreateUserMutation = {
    __typename?: 'Mutation'
    createUser: boolean
}

export type EditUserMutationVariables = Exact<{
    userParams: UserEditParamsView
}>

export type EditUserMutation = { __typename?: 'Mutation'; editUser: boolean }

export type DeleteUserMutationVariables = Exact<{
    userId: Scalars['Int']['input']
}>

export type DeleteUserMutation = {
    __typename?: 'Mutation'
    deleteUser: boolean
}

export type MetaHistoricFetchMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    from: Scalars['DateTime']['input']
    to: Scalars['DateTime']['input']
}>

export type MetaHistoricFetchMutation = {
    __typename?: 'Mutation'
    metaHistoricFetch: boolean
}

export type TiktokAuthCodeToAccessTokenMutationVariables = Exact<{
    authCode: Scalars['String']['input']
    code: Scalars['String']['input']
}>

export type TiktokAuthCodeToAccessTokenMutation = {
    __typename?: 'Mutation'
    tiktokAuthCodeToAccessToken: {
        __typename?: 'TikTokAccountSelectionView'
        key: { __typename?: 'TikTokSessionKey'; key: string; expiry: any }
        adAccounts: Array<{
            __typename?: 'TikTokAdAccountView'
            advertiserId: string
            advertiserName: string
        }>
    }
}

export type TiktokCreateUserAccountWithAuthMutationVariables = Exact<{
    authCode: Scalars['String']['input']
    code: Scalars['String']['input']
    redirectUri: Scalars['String']['input']
}>

export type TiktokCreateUserAccountWithAuthMutation = {
    __typename?: 'Mutation'
    tiktokCreateUserAccountWithAuth: {
        __typename?: 'TikTokUserAccountSelectionView'
        key: { __typename?: 'TikTokSessionKey'; key: string; expiry: any }
        accountData: {
            __typename?: 'AuthorisedAccountView'
            id: number
            serviceAccountUsername?: string | null
            serviceAccountId: string
            accountName?: string | null
            pageId?: string | null
            isFailing: boolean
            failureDate?: any | null
            lastFailureNotificationDate?: any | null
            socialMediaServiceName: string
        }
    }
}

export type CreateTiktokAccountsMutationVariables = Exact<{
    sessionKey: Scalars['String']['input']
    adAccountIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type CreateTiktokAccountsMutation = {
    __typename?: 'Mutation'
    createTiktokAccounts: Array<{
        __typename?: 'AuthorisedAccountView'
        id: number
        serviceAccountUsername?: string | null
        serviceAccountId: string
        accountName?: string | null
        pageId?: string | null
        isFailing: boolean
        failureDate?: any | null
        lastFailureNotificationDate?: any | null
        socialMediaServiceName: string
    }>
}

export type TikTokUserAccountSelectionItemFragment = {
    __typename?: 'TikTokUserAccountSelectionView'
    key: { __typename?: 'TikTokSessionKey'; key: string; expiry: any }
    accountData: {
        __typename?: 'AuthorisedAccountView'
        id: number
        serviceAccountUsername?: string | null
        serviceAccountId: string
        accountName?: string | null
        pageId?: string | null
        isFailing: boolean
        failureDate?: any | null
        lastFailureNotificationDate?: any | null
        socialMediaServiceName: string
    }
}

export type TikTokAuthorisedAccountItemFragment = {
    __typename?: 'AuthorisedAccountView'
    id: number
    serviceAccountUsername?: string | null
    serviceAccountId: string
    accountName?: string | null
    pageId?: string | null
    isFailing: boolean
    failureDate?: any | null
    lastFailureNotificationDate?: any | null
    socialMediaServiceName: string
}

export type TikTokAccountSelectionItemFragment = {
    __typename?: 'TikTokAccountSelectionView'
    key: { __typename?: 'TikTokSessionKey'; key: string; expiry: any }
    adAccounts: Array<{
        __typename?: 'TikTokAdAccountView'
        advertiserId: string
        advertiserName: string
    }>
}

export type TikTokAccountsSelectionItemFragment = {
    __typename?: 'TikTokAccountSelectionView'
    key: { __typename?: 'TikTokSessionKey'; key: string; expiry: any }
}

export type TikTokAdAccountItemFragment = {
    __typename?: 'TikTokAdAccountView'
    advertiserId: string
    advertiserName: string
}

export type TikTokSessionKeyItemFragment = {
    __typename?: 'TikTokSessionKey'
    key: string
    expiry: any
}

export type ClassifierSearchParamsViewItemFragment = {
    __typename?: 'ClassifierSearchParamsView'
    classifierName: string
    threshold?: number | null
    category?: string | null
}

export type ContentSearchParamsViewItemFragment = {
    __typename?: 'ContentSearchParamsView'
    accountIds?: Array<number> | null
    teamIds?: Array<number> | null
    socialMediaServiceIds?: Array<number> | null
    since?: any | null
    before?: any | null
    timeDuration?: string | null
    timeOffset?: string | null
    containsText?: string | null
    moderationCode?: Array<number> | null
    moderationString?: Array<string> | null
    skipResults?: number | null
    maxResults?: number | null
    adsOrOrganic?: AdsOrOrganicFilterView | null
    contentIds?: Array<number> | null
    owned?: boolean | null
    readFilter?: ReadFilterView | null
    classifierSearchParams?: Array<{
        __typename?: 'ClassifierSearchParamsView'
        classifierName: string
        threshold?: number | null
        category?: string | null
    }> | null
}

export type ContentFeedViewItemFragment = {
    __typename?: 'ContentFeedView'
    id: number
    userId: number
    displayName: string
    description?: string | null
    createdAt: any
    updatedAt: any
    searchParameters: {
        __typename?: 'ContentSearchParamsView'
        accountIds?: Array<number> | null
        teamIds?: Array<number> | null
        socialMediaServiceIds?: Array<number> | null
        since?: any | null
        before?: any | null
        timeDuration?: string | null
        timeOffset?: string | null
        containsText?: string | null
        moderationCode?: Array<number> | null
        moderationString?: Array<string> | null
        skipResults?: number | null
        maxResults?: number | null
        adsOrOrganic?: AdsOrOrganicFilterView | null
        contentIds?: Array<number> | null
        owned?: boolean | null
        readFilter?: ReadFilterView | null
        classifierSearchParams?: Array<{
            __typename?: 'ClassifierSearchParamsView'
            classifierName: string
            threshold?: number | null
            category?: string | null
        }> | null
    }
}

export type GetMyContentFeedsQueryVariables = Exact<{ [key: string]: never }>

export type GetMyContentFeedsQuery = {
    __typename?: 'Query'
    getMyContentFeeds: Array<{
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }>
}

export type GetContentFeedsForUserQueryVariables = Exact<{
    userId: Scalars['Int']['input']
}>

export type GetContentFeedsForUserQuery = {
    __typename?: 'Query'
    getContentFeedsForUser: Array<{
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }>
}

export type GetContentFeedQueryVariables = Exact<{
    contentFeedId: Scalars['Int']['input']
}>

export type GetContentFeedQuery = {
    __typename?: 'Query'
    getContentFeed: {
        __typename?: 'ContentFeedView'
        id: number
        userId: number
        displayName: string
        description?: string | null
        createdAt: any
        updatedAt: any
        searchParameters: {
            __typename?: 'ContentSearchParamsView'
            accountIds?: Array<number> | null
            teamIds?: Array<number> | null
            socialMediaServiceIds?: Array<number> | null
            since?: any | null
            before?: any | null
            timeDuration?: string | null
            timeOffset?: string | null
            containsText?: string | null
            moderationCode?: Array<number> | null
            moderationString?: Array<string> | null
            skipResults?: number | null
            maxResults?: number | null
            adsOrOrganic?: AdsOrOrganicFilterView | null
            contentIds?: Array<number> | null
            owned?: boolean | null
            readFilter?: ReadFilterView | null
            classifierSearchParams?: Array<{
                __typename?: 'ClassifierSearchParamsView'
                classifierName: string
                threshold?: number | null
                category?: string | null
            }> | null
        }
    }
}

export type GetNotificationConfigurationForAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetNotificationConfigurationForAccountQuery = {
    __typename?: 'Query'
    getNotificationConfigurationForAccount: Array<{
        __typename?: 'NotificationConfigurationView'
        id: number
        accountId: number
        recipientListId: string
        notificationTemplateId: string
    }>
}

export type GetNotificationConfigurationQueryVariables = Exact<{
    notificationConfigurationId: Scalars['Int']['input']
}>

export type GetNotificationConfigurationQuery = {
    __typename?: 'Query'
    getNotificationConfiguration: {
        __typename?: 'NotificationConfigurationView'
        id: number
        accountId: number
        recipientListId: string
        notificationTemplateId: string
    }
}

export type NotificationConfigurationViewItemFragment = {
    __typename?: 'NotificationConfigurationView'
    id: number
    accountId: number
    recipientListId: string
    notificationTemplateId: string
}

export type GetDashboardQueryVariables = Exact<{
    dashboardId: Scalars['Int']['input']
}>

export type GetDashboardQuery = {
    __typename?: 'Query'
    getDashboard: {
        __typename?: 'DashboardView'
        id: number
        displayName: string
        description: string
        extEmbeddedId: string
        createdAt: any
        updatedAt: any
    }
}

export type GetEditableDashboardQueryVariables = Exact<{
    dashboardId: Scalars['Int']['input']
}>

export type GetEditableDashboardQuery = {
    __typename?: 'Query'
    getEditableDashboard: {
        __typename?: 'EditableDashboardView'
        dashboard: {
            __typename?: 'DashboardView'
            id: number
            displayName: string
            description: string
            extEmbeddedId: string
            createdAt: any
            updatedAt: any
        }
        users: Array<{
            __typename?: 'UserView'
            id: number
            username: string
            createdOn: any
            updatedAt?: any | null
            stripeCustomerId?: string | null
            stripeSubscriptionId?: string | null
            stripeSubscriptionStatus?: string | null
            dashboardId?: string | null
            dashboardEnabled?: boolean | null
            referrerId?: number | null
            allowAccessOverride?: boolean | null
            admin?: boolean | null
            canLikeContent: boolean
            canReplyToContent: boolean
            intelligencePageViewer: boolean
            canUseReadStatus: boolean
            feedsEnabled: boolean
        }>
    }
}

export type GetDashboardsQueryVariables = Exact<{ [key: string]: never }>

export type GetDashboardsQuery = {
    __typename?: 'Query'
    getDashboards: Array<{
        __typename?: 'DashboardView'
        id: number
        displayName: string
        description: string
        extEmbeddedId: string
        createdAt: any
        updatedAt: any
    }>
}

export type EditableDashboardViewItemFragment = {
    __typename?: 'EditableDashboardView'
    dashboard: {
        __typename?: 'DashboardView'
        id: number
        displayName: string
        description: string
        extEmbeddedId: string
        createdAt: any
        updatedAt: any
    }
    users: Array<{
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }>
}

export type DashboardViewItemFragment = {
    __typename?: 'DashboardView'
    id: number
    displayName: string
    description: string
    extEmbeddedId: string
    createdAt: any
    updatedAt: any
}

export type GetEmbeddedDashboardQueryVariables = Exact<{
    dashboardId: Scalars['Int']['input']
}>

export type GetEmbeddedDashboardQuery = {
    __typename?: 'Query'
    getEmbeddedDashboard: {
        __typename?: 'EmbeddedDashboardView'
        guestToken: string
        workspaceUrl: string
        dashboard: {
            __typename?: 'DashboardView'
            id: number
            displayName: string
            description: string
            extEmbeddedId: string
            createdAt: any
            updatedAt: any
        }
    }
}

export type EmbeddedDashboardViewItemFragment = {
    __typename?: 'EmbeddedDashboardView'
    guestToken: string
    workspaceUrl: string
    dashboard: {
        __typename?: 'DashboardView'
        id: number
        displayName: string
        description: string
        extEmbeddedId: string
        createdAt: any
        updatedAt: any
    }
}

export type GetModerationBoundariesQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetModerationBoundariesQuery = {
    __typename?: 'Query'
    getModerationBoundaries: Array<{
        __typename?: 'ModerationBoundaryView'
        id: number
        account: number
        queue: string
        type: string
        severityCategory?: string | null
        probability?: number | null
        classifier: {
            __typename?: 'ClassifierView'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription: string
        }
    }>
}

export type ModerationBoundaryViewItemFragment = {
    __typename?: 'ModerationBoundaryView'
    id: number
    account: number
    queue: string
    type: string
    severityCategory?: string | null
    probability?: number | null
    classifier: {
        __typename?: 'ClassifierView'
        id: number
        shortName: string
        displayName: string
        shortDescription: string
        longDescription: string
    }
}

export type GetLlmClassificationPromptQueryVariables = Exact<{
    promptId: Scalars['Int']['input']
}>

export type GetLlmClassificationPromptQuery = {
    __typename?: 'Query'
    getLlmClassificationPrompt: {
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    }
}

export type GetLlmClassificationPromptsQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetLlmClassificationPromptsQuery = {
    __typename?: 'Query'
    getLlmClassificationPrompts: Array<{
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    }>
}

export type GetLlmClassificationCategoryQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
    llmClassifierDetailsName: Scalars['String']['input']
}>

export type GetLlmClassificationCategoryQuery = {
    __typename?: 'Query'
    getLlmClassificationCategory?: string | null
}

export type GetLlmReplyPromptQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetLlmReplyPromptQuery = {
    __typename?: 'Query'
    getLlmReplyPrompt?: {
        __typename?: 'PromptView'
        id: number
        prompt: string
        category?: string | null
        createdAt: any
    } | null
}

export type PromptViewItemFragment = {
    __typename?: 'PromptView'
    id: number
    prompt: string
    category?: string | null
    createdAt: any
}

export type GetAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetAccountQuery = {
    __typename?: 'Query'
    getAccount: {
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }
}

export type GetAccountsForMeQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountsForMeQuery = {
    __typename?: 'Query'
    getAccountsForMe: Array<{
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }>
}

export type GetAccountsInTeamQueryVariables = Exact<{
    params: GetAccountsInTeamParamsView
}>

export type GetAccountsInTeamQuery = {
    __typename?: 'Query'
    getAccountsInTeam: Array<{
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }>
}

export type GetKeywordsForAccountQueryVariables = Exact<{
    params: KeywordParamsList
}>

export type GetKeywordsForAccountQuery = {
    __typename?: 'Query'
    getKeywordsForAccount: Array<{
        __typename?: 'KeywordView'
        id: number
        keyword: string
        regex: boolean
        allowableRegex?: string | null
        description?: string | null
        accountId: number
        classifierDetailsDisplayName: string
        classifierDetailsId: number
        createdAt: any
    }>
}

export type GetUserByIdQueryVariables = Exact<{
    id: Scalars['Int']['input']
}>

export type GetUserByIdQuery = {
    __typename?: 'Query'
    getUserById: {
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }
}

export type GetUsersByTeamIdQueryVariables = Exact<{
    teamId: Scalars['Int']['input']
}>

export type GetUsersByTeamIdQuery = {
    __typename?: 'Query'
    getUsersByTeamId: Array<{
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }>
}

export type GetUserTeamsQueryVariables = Exact<{
    userId: Scalars['Int']['input']
}>

export type GetUserTeamsQuery = {
    __typename?: 'Query'
    getUserTeams: Array<{
        __typename?: 'TeamView'
        id: number
        name: string
        createdAt: any
    }>
}

export type GetUserIfAllowedQueryVariables = Exact<{
    id: Scalars['Int']['input']
}>

export type GetUserIfAllowedQuery = {
    __typename?: 'Query'
    getUserIfAllowed: {
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }
}

export type SearchUsersQueryVariables = Exact<{
    params: UserSearchParams
}>

export type SearchUsersQuery = {
    __typename?: 'Query'
    searchUsers: Array<{
        __typename?: 'UserView'
        id: number
        username: string
        createdOn: any
        updatedAt?: any | null
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        dashboardId?: string | null
        dashboardEnabled?: boolean | null
        referrerId?: number | null
        allowAccessOverride?: boolean | null
        admin?: boolean | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
    }>
}

export type SearchUsersCountQueryVariables = Exact<{
    params: UserSearchParams
}>

export type SearchUsersCountQuery = {
    __typename?: 'Query'
    searchUsersCount: number
}

export type GetCurrentAuthorisationValidityQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type GetCurrentAuthorisationValidityQuery = {
    __typename?: 'Query'
    getCurrentAuthorisationValidity: boolean
}

export type CheckMetaSubscriptionForAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type CheckMetaSubscriptionForAccountQuery = {
    __typename?: 'Query'
    checkMetaSubscriptionForAccount: boolean
}

export type CreateMetaSubscriptionForAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type CreateMetaSubscriptionForAccountQuery = {
    __typename?: 'Query'
    createMetaSubscriptionForAccount: boolean
}

export type DebugMetaAuthorisationForAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type DebugMetaAuthorisationForAccountQuery = {
    __typename?: 'Query'
    debugMetaAuthorisationForAccount: {
        __typename?: 'DebugTokenResponseView'
        appId: string
        dataType: string
        application: string
        dataAccessExpiresAt: number
        expiresAt: number
        isValid: boolean
        issuedAt?: number | null
        scopes: Array<string>
        userId: string
    }
}

export type CheckMetaAuthorisationForAccountQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type CheckMetaAuthorisationForAccountQuery = {
    __typename?: 'Query'
    checkMetaAuthorisationForAccount: boolean
}

export type DebugTokenResponseViewItemFragment = {
    __typename?: 'DebugTokenResponseView'
    appId: string
    dataType: string
    application: string
    dataAccessExpiresAt: number
    expiresAt: number
    isValid: boolean
    issuedAt?: number | null
    scopes: Array<string>
    userId: string
}

export type UserItemFragment = {
    __typename?: 'UserView'
    id: number
    username: string
    createdOn: any
    updatedAt?: any | null
    stripeCustomerId?: string | null
    stripeSubscriptionId?: string | null
    stripeSubscriptionStatus?: string | null
    dashboardId?: string | null
    dashboardEnabled?: boolean | null
    referrerId?: number | null
    allowAccessOverride?: boolean | null
    admin?: boolean | null
    canLikeContent: boolean
    canReplyToContent: boolean
    intelligencePageViewer: boolean
    canUseReadStatus: boolean
    feedsEnabled: boolean
}

export type GetUsersPermissionsQueryVariables = Exact<{
    usernames: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type GetUsersPermissionsQuery = {
    __typename?: 'Query'
    getUserPermissions: {
        __typename?: 'UsersPermissionsView'
        userPermissions: any
    }
}

export type GetTeamByIdQueryVariables = Exact<{
    teamId: Scalars['Int']['input']
}>

export type GetTeamByIdQuery = {
    __typename?: 'Query'
    getTeamById: {
        __typename?: 'TeamView'
        id: number
        name: string
        createdAt: any
    }
}

export type GetTeamsQueryVariables = Exact<{ [key: string]: never }>

export type GetTeamsQuery = {
    __typename?: 'Query'
    getTeams: Array<{
        __typename?: 'TeamView'
        id: number
        name: string
        createdAt: any
    }>
}

export type GetOauthUrlQueryVariables = Exact<{
    socialMediaService: Scalars['String']['input']
    baseUrl: Scalars['String']['input']
}>

export type GetOauthUrlQuery = { __typename?: 'Query'; getOauthUrl: string }

export type SearchTeamsQueryVariables = Exact<{
    params: TeamsSearchParamsView
}>

export type SearchTeamsQuery = {
    __typename?: 'Query'
    searchTeams: Array<{
        __typename?: 'TeamView'
        id: number
        name: string
        createdAt: any
    }>
}

export type TeamItemFragment = {
    __typename?: 'TeamView'
    id: number
    name: string
    createdAt: any
}

export type GetProfilesByIdsQueryVariables = Exact<{
    profileIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type GetProfilesByIdsQuery = {
    __typename?: 'Query'
    getProfilesByIds: Array<{
        __typename?: 'ProfileView'
        id: number
        username: string
        serviceProfileId: string
        followers?: number | null
        following?: number | null
        posts?: number | null
        profileImageUrl?: string | null
        profileUrl: string
        profileImagePath?: string | null
    }>
}

export type GetProfileQueryVariables = Exact<{
    contentId: Scalars['Int']['input']
}>

export type GetProfileQuery = {
    __typename?: 'Query'
    getProfile: {
        __typename?: 'ProfileView'
        id: number
        username: string
        serviceProfileId: string
        followers?: number | null
        following?: number | null
        posts?: number | null
        profileImageUrl?: string | null
        profileUrl: string
        profileImagePath?: string | null
    }
}

export type SearchProfilesQueryVariables = Exact<{
    filter?: InputMaybe<ProfileFilterInput>
    sort?: InputMaybe<ProfileSortInput>
    limit: Scalars['Int']['input']
    offset: Scalars['Int']['input']
}>

export type SearchProfilesQuery = {
    __typename?: 'Query'
    searchProfiles: Array<{
        __typename?: 'ProfileView'
        id: number
        username: string
        serviceProfileId: string
        followers?: number | null
        following?: number | null
        posts?: number | null
        profileImageUrl?: string | null
        profileUrl: string
        profileImagePath?: string | null
    }>
}

export type CountProfilesQueryVariables = Exact<{
    filter?: InputMaybe<ProfileFilterInput>
}>

export type CountProfilesQuery = { __typename?: 'Query'; countProfiles: number }

export type SearchAccountsQueryVariables = Exact<{
    params: SearchAccountsParamsView
}>

export type SearchAccountsQuery = {
    __typename?: 'Query'
    searchAccounts: {
        __typename?: 'SearchAccountResponse'
        total: number
        accounts: Array<{
            __typename?: 'AccountRecord'
            id: number
            accountName?: string | null
            autoDeleteSevereContent: boolean
            autoHideSevereContent: boolean
            autoMuteSevereAccount: boolean
            autoHideAllAdComments?: boolean | null
            classifyContent: boolean
            createdOn: any
            fetchContent: boolean
            isSubscribed: boolean
            lastFetched?: any | null
            latestFetchedServiceContentId?: string | null
            pageId?: string | null
            scrapeContent: boolean
            serviceAccountId: string
            serviceAccountUsername?: string | null
            socialMediaServiceId: number
            teamId?: number | null
            updatedOn: any
            userId: number
            profileCollectionEnabled: boolean
            profileModerationEnabled: boolean
            llmClassificationEnabled: boolean
            llmEnableSuggestedReplies: boolean
            intelligenceEnabled: boolean
            socialMediaServiceShortName?: string | null
            socialMediaServiceDisplayName?: string | null
            hasAuthorisation: boolean
            teamName?: string | null
            ownedBy?: string | null
            adsLastFetched?: any | null
            postsLastFetched?: any | null
            fetchPosts: boolean
            fetchAds: boolean
            shadowBanningEnabled: boolean
            shadowBanningThreshold: number
            shadowBanningRangeInDays: number
        }>
    }
}

export type ProfileItemFragment = {
    __typename?: 'ProfileView'
    id: number
    username: string
    serviceProfileId: string
    followers?: number | null
    following?: number | null
    posts?: number | null
    profileImageUrl?: string | null
    profileUrl: string
    profileImagePath?: string | null
}

export type GetSummaryForAccountsQueryVariables = Exact<{
    params: GetSummaryMetricsParams
}>

export type GetSummaryForAccountsQuery = {
    __typename?: 'Query'
    getSummaryForAccounts: {
        __typename?: 'ContentSummary'
        countSafe: number
        countSuspect: number
        countSevere: number
    }
}

export type ContentSummaryItemFragment = {
    __typename?: 'ContentSummary'
    countSafe: number
    countSuspect: number
    countSevere: number
}

export type SearchContentOutcomeQueryVariables = Exact<{
    params: SearchParamsForContentOutcome
}>

export type SearchContentOutcomeQuery = {
    __typename?: 'Query'
    searchContentOutcome: Array<{
        __typename?: 'ContentOutcomeView'
        id: number
        serviceContentId: string
        content: string
        contentType: ContentTypeView
        socialMediaServiceName: string
        isPublic: boolean
        serviceAuthorId?: string | null
        serviceAuthorName?: string | null
        serviceAuthorDisplayUsername?: string | null
        shouldEnrich: boolean
        moderation?: string | null
        authorised: boolean
        socialMediaAuthorLink?: string | null
        socialMediaPostLink?: string | null
        socialMediaContentLink?: string | null
        socialMediaContentName: string
        datePosted?: any | null
        isReply: boolean
        inReplyToUserId?: string | null
        inReplyToUsername?: string | null
        inReplyToStatusId?: string | null
        originalPostAuthorId?: string | null
        originalPostAuthorName?: string | null
        originalPostId?: string | null
        originalPostLinkId?: string | null
        originalPostLink?: string | null
        isOwned?: boolean | null
        authorProfileId?: number | null
        account: {
            __typename?: 'AccountRecord'
            id: number
            accountName?: string | null
            autoDeleteSevereContent: boolean
            autoHideSevereContent: boolean
            autoMuteSevereAccount: boolean
            autoHideAllAdComments?: boolean | null
            classifyContent: boolean
            createdOn: any
            fetchContent: boolean
            isSubscribed: boolean
            lastFetched?: any | null
            latestFetchedServiceContentId?: string | null
            pageId?: string | null
            scrapeContent: boolean
            serviceAccountId: string
            serviceAccountUsername?: string | null
            socialMediaServiceId: number
            teamId?: number | null
            updatedOn: any
            userId: number
            profileCollectionEnabled: boolean
            profileModerationEnabled: boolean
            llmClassificationEnabled: boolean
            llmEnableSuggestedReplies: boolean
            intelligenceEnabled: boolean
            socialMediaServiceShortName?: string | null
            socialMediaServiceDisplayName?: string | null
            hasAuthorisation: boolean
            teamName?: string | null
            ownedBy?: string | null
            adsLastFetched?: any | null
            postsLastFetched?: any | null
            fetchPosts: boolean
            fetchAds: boolean
            shadowBanningEnabled: boolean
            shadowBanningThreshold: number
            shadowBanningRangeInDays: number
        }
        actions: Array<{
            __typename?: 'ContentActionView'
            id: number
            action: string
            actionOn: any
            active: boolean
            automated: boolean
            contentId: number
            queued: boolean
            retryAt?: any | null
            userId?: number | null
            message?: string | null
        }>
        media: Array<{
            __typename?: 'ContentMediaView'
            serviceMediaId: string
            publicUrl: string
            mediaType: string
            mediaClassifications: Array<{
                __typename?: 'ContentClassificationView'
                classification?: number | null
                severity?: string | null
                classifierName: string
            }>
        }>
        readReceipts: Array<{
            __typename?: 'ReadReceiptView'
            userId: number
            contentOutcomeId: number
            createdAt: any
        }>
        allClassifications: Array<{
            __typename?: 'ContentClassificationView'
            classifierName: string
            classification?: number | null
            severity?: string | null
        }>
    }>
}

export type SearchContentOutcomeCountQueryVariables = Exact<{
    params: SearchParamsForContentOutcome
}>

export type SearchContentOutcomeCountQuery = {
    __typename?: 'Query'
    searchContentOutcomeCount: number
}

export type GetContentByIdQueryVariables = Exact<{
    id: Scalars['Int']['input']
}>

export type GetContentByIdQuery = {
    __typename?: 'Query'
    getContentById: {
        __typename?: 'ContentOutcomeView'
        id: number
        serviceContentId: string
        content: string
        contentType: ContentTypeView
        socialMediaServiceName: string
        isPublic: boolean
        serviceAuthorId?: string | null
        serviceAuthorName?: string | null
        serviceAuthorDisplayUsername?: string | null
        shouldEnrich: boolean
        moderation?: string | null
        authorised: boolean
        socialMediaAuthorLink?: string | null
        socialMediaPostLink?: string | null
        socialMediaContentLink?: string | null
        socialMediaContentName: string
        datePosted?: any | null
        isReply: boolean
        inReplyToUserId?: string | null
        inReplyToUsername?: string | null
        inReplyToStatusId?: string | null
        originalPostAuthorId?: string | null
        originalPostAuthorName?: string | null
        originalPostId?: string | null
        originalPostLinkId?: string | null
        originalPostLink?: string | null
        isOwned?: boolean | null
        authorProfileId?: number | null
        account: {
            __typename?: 'AccountRecord'
            id: number
            accountName?: string | null
            autoDeleteSevereContent: boolean
            autoHideSevereContent: boolean
            autoMuteSevereAccount: boolean
            autoHideAllAdComments?: boolean | null
            classifyContent: boolean
            createdOn: any
            fetchContent: boolean
            isSubscribed: boolean
            lastFetched?: any | null
            latestFetchedServiceContentId?: string | null
            pageId?: string | null
            scrapeContent: boolean
            serviceAccountId: string
            serviceAccountUsername?: string | null
            socialMediaServiceId: number
            teamId?: number | null
            updatedOn: any
            userId: number
            profileCollectionEnabled: boolean
            profileModerationEnabled: boolean
            llmClassificationEnabled: boolean
            llmEnableSuggestedReplies: boolean
            intelligenceEnabled: boolean
            socialMediaServiceShortName?: string | null
            socialMediaServiceDisplayName?: string | null
            hasAuthorisation: boolean
            teamName?: string | null
            ownedBy?: string | null
            adsLastFetched?: any | null
            postsLastFetched?: any | null
            fetchPosts: boolean
            fetchAds: boolean
            shadowBanningEnabled: boolean
            shadowBanningThreshold: number
            shadowBanningRangeInDays: number
        }
        actions: Array<{
            __typename?: 'ContentActionView'
            id: number
            action: string
            actionOn: any
            active: boolean
            automated: boolean
            contentId: number
            queued: boolean
            retryAt?: any | null
            userId?: number | null
            message?: string | null
        }>
        media: Array<{
            __typename?: 'ContentMediaView'
            serviceMediaId: string
            publicUrl: string
            mediaType: string
            mediaClassifications: Array<{
                __typename?: 'ContentClassificationView'
                classification?: number | null
                severity?: string | null
                classifierName: string
            }>
        }>
        readReceipts: Array<{
            __typename?: 'ReadReceiptView'
            userId: number
            contentOutcomeId: number
            createdAt: any
        }>
        allClassifications: Array<{
            __typename?: 'ContentClassificationView'
            classifierName: string
            classification?: number | null
            severity?: string | null
        }>
    }
}

export type ContentOutcomeViewItemFragment = {
    __typename?: 'ContentOutcomeView'
    id: number
    serviceContentId: string
    content: string
    contentType: ContentTypeView
    socialMediaServiceName: string
    isPublic: boolean
    serviceAuthorId?: string | null
    serviceAuthorName?: string | null
    serviceAuthorDisplayUsername?: string | null
    shouldEnrich: boolean
    moderation?: string | null
    authorised: boolean
    socialMediaAuthorLink?: string | null
    socialMediaPostLink?: string | null
    socialMediaContentLink?: string | null
    socialMediaContentName: string
    datePosted?: any | null
    isReply: boolean
    inReplyToUserId?: string | null
    inReplyToUsername?: string | null
    inReplyToStatusId?: string | null
    originalPostAuthorId?: string | null
    originalPostAuthorName?: string | null
    originalPostId?: string | null
    originalPostLinkId?: string | null
    originalPostLink?: string | null
    isOwned?: boolean | null
    authorProfileId?: number | null
    account: {
        __typename?: 'AccountRecord'
        id: number
        accountName?: string | null
        autoDeleteSevereContent: boolean
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoHideAllAdComments?: boolean | null
        classifyContent: boolean
        createdOn: any
        fetchContent: boolean
        isSubscribed: boolean
        lastFetched?: any | null
        latestFetchedServiceContentId?: string | null
        pageId?: string | null
        scrapeContent: boolean
        serviceAccountId: string
        serviceAccountUsername?: string | null
        socialMediaServiceId: number
        teamId?: number | null
        updatedOn: any
        userId: number
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        llmEnableSuggestedReplies: boolean
        intelligenceEnabled: boolean
        socialMediaServiceShortName?: string | null
        socialMediaServiceDisplayName?: string | null
        hasAuthorisation: boolean
        teamName?: string | null
        ownedBy?: string | null
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        fetchPosts: boolean
        fetchAds: boolean
        shadowBanningEnabled: boolean
        shadowBanningThreshold: number
        shadowBanningRangeInDays: number
    }
    actions: Array<{
        __typename?: 'ContentActionView'
        id: number
        action: string
        actionOn: any
        active: boolean
        automated: boolean
        contentId: number
        queued: boolean
        retryAt?: any | null
        userId?: number | null
        message?: string | null
    }>
    media: Array<{
        __typename?: 'ContentMediaView'
        serviceMediaId: string
        publicUrl: string
        mediaType: string
        mediaClassifications: Array<{
            __typename?: 'ContentClassificationView'
            classification?: number | null
            severity?: string | null
            classifierName: string
        }>
    }>
    readReceipts: Array<{
        __typename?: 'ReadReceiptView'
        userId: number
        contentOutcomeId: number
        createdAt: any
    }>
    allClassifications: Array<{
        __typename?: 'ContentClassificationView'
        classifierName: string
        classification?: number | null
        severity?: string | null
    }>
}

export type ContentActionItemFragment = {
    __typename?: 'ContentActionView'
    id: number
    action: string
    actionOn: any
    active: boolean
    automated: boolean
    contentId: number
    queued: boolean
    retryAt?: any | null
    userId?: number | null
    message?: string | null
}

export type ContentMediaItemFragment = {
    __typename?: 'ContentMediaView'
    serviceMediaId: string
    publicUrl: string
    mediaType: string
    mediaClassifications: Array<{
        __typename?: 'ContentClassificationView'
        classification?: number | null
        severity?: string | null
        classifierName: string
    }>
}

export type ReadReceiptItemFragment = {
    __typename?: 'ReadReceiptView'
    userId: number
    contentOutcomeId: number
    createdAt: any
}

export type ContentClassificationItemFragment = {
    __typename?: 'ContentClassificationView'
    classification?: number | null
    severity?: string | null
    classifierName: string
}

export type GetContentClassifierMetadataQueryVariables = Exact<{
    params: SearchParamsForContentOutcome
}>

export type GetContentClassifierMetadataQuery = {
    __typename?: 'Query'
    getContentClassifierMetadata: Array<{
        __typename?: 'ContentClassifierMetadataView'
        classifierName: string
        classifierDisplayName: string
        classifierId: number
        contentCount: number
    }>
}

export type ContentClassifierMetadataItemFragment = {
    __typename?: 'ContentClassifierMetadataView'
    classifierName: string
    classifierDisplayName: string
    classifierId: number
    contentCount: number
}

export type GetHeatmapQueryVariables = Exact<{
    params: SearchParamsForContentOutcome
}>

export type GetHeatmapQuery = {
    __typename?: 'Query'
    getHeatmap: Array<{
        __typename?: 'HeatmapView'
        foodNeutral: number
        foodApproval: number
        foodAnnoyance: number
        foodRealization: number
        foodDisapproval: number
        foodDisappointment: number
        foodAdmiration: number
        foodExcitement: number
        foodAnger: number
        foodSadness: number
        foodDisgust: number
        foodJoy: number
        foodAmusement: number
        foodConfusion: number
        foodFear: number
        foodOptimism: number
        foodLove: number
        foodDesire: number
        foodCuriosity: number
        foodGratitude: number
        foodSurprise: number
        foodCaring: number
        foodEmbarrassment: number
        foodGrief: number
        foodPride: number
        foodRelief: number
        foodNervousness: number
        foodRemorse: number
        serviceNeutral: number
        serviceApproval: number
        serviceAnnoyance: number
        serviceRealization: number
        serviceDisapproval: number
        serviceDisappointment: number
        serviceAdmiration: number
        serviceExcitement: number
        serviceAnger: number
        serviceSadness: number
        serviceDisgust: number
        serviceJoy: number
        serviceAmusement: number
        serviceConfusion: number
        serviceFear: number
        serviceOptimism: number
        serviceLove: number
        serviceDesire: number
        serviceCuriosity: number
        serviceGratitude: number
        serviceSurprise: number
        serviceCaring: number
        serviceEmbarrassment: number
        serviceGrief: number
        servicePride: number
        serviceRelief: number
        serviceNervousness: number
        serviceRemorse: number
        venueNeutral: number
        venueApproval: number
        venueAnnoyance: number
        venueRealization: number
        venueDisapproval: number
        venueDisappointment: number
        venueAdmiration: number
        venueExcitement: number
        venueAnger: number
        venueSadness: number
        venueDisgust: number
        venueJoy: number
        venueAmusement: number
        venueConfusion: number
        venueFear: number
        venueOptimism: number
        venueLove: number
        venueDesire: number
        venueCuriosity: number
        venueGratitude: number
        venueSurprise: number
        venueCaring: number
        venueEmbarrassment: number
        venueGrief: number
        venuePride: number
        venueRelief: number
        venueNervousness: number
        venueRemorse: number
        atmosphereNeutral: number
        atmosphereApproval: number
        atmosphereAnnoyance: number
        atmosphereRealization: number
        atmosphereDisapproval: number
        atmosphereDisappointment: number
        atmosphereAdmiration: number
        atmosphereExcitement: number
        atmosphereAnger: number
        atmosphereSadness: number
        atmosphereDisgust: number
        atmosphereJoy: number
        atmosphereAmusement: number
        atmosphereConfusion: number
        atmosphereFear: number
        atmosphereOptimism: number
        atmosphereLove: number
        atmosphereDesire: number
        atmosphereCuriosity: number
        atmosphereGratitude: number
        atmosphereSurprise: number
        atmosphereCaring: number
        atmosphereEmbarrassment: number
        atmosphereGrief: number
        atmospherePride: number
        atmosphereRelief: number
        atmosphereNervousness: number
        atmosphereRemorse: number
        facilitiesNeutral: number
        facilitiesApproval: number
        facilitiesAnnoyance: number
        facilitiesRealization: number
        facilitiesDisapproval: number
        facilitiesDisappointment: number
        facilitiesAdmiration: number
        facilitiesExcitement: number
        facilitiesAnger: number
        facilitiesSadness: number
        facilitiesDisgust: number
        facilitiesJoy: number
        facilitiesAmusement: number
        facilitiesConfusion: number
        facilitiesFear: number
        facilitiesOptimism: number
        facilitiesLove: number
        facilitiesDesire: number
        facilitiesCuriosity: number
        facilitiesGratitude: number
        facilitiesSurprise: number
        facilitiesCaring: number
        facilitiesEmbarrassment: number
        facilitiesGrief: number
        facilitiesPride: number
        facilitiesRelief: number
        facilitiesNervousness: number
        facilitiesRemorse: number
        eventsNeutral: number
        eventsApproval: number
        eventsAnnoyance: number
        eventsRealization: number
        eventsDisapproval: number
        eventsDisappointment: number
        eventsAdmiration: number
        eventsExcitement: number
        eventsAnger: number
        eventsSadness: number
        eventsDisgust: number
        eventsJoy: number
        eventsAmusement: number
        eventsConfusion: number
        eventsFear: number
        eventsOptimism: number
        eventsLove: number
        eventsDesire: number
        eventsCuriosity: number
        eventsGratitude: number
        eventsSurprise: number
        eventsCaring: number
        eventsEmbarrassment: number
        eventsGrief: number
        eventsPride: number
        eventsRelief: number
        eventsNervousness: number
        eventsRemorse: number
    }>
}

export type HeatmapItemFragment = {
    __typename?: 'HeatmapView'
    foodNeutral: number
    foodApproval: number
    foodAnnoyance: number
    foodRealization: number
    foodDisapproval: number
    foodDisappointment: number
    foodAdmiration: number
    foodExcitement: number
    foodAnger: number
    foodSadness: number
    foodDisgust: number
    foodJoy: number
    foodAmusement: number
    foodConfusion: number
    foodFear: number
    foodOptimism: number
    foodLove: number
    foodDesire: number
    foodCuriosity: number
    foodGratitude: number
    foodSurprise: number
    foodCaring: number
    foodEmbarrassment: number
    foodGrief: number
    foodPride: number
    foodRelief: number
    foodNervousness: number
    foodRemorse: number
    serviceNeutral: number
    serviceApproval: number
    serviceAnnoyance: number
    serviceRealization: number
    serviceDisapproval: number
    serviceDisappointment: number
    serviceAdmiration: number
    serviceExcitement: number
    serviceAnger: number
    serviceSadness: number
    serviceDisgust: number
    serviceJoy: number
    serviceAmusement: number
    serviceConfusion: number
    serviceFear: number
    serviceOptimism: number
    serviceLove: number
    serviceDesire: number
    serviceCuriosity: number
    serviceGratitude: number
    serviceSurprise: number
    serviceCaring: number
    serviceEmbarrassment: number
    serviceGrief: number
    servicePride: number
    serviceRelief: number
    serviceNervousness: number
    serviceRemorse: number
    venueNeutral: number
    venueApproval: number
    venueAnnoyance: number
    venueRealization: number
    venueDisapproval: number
    venueDisappointment: number
    venueAdmiration: number
    venueExcitement: number
    venueAnger: number
    venueSadness: number
    venueDisgust: number
    venueJoy: number
    venueAmusement: number
    venueConfusion: number
    venueFear: number
    venueOptimism: number
    venueLove: number
    venueDesire: number
    venueCuriosity: number
    venueGratitude: number
    venueSurprise: number
    venueCaring: number
    venueEmbarrassment: number
    venueGrief: number
    venuePride: number
    venueRelief: number
    venueNervousness: number
    venueRemorse: number
    atmosphereNeutral: number
    atmosphereApproval: number
    atmosphereAnnoyance: number
    atmosphereRealization: number
    atmosphereDisapproval: number
    atmosphereDisappointment: number
    atmosphereAdmiration: number
    atmosphereExcitement: number
    atmosphereAnger: number
    atmosphereSadness: number
    atmosphereDisgust: number
    atmosphereJoy: number
    atmosphereAmusement: number
    atmosphereConfusion: number
    atmosphereFear: number
    atmosphereOptimism: number
    atmosphereLove: number
    atmosphereDesire: number
    atmosphereCuriosity: number
    atmosphereGratitude: number
    atmosphereSurprise: number
    atmosphereCaring: number
    atmosphereEmbarrassment: number
    atmosphereGrief: number
    atmospherePride: number
    atmosphereRelief: number
    atmosphereNervousness: number
    atmosphereRemorse: number
    facilitiesNeutral: number
    facilitiesApproval: number
    facilitiesAnnoyance: number
    facilitiesRealization: number
    facilitiesDisapproval: number
    facilitiesDisappointment: number
    facilitiesAdmiration: number
    facilitiesExcitement: number
    facilitiesAnger: number
    facilitiesSadness: number
    facilitiesDisgust: number
    facilitiesJoy: number
    facilitiesAmusement: number
    facilitiesConfusion: number
    facilitiesFear: number
    facilitiesOptimism: number
    facilitiesLove: number
    facilitiesDesire: number
    facilitiesCuriosity: number
    facilitiesGratitude: number
    facilitiesSurprise: number
    facilitiesCaring: number
    facilitiesEmbarrassment: number
    facilitiesGrief: number
    facilitiesPride: number
    facilitiesRelief: number
    facilitiesNervousness: number
    facilitiesRemorse: number
    eventsNeutral: number
    eventsApproval: number
    eventsAnnoyance: number
    eventsRealization: number
    eventsDisapproval: number
    eventsDisappointment: number
    eventsAdmiration: number
    eventsExcitement: number
    eventsAnger: number
    eventsSadness: number
    eventsDisgust: number
    eventsJoy: number
    eventsAmusement: number
    eventsConfusion: number
    eventsFear: number
    eventsOptimism: number
    eventsLove: number
    eventsDesire: number
    eventsCuriosity: number
    eventsGratitude: number
    eventsSurprise: number
    eventsCaring: number
    eventsEmbarrassment: number
    eventsGrief: number
    eventsPride: number
    eventsRelief: number
    eventsNervousness: number
    eventsRemorse: number
}

export type GetUserAuthEventsQueryVariables = Exact<{
    username: Scalars['String']['input']
}>

export type GetUserAuthEventsQuery = {
    __typename?: 'Query'
    getUserAuthEvents: Array<{
        __typename?: 'UserAuthEventView'
        username: string
        eventType: string
        eventTimestamp?: any | null
        eventResponse: string
        ipAddress: string
        deviceName: string
        city: string
        country: string
    }>
}

export type UserAuthEventItemFragment = {
    __typename?: 'UserAuthEventView'
    username: string
    eventType: string
    eventTimestamp?: any | null
    eventResponse: string
    ipAddress: string
    deviceName: string
    city: string
    country: string
}

export type GetLineGraphQueryVariables = Exact<{
    params: SearchParamsForContentOutcome
}>

export type GetLineGraphQuery = {
    __typename?: 'Query'
    getLineGraph: Array<{
        __typename?: 'DateMapView'
        date: any
        contentCount: number
    }>
}

export type DateMapItemFragment = {
    __typename?: 'DateMapView'
    date: any
    contentCount: number
}

export type DetectedLanguageItemFragment = {
    __typename?: 'DetectedLanguageView'
    sourceText: string
    detectedSourceLanguage?: string | null
}

export type DetectLanguagesQueryVariables = Exact<{
    sourceTexts: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DetectLanguagesQuery = {
    __typename?: 'Query'
    detectLanguages: Array<{
        __typename?: 'DetectedLanguageView'
        sourceText: string
        detectedSourceLanguage?: string | null
    }>
}

export type TranslatedTextItemFragment = {
    __typename?: 'TranslatedTextView'
    sourceText: string
    translatedText: string
    detectedSourceLanguage: string
}

export type TranslateQueryVariables = Exact<{
    sourceTexts: Array<Scalars['String']['input']> | Scalars['String']['input']
    targetLanguage: Scalars['String']['input']
}>

export type TranslateQuery = {
    __typename?: 'Query'
    translate: Array<{
        __typename?: 'TranslatedTextView'
        sourceText: string
        translatedText: string
        detectedSourceLanguage: string
    }>
}

export type CreateAccountMutationVariables = Exact<{
    serviceAccountId: Scalars['String']['input']
    accountName: Scalars['String']['input']
    serviceAccountUsername: Scalars['String']['input']
    serviceName: Scalars['String']['input']
}>

export type CreateAccountMutation = {
    __typename?: 'Mutation'
    createAccount?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type DeleteAccountMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    authCode?: InputMaybe<Scalars['String']['input']>
}>

export type DeleteAccountMutation = {
    __typename?: 'Mutation'
    deleteAccount?: boolean | null
}

export type DeleteAuthorisationMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type DeleteAuthorisationMutation = {
    __typename?: 'Mutation'
    deleteAuthorisation?: boolean | null
}

export type SetAutoMuteSevereAccountMutationVariables = Exact<{
    id: Scalars['Int']['input']
    autoMuteSevereAccount?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SetAutoMuteSevereAccountMutation = {
    __typename?: 'Mutation'
    updateAccount?: boolean | null
}

export type SetAutoHideSevereContentMutationVariables = Exact<{
    id: Scalars['Int']['input']
    autoHideSevereContent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SetAutoHideSevereContentMutation = {
    __typename?: 'Mutation'
    updateAccount?: boolean | null
}

export type SetAutoDeleteSevereContentMutationVariables = Exact<{
    id: Scalars['Int']['input']
    autoDeleteSevereContent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SetAutoDeleteSevereContentMutation = {
    __typename?: 'Mutation'
    updateAccount?: boolean | null
}

export type UpdateAccountMutationVariables = Exact<{
    id: Scalars['Int']['input']
    accountName?: InputMaybe<Scalars['String']['input']>
    autoMuteSevereAccount?: InputMaybe<Scalars['Boolean']['input']>
    autoHideSevereContent?: InputMaybe<Scalars['Boolean']['input']>
    autoDeleteSevereContent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateAccountMutation = {
    __typename?: 'Mutation'
    updateAccount?: boolean | null
}

export type SaveAuthorisationCodeMutationVariables = Exact<{
    accountId?: InputMaybe<Scalars['Int']['input']>
    socialMediaServiceName?: InputMaybe<Scalars['String']['input']>
    baseUrl?: InputMaybe<Scalars['String']['input']>
    authCode: Scalars['String']['input']
    authVerifier?: InputMaybe<Scalars['String']['input']>
}>

export type SaveAuthorisationCodeMutation = {
    __typename?: 'Mutation'
    saveAuthorisationCode?: {
        __typename?: 'SaveAuthorisationCodeResponse'
        new: Array<number | null>
        updated: Array<number | null>
    } | null
}

export type SaveAuthorisationCodeResponseItemFragment = {
    __typename?: 'SaveAuthorisationCodeResponse'
    new: Array<number | null>
    updated: Array<number | null>
}

export type TakeContentActionMutationVariables = Exact<{
    contentId: Scalars['Int']['input']
    action: ContentAction
    toggle: Scalars['Boolean']['input']
    baseUrl?: InputMaybe<Scalars['String']['input']>
}>

export type TakeContentActionMutation = {
    __typename?: 'Mutation'
    takeContentAction?: {
        __typename?: 'TakeContentActionResponse'
        queued: boolean
        contentAction?: {
            __typename?: 'ContentActionBusiness'
            id?: number | null
            action: string
            actionOn: any
            automated: boolean
            active: boolean
            contentId: number
            createdAt: any
            updatedAt?: any | null
            userId?: number | null
            queued: boolean
            retryAt?: any | null
            message?: string | null
        } | null
    } | null
}

export type ContentActionBusinessItemFragment = {
    __typename?: 'ContentActionBusiness'
    id?: number | null
    action: string
    actionOn: any
    automated: boolean
    active: boolean
    contentId: number
    createdAt: any
    updatedAt?: any | null
    userId?: number | null
    queued: boolean
    retryAt?: any | null
    message?: string | null
}

export type TakeContentActionResponseItemFragment = {
    __typename?: 'TakeContentActionResponse'
    queued: boolean
    contentAction?: {
        __typename?: 'ContentActionBusiness'
        id?: number | null
        action: string
        actionOn: any
        automated: boolean
        active: boolean
        contentId: number
        createdAt: any
        updatedAt?: any | null
        userId?: number | null
        queued: boolean
        retryAt?: any | null
        message?: string | null
    } | null
}

export type UpdateAutoFetchContentMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    autoFetchContent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateAutoFetchContentMutation = {
    __typename?: 'Mutation'
    updateAutoFetchContent?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateProfileCollectionEnabledMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    profileCollectionEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateProfileCollectionEnabledMutation = {
    __typename?: 'Mutation'
    updateProfileCollectionEnabled?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateProfileModerationEnabledMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    profileModerationEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateProfileModerationEnabledMutation = {
    __typename?: 'Mutation'
    updateProfileModerationEnabled?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateLlmClassificationEnabledMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    llmClassificationEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateLlmClassificationEnabledMutation = {
    __typename?: 'Mutation'
    updateLlmClassificationEnabled?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateLlmSuggestReplyEnabledMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    llmSuggestedReplyEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateLlmSuggestReplyEnabledMutation = {
    __typename?: 'Mutation'
    updateLlmSuggestedReplyEnabled?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateIntelligenceEnabledMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    intelligenceEnabled?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateIntelligenceEnabledMutation = {
    __typename?: 'Mutation'
    updateIntelligenceEnabled?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateFetchAdsMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    fetchAds?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateFetchAdsMutation = {
    __typename?: 'Mutation'
    updateFetchAds?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateFetchPostsMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    fetchPosts?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateFetchPostsMutation = {
    __typename?: 'Mutation'
    updateFetchPosts?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type UpdateAutoHideAdCommentsMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    autoHideAdComments?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateAutoHideAdCommentsMutation = {
    __typename?: 'Mutation'
    updateAutoHideAdComments?: {
        __typename?: 'Account'
        id: number
        serviceAccountId: string
        serviceAccountUsername?: string | null
        accountName?: string | null
        authorised: boolean
        isAuthorisationFailing?: boolean | null
        createdOn?: any | null
        ownedBy?: string | null
        autoHideSevereContent: boolean
        autoMuteSevereAccount: boolean
        autoDeleteSevereContent: boolean
        isSubscribed: boolean
        autoFetchContent: boolean
        scrapeContent: boolean
        profileCollectionEnabled: boolean
        profileModerationEnabled: boolean
        llmClassificationEnabled: boolean
        intelligenceEnabled: boolean
        fetchAds: boolean
        fetchPosts: boolean
        adsLastFetched?: any | null
        postsLastFetched?: any | null
        llmEnableSuggestedReplies: boolean
        socialMediaService: {
            __typename?: 'SocialMediaService'
            id: number
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        }
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
    } | null
}

export type ClassifyContentMutationVariables = Exact<{
    reason?: InputMaybe<Scalars['String']['input']>
}>

export type ClassifyContentMutation = {
    __typename?: 'Mutation'
    classifyContent: number
}

export type ModerateContentMutationVariables = Exact<{
    reason?: InputMaybe<Scalars['String']['input']>
}>

export type ModerateContentMutation = {
    __typename?: 'Mutation'
    moderateContent: number
}

export type FetchHistoricContentMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    tweetsRequested: Scalars['Int']['input']
}>

export type FetchHistoricContentMutation = {
    __typename?: 'Mutation'
    fetchHistoricContent?: boolean | null
}

export type FetchHistoricYouTubeVideosMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type FetchHistoricYouTubeVideosMutation = {
    __typename?: 'Mutation'
    fetchHistoricYouTubeVideos?: boolean | null
}

export type AddTwitterSubscriptionMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type AddTwitterSubscriptionMutation = {
    __typename?: 'Mutation'
    addTwitterSubscription?: boolean | null
}

export type RemoveTwitterSubscriptionMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type RemoveTwitterSubscriptionMutation = {
    __typename?: 'Mutation'
    removeTwitterSubscription?: boolean | null
}

export type UpdateScrapeContentMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    scrapeContent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateScrapeContentMutation = {
    __typename?: 'Mutation'
    updateScrapeContent?: boolean | null
}

export type CreatePaymentPortalMutationVariables = Exact<{
    successUrl: Scalars['String']['input']
    cancelUrl: Scalars['String']['input']
}>

export type CreatePaymentPortalMutation = {
    __typename?: 'Mutation'
    createPaymentPortal: string
}

export type AddYouTubeSubscriptionMutationVariables = Exact<{
    channelId?: InputMaybe<Scalars['String']['input']>
}>

export type AddYouTubeSubscriptionMutation = {
    __typename?: 'Mutation'
    addYouTubeSubscription: {
        __typename?: 'AddYouTubeSubcription'
        success?: boolean | null
    }
}

export type AddDefaultKeywordsMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
    reset: Scalars['Boolean']['input']
}>

export type AddDefaultKeywordsMutation = {
    __typename?: 'Mutation'
    addDefaultKeywords?: boolean | null
}

export type RefreshTokenMutationVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type RefreshTokenMutation = {
    __typename?: 'Mutation'
    refreshToken: boolean
}

export type RemoveItemFromSpamModelMutationVariables = Exact<{
    contentId: Scalars['Int']['input']
}>

export type RemoveItemFromSpamModelMutation = {
    __typename?: 'Mutation'
    removeItemFromSpamModel?: boolean | null
}

export type AccountItemFragment = {
    __typename?: 'Account'
    id: number
    serviceAccountId: string
    serviceAccountUsername?: string | null
    accountName?: string | null
    authorised: boolean
    isAuthorisationFailing?: boolean | null
    createdOn?: any | null
    ownedBy?: string | null
    autoHideSevereContent: boolean
    autoMuteSevereAccount: boolean
    autoDeleteSevereContent: boolean
    isSubscribed: boolean
    autoFetchContent: boolean
    scrapeContent: boolean
    profileCollectionEnabled: boolean
    profileModerationEnabled: boolean
    llmClassificationEnabled: boolean
    intelligenceEnabled: boolean
    fetchAds: boolean
    fetchPosts: boolean
    adsLastFetched?: any | null
    postsLastFetched?: any | null
    llmEnableSuggestedReplies: boolean
    socialMediaService: {
        __typename?: 'SocialMediaService'
        id: number
        shortName: string
        displayName: string
        shortDescription: string
        longDescription?: string | null
    }
    team?: {
        __typename?: 'Team'
        id: number
        name: string
        createdAt: any
    } | null
}

export type TestLlmClassificationPromptQueryVariables = Exact<{
    prompt: Scalars['String']['input']
    testMessage: Scalars['String']['input']
}>

export type TestLlmClassificationPromptQuery = {
    __typename?: 'Query'
    testLlmClassificationPrompt: number
}

export type GetCustomerPaymentPortalUrlQueryVariables = Exact<{
    returnUrl: Scalars['String']['input']
}>

export type GetCustomerPaymentPortalUrlQuery = {
    __typename?: 'Query'
    getCustomerPaymentPortalUrl: {
        __typename?: 'CustomerPaymentPortal'
        url?: string | null
    }
}

export type GetYouTubeSubscriptionDiagnosticUrlQueryVariables = Exact<{
    channelId?: InputMaybe<Scalars['String']['input']>
}>

export type GetYouTubeSubscriptionDiagnosticUrlQuery = {
    __typename?: 'Query'
    getYouTubeSubscriptionDiagnosticUrl?: {
        __typename?: 'YouTubeSubscriptionDiagrnostics'
        url?: string | null
    } | null
}

export type GetClassifierDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetClassifierDetailsQuery = {
    __typename?: 'Query'
    getClassifierDetails: Array<{
        __typename?: 'ClassifierDetails'
        id?: number | null
        shortName: string
        displayName: string
        shortDescription: string
        longDescription?: string | null
    }>
}

export type GetSocialMediaServicesQueryVariables = Exact<{
    [key: string]: never
}>

export type GetSocialMediaServicesQuery = {
    __typename?: 'Query'
    getSocialMediaServices: Array<{
        __typename?: 'SocialMediaService'
        id: number
        displayName: string
        shortName: string
        shortDescription: string
        longDescription?: string | null
    }>
}

export type CountContentQueryVariables = Exact<{
    startDateTime: Scalars['AWSDateTime']['input']
    endDateTime: Scalars['AWSDateTime']['input']
    filter?: InputMaybe<ContentFilterInput>
    keyword?: InputMaybe<Scalars['String']['input']>
}>

export type CountContentQuery = {
    __typename?: 'Query'
    countContent?: {
        __typename?: 'CountContentConnection'
        total?: number | null
    } | null
}

export type UserFragment = {
    __typename?: 'User'
    id: number
    username: string
    stripeCustomerId?: string | null
    stripeSubscriptionId?: string | null
    stripeSubscriptionStatus?: string | null
    shouldProvisionAccess: boolean
    dashboardEnabled?: boolean | null
    dashboardId?: string | null
    canLikeContent: boolean
    canReplyToContent: boolean
    intelligencePageViewer: boolean
    canUseReadStatus: boolean
    feedsEnabled: boolean
    team?: {
        __typename?: 'Team'
        id: number
        name: string
        createdAt: any
    } | null
    referrer?: {
        __typename?: 'Referrer'
        id: number
        referrer: string
        referrerUuid: string
        signUpEnabled: boolean
        userLimit?: number | null
        allowTrial: boolean
        trialDays?: number | null
        trialEndDate?: any | null
        creditCardRequired: boolean
        priceId?: string | null
        isCobranded: boolean
        logoUrl?: string | null
    } | null
}

export type ReferrerFragment = {
    __typename?: 'Referrer'
    id: number
    referrer: string
    referrerUuid: string
    signUpEnabled: boolean
    userLimit?: number | null
    allowTrial: boolean
    trialDays?: number | null
    trialEndDate?: any | null
    creditCardRequired: boolean
    priceId?: string | null
    isCobranded: boolean
    logoUrl?: string | null
}

export type ModerationItemFragment = {
    __typename?: 'Moderation'
    id: number
    moderation?: string | null
    moderatedOn: any
    dismissed?: boolean | null
}

export type ClassificationResultItemFragment = {
    __typename?: 'ClassificationResult'
    id: number
    contentId: number
    classification: number
    severity?: Severity | null
    classifier?: {
        __typename?: 'ClassifierDetails'
        id?: number | null
        shortName: string
        displayName: string
        shortDescription: string
        longDescription?: string | null
    } | null
}

export type FetchUserDetailsQueryVariables = Exact<{
    socialMediaService: SocialMediaServiceEnum
    serviceAccountUsername: Scalars['String']['input']
}>

export type FetchUserDetailsQuery = {
    __typename?: 'Query'
    fetchUserDetails?: {
        __typename?: 'FetchUserDetailsConnection'
        error?: {
            __typename?: 'FetchUserDetailsErrorDetails'
            message?: string | null
            type?: FetchUserDetailsError | null
        } | null
        userDetails?: {
            __typename?: 'FetchedUserDetails'
            username?: string | null
            serviceAccountId?: string | null
            name?: string | null
        } | null
    } | null
}

export type GetClassificationResultsQueryVariables = Exact<{
    contentId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetClassificationResultsQuery = {
    __typename?: 'Query'
    getClassificationResults: Array<{
        __typename?: 'ClassificationResult'
        id: number
        contentId: number
        classification: number
        severity?: Severity | null
        classifier?: {
            __typename?: 'ClassifierDetails'
            id?: number | null
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        } | null
    }>
}

export type GetManyClassificationResultsQueryVariables = Exact<{
    contentIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type GetManyClassificationResultsQuery = {
    __typename?: 'Query'
    getManyClassificationResults: Array<{
        __typename?: 'ClassificationResult'
        id: number
        contentId: number
        classification: number
        severity?: Severity | null
        classifier?: {
            __typename?: 'ClassifierDetails'
            id?: number | null
            shortName: string
            displayName: string
            shortDescription: string
            longDescription?: string | null
        } | null
    }>
}

export type GetAuthorisationUrlQueryVariables = Exact<{
    socialMediaService: SocialMediaServiceEnum
    accountId: Scalars['Int']['input']
    baseUrl: Scalars['String']['input']
}>

export type GetAuthorisationUrlQuery = {
    __typename?: 'Query'
    getAuthorisationUrl: string
}

export type IsSubscribedQueryVariables = Exact<{
    accountId: Scalars['Int']['input']
}>

export type IsSubscribedQuery = { __typename?: 'Query'; isSubscribed: boolean }

export type GetUserSubscriptionQueryVariables = Exact<{
    referrer?: InputMaybe<Scalars['String']['input']>
}>

export type GetUserSubscriptionQuery = {
    __typename?: 'Query'
    getUserSubscription: {
        __typename?: 'User'
        id: number
        username: string
        stripeCustomerId?: string | null
        stripeSubscriptionId?: string | null
        stripeSubscriptionStatus?: string | null
        shouldProvisionAccess: boolean
        dashboardEnabled?: boolean | null
        dashboardId?: string | null
        canLikeContent: boolean
        canReplyToContent: boolean
        intelligencePageViewer: boolean
        canUseReadStatus: boolean
        feedsEnabled: boolean
        team?: {
            __typename?: 'Team'
            id: number
            name: string
            createdAt: any
        } | null
        referrer?: {
            __typename?: 'Referrer'
            id: number
            referrer: string
            referrerUuid: string
            signUpEnabled: boolean
            userLimit?: number | null
            allowTrial: boolean
            trialDays?: number | null
            trialEndDate?: any | null
            creditCardRequired: boolean
            priceId?: string | null
            isCobranded: boolean
            logoUrl?: string | null
        } | null
    }
}

export type GetTeamQueryVariables = Exact<{
    teamId: Scalars['Int']['input']
}>

export type GetTeamQuery = {
    __typename?: 'Query'
    getTeam?: {
        __typename?: 'Team'
        id: number
        name: string
        createdAt: any
        updatedAt?: any | null
        users?: Array<{
            __typename?: 'User'
            id: number
            username: string
        }> | null
        accounts?: Array<{
            __typename?: 'Account'
            accountName?: string | null
            socialMediaService: {
                __typename?: 'SocialMediaService'
                displayName: string
            }
        }> | null
    } | null
}

export type SpamSearchFragment = {
    __typename?: 'SpamSearchResult'
    contentId?: number | null
    similarityScore?: number | null
}

export type GetLabelledSpamQueryVariables = Exact<{
    message?: InputMaybe<Scalars['String']['input']>
    platform?: InputMaybe<Scalars['String']['input']>
    threshold?: InputMaybe<Scalars['Float']['input']>
}>

export type GetLabelledSpamQuery = {
    __typename?: 'Query'
    getLabelledSpam?: Array<{
        __typename?: 'SpamSearchResult'
        contentId?: number | null
        similarityScore?: number | null
    } | null> | null
}

export type EnrichedContentItemFragment = {
    __typename?: 'EnrichedContent'
    id: number
    serviceAuthorName?: string | null
    serviceAuthorUsername?: string | null
    originalPostId?: string | null
    originalPostLinkId?: string | null
}

export type EnrichContentQueryVariables = Exact<{
    contents: Array<ContentToEnrich> | ContentToEnrich
}>

export type EnrichContentQuery = {
    __typename?: 'Query'
    enrichContent: Array<{
        __typename?: 'EnrichedContent'
        id: number
        serviceAuthorName?: string | null
        serviceAuthorUsername?: string | null
        originalPostId?: string | null
        originalPostLinkId?: string | null
    }>
}

export type GetSuggestedRepliesQueryVariables = Exact<{
    contentId: Scalars['Int']['input']
    numberOfSuggestions: Scalars['Int']['input']
}>

export type GetSuggestedRepliesQuery = {
    __typename?: 'Query'
    getSuggestedReplies: Array<string>
}

export type TestLlmReplyPromptQueryVariables = Exact<{
    prompt: Scalars['String']['input']
    testMessage: Scalars['String']['input']
    numberOfSuggestions?: InputMaybe<Scalars['Int']['input']>
}>

export type TestLlmReplyPromptQuery = {
    __typename?: 'Query'
    testLlmReplyPrompt: Array<string>
}

export const ContentActionViewFragmentDoc = gql`
    fragment ContentActionView on ContentActionView {
        id
        action
        actionOn
        automated
        active
        contentId
        userId
        queued
        retryAt
        message
    }
`
export const ContentActionResultFragmentDoc = gql`
    fragment ContentActionResult on ContentActionResult {
        success
        contentId
        contentAction {
            ...ContentActionView
        }
        message
    }
    ${ContentActionViewFragmentDoc}
`
export const TikTokSessionKeyItemFragmentDoc = gql`
    fragment TikTokSessionKeyItem on TikTokSessionKey {
        key
        expiry
    }
`
export const TikTokAuthorisedAccountItemFragmentDoc = gql`
    fragment TikTokAuthorisedAccountItem on AuthorisedAccountView {
        id
        serviceAccountUsername
        serviceAccountId
        accountName
        pageId
        isFailing
        failureDate
        lastFailureNotificationDate
        socialMediaServiceName
    }
`
export const TikTokUserAccountSelectionItemFragmentDoc = gql`
    fragment TikTokUserAccountSelectionItem on TikTokUserAccountSelectionView {
        key {
            ...TikTokSessionKeyItem
        }
        accountData {
            ...TikTokAuthorisedAccountItem
        }
    }
    ${TikTokSessionKeyItemFragmentDoc}
    ${TikTokAuthorisedAccountItemFragmentDoc}
`
export const TikTokAdAccountItemFragmentDoc = gql`
    fragment TikTokAdAccountItem on TikTokAdAccountView {
        advertiserId
        advertiserName
    }
`
export const TikTokAccountSelectionItemFragmentDoc = gql`
    fragment TikTokAccountSelectionItem on TikTokAccountSelectionView {
        key {
            ...TikTokSessionKeyItem
        }
        adAccounts {
            ...TikTokAdAccountItem
        }
    }
    ${TikTokSessionKeyItemFragmentDoc}
    ${TikTokAdAccountItemFragmentDoc}
`
export const TikTokAccountsSelectionItemFragmentDoc = gql`
    fragment TikTokAccountsSelectionItem on TikTokAccountSelectionView {
        key {
            ...TikTokSessionKeyItem
        }
    }
    ${TikTokSessionKeyItemFragmentDoc}
`
export const ClassifierSearchParamsViewItemFragmentDoc = gql`
    fragment ClassifierSearchParamsViewItem on ClassifierSearchParamsView {
        classifierName
        threshold
        category
    }
`
export const ContentSearchParamsViewItemFragmentDoc = gql`
    fragment ContentSearchParamsViewItem on ContentSearchParamsView {
        accountIds
        teamIds
        socialMediaServiceIds
        since
        before
        timeDuration
        timeOffset
        containsText
        moderationCode
        moderationString
        skipResults
        maxResults
        adsOrOrganic
        contentIds
        owned
        readFilter
        classifierSearchParams {
            ...ClassifierSearchParamsViewItem
        }
    }
    ${ClassifierSearchParamsViewItemFragmentDoc}
`
export const ContentFeedViewItemFragmentDoc = gql`
    fragment ContentFeedViewItem on ContentFeedView {
        id
        userId
        displayName
        description
        searchParameters {
            ...ContentSearchParamsViewItem
        }
        createdAt
        updatedAt
    }
    ${ContentSearchParamsViewItemFragmentDoc}
`
export const NotificationConfigurationViewItemFragmentDoc = gql`
    fragment NotificationConfigurationViewItem on NotificationConfigurationView {
        id
        accountId
        recipientListId
        notificationTemplateId
    }
`
export const DashboardViewItemFragmentDoc = gql`
    fragment DashboardViewItem on DashboardView {
        id
        displayName
        description
        extEmbeddedId
        createdAt
        updatedAt
    }
`
export const UserItemFragmentDoc = gql`
    fragment UserItem on UserView {
        id
        username
        createdOn
        updatedAt
        stripeCustomerId
        stripeSubscriptionId
        stripeSubscriptionStatus
        dashboardId
        dashboardEnabled
        referrerId
        allowAccessOverride
        admin
        canLikeContent
        canReplyToContent
        intelligencePageViewer
        canUseReadStatus
        feedsEnabled
    }
`
export const EditableDashboardViewItemFragmentDoc = gql`
    fragment EditableDashboardViewItem on EditableDashboardView {
        dashboard {
            ...DashboardViewItem
        }
        users {
            ...UserItem
        }
    }
    ${DashboardViewItemFragmentDoc}
    ${UserItemFragmentDoc}
`
export const EmbeddedDashboardViewItemFragmentDoc = gql`
    fragment EmbeddedDashboardViewItem on EmbeddedDashboardView {
        dashboard {
            ...DashboardViewItem
        }
        guestToken
        workspaceUrl
    }
    ${DashboardViewItemFragmentDoc}
`
export const ModerationBoundaryViewItemFragmentDoc = gql`
    fragment ModerationBoundaryViewItem on ModerationBoundaryView {
        id
        account
        classifier {
            id
            shortName
            displayName
            shortDescription
            longDescription
        }
        queue
        type
        severityCategory
        probability
    }
`
export const PromptViewItemFragmentDoc = gql`
    fragment PromptViewItem on PromptView {
        id
        prompt
        category
        createdAt
    }
`
export const DebugTokenResponseViewItemFragmentDoc = gql`
    fragment DebugTokenResponseViewItem on DebugTokenResponseView {
        appId
        dataType
        application
        dataAccessExpiresAt
        expiresAt
        isValid
        issuedAt
        scopes
        userId
    }
`
export const TeamItemFragmentDoc = gql`
    fragment TeamItem on TeamView {
        id
        name
        createdAt
    }
`
export const ProfileItemFragmentDoc = gql`
    fragment ProfileItem on ProfileView {
        id
        username
        serviceProfileId
        followers
        following
        posts
        profileImageUrl
        profileUrl
        profileImagePath
    }
`
export const ContentSummaryItemFragmentDoc = gql`
    fragment ContentSummaryItem on ContentSummary {
        countSafe
        countSuspect
        countSevere
    }
`
export const AccountRecordItemFragmentDoc = gql`
    fragment AccountRecordItem on AccountRecord {
        id
        accountName
        autoDeleteSevereContent
        autoHideSevereContent
        autoMuteSevereAccount
        autoHideAllAdComments
        classifyContent
        createdOn
        fetchContent
        isSubscribed
        lastFetched
        latestFetchedServiceContentId
        pageId
        scrapeContent
        serviceAccountId
        serviceAccountUsername
        socialMediaServiceId
        teamId
        updatedOn
        userId
        profileCollectionEnabled
        profileModerationEnabled
        llmClassificationEnabled
        llmEnableSuggestedReplies
        intelligenceEnabled
        socialMediaServiceShortName
        socialMediaServiceDisplayName
        hasAuthorisation
        teamName
        ownedBy
        adsLastFetched
        postsLastFetched
        fetchPosts
        fetchAds
        shadowBanningEnabled
        shadowBanningThreshold
        shadowBanningRangeInDays
    }
`
export const ContentActionItemFragmentDoc = gql`
    fragment ContentActionItem on ContentActionView {
        id
        action
        actionOn
        active
        automated
        contentId
        queued
        retryAt
        userId
        message
    }
`
export const ContentClassificationItemFragmentDoc = gql`
    fragment ContentClassificationItem on ContentClassificationView {
        classification
        severity
        classifierName
    }
`
export const ContentMediaItemFragmentDoc = gql`
    fragment ContentMediaItem on ContentMediaView {
        serviceMediaId
        publicUrl
        mediaType
        mediaClassifications {
            ...ContentClassificationItem
        }
    }
    ${ContentClassificationItemFragmentDoc}
`
export const ReadReceiptItemFragmentDoc = gql`
    fragment ReadReceiptItem on ReadReceiptView {
        userId
        contentOutcomeId
        createdAt
    }
`
export const ContentOutcomeViewItemFragmentDoc = gql`
    fragment ContentOutcomeViewItem on ContentOutcomeView {
        id
        serviceContentId
        account {
            ...AccountRecordItem
        }
        content
        contentType
        socialMediaServiceName
        isPublic
        serviceAuthorId
        serviceAuthorName
        serviceAuthorDisplayUsername
        shouldEnrich
        moderation
        authorised
        socialMediaAuthorLink
        socialMediaPostLink
        socialMediaContentLink
        socialMediaContentName
        datePosted
        isReply
        inReplyToUserId
        inReplyToUsername
        inReplyToStatusId
        originalPostAuthorId
        originalPostAuthorName
        originalPostId
        originalPostLinkId
        originalPostLink
        actions {
            ...ContentActionItem
        }
        media {
            ...ContentMediaItem
        }
        readReceipts {
            ...ReadReceiptItem
        }
        allClassifications {
            classifierName
            classification
            severity
        }
        isOwned
        authorProfileId
    }
    ${AccountRecordItemFragmentDoc}
    ${ContentActionItemFragmentDoc}
    ${ContentMediaItemFragmentDoc}
    ${ReadReceiptItemFragmentDoc}
`
export const ContentClassifierMetadataItemFragmentDoc = gql`
    fragment ContentClassifierMetadataItem on ContentClassifierMetadataView {
        classifierName
        classifierDisplayName
        classifierId
        contentCount
    }
`
export const HeatmapItemFragmentDoc = gql`
    fragment HeatmapItem on HeatmapView {
        foodNeutral
        foodApproval
        foodAnnoyance
        foodRealization
        foodDisapproval
        foodDisappointment
        foodAdmiration
        foodExcitement
        foodAnger
        foodSadness
        foodDisgust
        foodJoy
        foodAmusement
        foodConfusion
        foodFear
        foodOptimism
        foodLove
        foodDesire
        foodCuriosity
        foodGratitude
        foodSurprise
        foodCaring
        foodEmbarrassment
        foodGrief
        foodPride
        foodRelief
        foodNervousness
        foodRemorse
        serviceNeutral
        serviceApproval
        serviceAnnoyance
        serviceRealization
        serviceDisapproval
        serviceDisappointment
        serviceAdmiration
        serviceExcitement
        serviceAnger
        serviceSadness
        serviceDisgust
        serviceJoy
        serviceAmusement
        serviceConfusion
        serviceFear
        serviceOptimism
        serviceLove
        serviceDesire
        serviceCuriosity
        serviceGratitude
        serviceSurprise
        serviceCaring
        serviceEmbarrassment
        serviceGrief
        servicePride
        serviceRelief
        serviceNervousness
        serviceRemorse
        venueNeutral
        venueApproval
        venueAnnoyance
        venueRealization
        venueDisapproval
        venueDisappointment
        venueAdmiration
        venueExcitement
        venueAnger
        venueSadness
        venueDisgust
        venueJoy
        venueAmusement
        venueConfusion
        venueFear
        venueOptimism
        venueLove
        venueDesire
        venueCuriosity
        venueGratitude
        venueSurprise
        venueCaring
        venueEmbarrassment
        venueGrief
        venuePride
        venueRelief
        venueNervousness
        venueRemorse
        atmosphereNeutral
        atmosphereApproval
        atmosphereAnnoyance
        atmosphereRealization
        atmosphereDisapproval
        atmosphereDisappointment
        atmosphereAdmiration
        atmosphereExcitement
        atmosphereAnger
        atmosphereSadness
        atmosphereDisgust
        atmosphereJoy
        atmosphereAmusement
        atmosphereConfusion
        atmosphereFear
        atmosphereOptimism
        atmosphereLove
        atmosphereDesire
        atmosphereCuriosity
        atmosphereGratitude
        atmosphereSurprise
        atmosphereCaring
        atmosphereEmbarrassment
        atmosphereGrief
        atmospherePride
        atmosphereRelief
        atmosphereNervousness
        atmosphereRemorse
        facilitiesNeutral
        facilitiesApproval
        facilitiesAnnoyance
        facilitiesRealization
        facilitiesDisapproval
        facilitiesDisappointment
        facilitiesAdmiration
        facilitiesExcitement
        facilitiesAnger
        facilitiesSadness
        facilitiesDisgust
        facilitiesJoy
        facilitiesAmusement
        facilitiesConfusion
        facilitiesFear
        facilitiesOptimism
        facilitiesLove
        facilitiesDesire
        facilitiesCuriosity
        facilitiesGratitude
        facilitiesSurprise
        facilitiesCaring
        facilitiesEmbarrassment
        facilitiesGrief
        facilitiesPride
        facilitiesRelief
        facilitiesNervousness
        facilitiesRemorse
        eventsNeutral
        eventsApproval
        eventsAnnoyance
        eventsRealization
        eventsDisapproval
        eventsDisappointment
        eventsAdmiration
        eventsExcitement
        eventsAnger
        eventsSadness
        eventsDisgust
        eventsJoy
        eventsAmusement
        eventsConfusion
        eventsFear
        eventsOptimism
        eventsLove
        eventsDesire
        eventsCuriosity
        eventsGratitude
        eventsSurprise
        eventsCaring
        eventsEmbarrassment
        eventsGrief
        eventsPride
        eventsRelief
        eventsNervousness
        eventsRemorse
    }
`
export const UserAuthEventItemFragmentDoc = gql`
    fragment UserAuthEventItem on UserAuthEventView {
        username
        eventType
        eventTimestamp
        eventResponse
        ipAddress
        deviceName
        city
        country
    }
`
export const DateMapItemFragmentDoc = gql`
    fragment DateMapItem on DateMapView {
        date
        contentCount
    }
`
export const DetectedLanguageItemFragmentDoc = gql`
    fragment DetectedLanguageItem on DetectedLanguageView {
        sourceText
        detectedSourceLanguage
    }
`
export const TranslatedTextItemFragmentDoc = gql`
    fragment TranslatedTextItem on TranslatedTextView {
        sourceText
        translatedText
        detectedSourceLanguage
    }
`
export const SaveAuthorisationCodeResponseItemFragmentDoc = gql`
    fragment SaveAuthorisationCodeResponseItem on SaveAuthorisationCodeResponse {
        new
        updated
    }
`
export const ContentActionBusinessItemFragmentDoc = gql`
    fragment ContentActionBusinessItem on ContentActionBusiness {
        id
        action
        actionOn
        automated
        active
        contentId
        createdAt
        updatedAt
        userId
        queued
        retryAt
        message
    }
`
export const TakeContentActionResponseItemFragmentDoc = gql`
    fragment TakeContentActionResponseItem on TakeContentActionResponse {
        contentAction {
            ...ContentActionBusinessItem
        }
        queued
    }
    ${ContentActionBusinessItemFragmentDoc}
`
export const AccountItemFragmentDoc = gql`
    fragment AccountItem on Account {
        id
        serviceAccountId
        serviceAccountUsername
        accountName
        authorised
        isAuthorisationFailing
        createdOn
        ownedBy
        socialMediaService {
            id
            shortName
            displayName
            shortDescription
            longDescription
        }
        autoHideSevereContent
        autoMuteSevereAccount
        autoDeleteSevereContent
        isSubscribed
        autoFetchContent
        scrapeContent
        profileCollectionEnabled
        profileModerationEnabled
        llmClassificationEnabled
        intelligenceEnabled
        fetchAds
        fetchPosts
        adsLastFetched
        postsLastFetched
        team {
            id
            name
            createdAt
        }
        llmEnableSuggestedReplies
    }
`
export const ReferrerFragmentDoc = gql`
    fragment Referrer on Referrer {
        id
        referrer
        referrerUuid
        signUpEnabled
        userLimit
        allowTrial
        trialDays
        trialEndDate
        creditCardRequired
        priceId
        isCobranded
        logoUrl
    }
`
export const UserFragmentDoc = gql`
    fragment User on User {
        id
        username
        stripeCustomerId
        stripeSubscriptionId
        stripeSubscriptionStatus
        shouldProvisionAccess
        team {
            id
            name
            createdAt
        }
        dashboardEnabled
        dashboardId
        referrer {
            ...Referrer
        }
        canLikeContent
        canReplyToContent
        intelligencePageViewer
        canUseReadStatus
        feedsEnabled
    }
    ${ReferrerFragmentDoc}
`
export const ModerationItemFragmentDoc = gql`
    fragment ModerationItem on Moderation {
        id
        moderation
        moderatedOn
        dismissed
    }
`
export const ClassificationResultItemFragmentDoc = gql`
    fragment ClassificationResultItem on ClassificationResult {
        id
        contentId
        classification
        classifier {
            id
            shortName
            displayName
            shortDescription
            longDescription
        }
        severity
    }
`
export const SpamSearchFragmentDoc = gql`
    fragment SpamSearch on SpamSearchResult {
        contentId
        similarityScore
    }
`
export const EnrichedContentItemFragmentDoc = gql`
    fragment EnrichedContentItem on EnrichedContent {
        id
        serviceAuthorName
        serviceAuthorUsername
        originalPostId
        originalPostLinkId
    }
`
export const CreateContentFeedDocument = gql`
    mutation createContentFeed($contentFeedNew: ContentFeedCreate!) {
        createContentFeed(contentFeed: $contentFeedNew) {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`
export type CreateContentFeedMutationFn = Apollo.MutationFunction<
    CreateContentFeedMutation,
    CreateContentFeedMutationVariables
>

/**
 * __useCreateContentFeedMutation__
 *
 * To run a mutation, you first call `useCreateContentFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentFeedMutation, { data, loading, error }] = useCreateContentFeedMutation({
 *   variables: {
 *      contentFeedNew: // value for 'contentFeedNew'
 *   },
 * });
 */
export function useCreateContentFeedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateContentFeedMutation,
        CreateContentFeedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateContentFeedMutation,
        CreateContentFeedMutationVariables
    >(CreateContentFeedDocument, options)
}
export type CreateContentFeedMutationHookResult = ReturnType<
    typeof useCreateContentFeedMutation
>
export type CreateContentFeedMutationResult =
    Apollo.MutationResult<CreateContentFeedMutation>
export type CreateContentFeedMutationOptions = Apollo.BaseMutationOptions<
    CreateContentFeedMutation,
    CreateContentFeedMutationVariables
>
export const DeleteContentFeedDocument = gql`
    mutation deleteContentFeed($contentFeedId: Int!) {
        deleteContentFeed(contentFeedId: $contentFeedId) {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`
export type DeleteContentFeedMutationFn = Apollo.MutationFunction<
    DeleteContentFeedMutation,
    DeleteContentFeedMutationVariables
>

/**
 * __useDeleteContentFeedMutation__
 *
 * To run a mutation, you first call `useDeleteContentFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentFeedMutation, { data, loading, error }] = useDeleteContentFeedMutation({
 *   variables: {
 *      contentFeedId: // value for 'contentFeedId'
 *   },
 * });
 */
export function useDeleteContentFeedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteContentFeedMutation,
        DeleteContentFeedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteContentFeedMutation,
        DeleteContentFeedMutationVariables
    >(DeleteContentFeedDocument, options)
}
export type DeleteContentFeedMutationHookResult = ReturnType<
    typeof useDeleteContentFeedMutation
>
export type DeleteContentFeedMutationResult =
    Apollo.MutationResult<DeleteContentFeedMutation>
export type DeleteContentFeedMutationOptions = Apollo.BaseMutationOptions<
    DeleteContentFeedMutation,
    DeleteContentFeedMutationVariables
>
export const UpdateContentFeedDocument = gql`
    mutation updateContentFeed($contentFeedUpdate: ContentFeedUpdate!) {
        updateContentFeed(contentFeed: $contentFeedUpdate) {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`
export type UpdateContentFeedMutationFn = Apollo.MutationFunction<
    UpdateContentFeedMutation,
    UpdateContentFeedMutationVariables
>

/**
 * __useUpdateContentFeedMutation__
 *
 * To run a mutation, you first call `useUpdateContentFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentFeedMutation, { data, loading, error }] = useUpdateContentFeedMutation({
 *   variables: {
 *      contentFeedUpdate: // value for 'contentFeedUpdate'
 *   },
 * });
 */
export function useUpdateContentFeedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateContentFeedMutation,
        UpdateContentFeedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateContentFeedMutation,
        UpdateContentFeedMutationVariables
    >(UpdateContentFeedDocument, options)
}
export type UpdateContentFeedMutationHookResult = ReturnType<
    typeof useUpdateContentFeedMutation
>
export type UpdateContentFeedMutationResult =
    Apollo.MutationResult<UpdateContentFeedMutation>
export type UpdateContentFeedMutationOptions = Apollo.BaseMutationOptions<
    UpdateContentFeedMutation,
    UpdateContentFeedMutationVariables
>
export const CreateDashboardDocument = gql`
    mutation createDashboard($dashboard: NewDashboardView!) {
        createDashboard(dashboard: $dashboard) {
            ...DashboardViewItem
        }
    }
    ${DashboardViewItemFragmentDoc}
`
export type CreateDashboardMutationFn = Apollo.MutationFunction<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
>

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      dashboard: // value for 'dashboard'
 *   },
 * });
 */
export function useCreateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >(CreateDashboardDocument, options)
}
export type CreateDashboardMutationHookResult = ReturnType<
    typeof useCreateDashboardMutation
>
export type CreateDashboardMutationResult =
    Apollo.MutationResult<CreateDashboardMutation>
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
>
export const DeleteDashboardDocument = gql`
    mutation deleteDashboard($dashboardId: Int!) {
        deleteDashboard(dashboardId: $dashboardId)
    }
`
export type DeleteDashboardMutationFn = Apollo.MutationFunction<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
>

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDeleteDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDashboardMutation,
        DeleteDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteDashboardMutation,
        DeleteDashboardMutationVariables
    >(DeleteDashboardDocument, options)
}
export type DeleteDashboardMutationHookResult = ReturnType<
    typeof useDeleteDashboardMutation
>
export type DeleteDashboardMutationResult =
    Apollo.MutationResult<DeleteDashboardMutation>
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
>
export const UpdateDashboardDocument = gql`
    mutation updateDashboard($dashboard: UpdatedDashboardView!) {
        updateDashboard(dashboard: $dashboard)
    }
`
export type UpdateDashboardMutationFn = Apollo.MutationFunction<
    UpdateDashboardMutation,
    UpdateDashboardMutationVariables
>

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      dashboard: // value for 'dashboard'
 *   },
 * });
 */
export function useUpdateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDashboardMutation,
        UpdateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateDashboardMutation,
        UpdateDashboardMutationVariables
    >(UpdateDashboardDocument, options)
}
export type UpdateDashboardMutationHookResult = ReturnType<
    typeof useUpdateDashboardMutation
>
export type UpdateDashboardMutationResult =
    Apollo.MutationResult<UpdateDashboardMutation>
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<
    UpdateDashboardMutation,
    UpdateDashboardMutationVariables
>
export const GiveUserAccessToDashboardDocument = gql`
    mutation giveUserAccessToDashboard($userId: Int!, $dashboardId: Int!) {
        giveUserAccessToDashboard(userId: $userId, dashboardId: $dashboardId)
    }
`
export type GiveUserAccessToDashboardMutationFn = Apollo.MutationFunction<
    GiveUserAccessToDashboardMutation,
    GiveUserAccessToDashboardMutationVariables
>

/**
 * __useGiveUserAccessToDashboardMutation__
 *
 * To run a mutation, you first call `useGiveUserAccessToDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveUserAccessToDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveUserAccessToDashboardMutation, { data, loading, error }] = useGiveUserAccessToDashboardMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useGiveUserAccessToDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        GiveUserAccessToDashboardMutation,
        GiveUserAccessToDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        GiveUserAccessToDashboardMutation,
        GiveUserAccessToDashboardMutationVariables
    >(GiveUserAccessToDashboardDocument, options)
}
export type GiveUserAccessToDashboardMutationHookResult = ReturnType<
    typeof useGiveUserAccessToDashboardMutation
>
export type GiveUserAccessToDashboardMutationResult =
    Apollo.MutationResult<GiveUserAccessToDashboardMutation>
export type GiveUserAccessToDashboardMutationOptions =
    Apollo.BaseMutationOptions<
        GiveUserAccessToDashboardMutation,
        GiveUserAccessToDashboardMutationVariables
    >
export const CreateReadReceiptDocument = gql`
    mutation createReadReceipt($contentId: Int!) {
        createReadReceipt(contentId: $contentId) {
            ...ReadReceiptItem
        }
    }
    ${ReadReceiptItemFragmentDoc}
`
export type CreateReadReceiptMutationFn = Apollo.MutationFunction<
    CreateReadReceiptMutation,
    CreateReadReceiptMutationVariables
>

/**
 * __useCreateReadReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReadReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReadReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReadReceiptMutation, { data, loading, error }] = useCreateReadReceiptMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useCreateReadReceiptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReadReceiptMutation,
        CreateReadReceiptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateReadReceiptMutation,
        CreateReadReceiptMutationVariables
    >(CreateReadReceiptDocument, options)
}
export type CreateReadReceiptMutationHookResult = ReturnType<
    typeof useCreateReadReceiptMutation
>
export type CreateReadReceiptMutationResult =
    Apollo.MutationResult<CreateReadReceiptMutation>
export type CreateReadReceiptMutationOptions = Apollo.BaseMutationOptions<
    CreateReadReceiptMutation,
    CreateReadReceiptMutationVariables
>
export const CreateReadReceiptsDocument = gql`
    mutation createReadReceipts($contentIds: [Int!]!) {
        createReadReceipts(contentIds: $contentIds) {
            ...ReadReceiptItem
        }
    }
    ${ReadReceiptItemFragmentDoc}
`
export type CreateReadReceiptsMutationFn = Apollo.MutationFunction<
    CreateReadReceiptsMutation,
    CreateReadReceiptsMutationVariables
>

/**
 * __useCreateReadReceiptsMutation__
 *
 * To run a mutation, you first call `useCreateReadReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReadReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReadReceiptsMutation, { data, loading, error }] = useCreateReadReceiptsMutation({
 *   variables: {
 *      contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useCreateReadReceiptsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReadReceiptsMutation,
        CreateReadReceiptsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateReadReceiptsMutation,
        CreateReadReceiptsMutationVariables
    >(CreateReadReceiptsDocument, options)
}
export type CreateReadReceiptsMutationHookResult = ReturnType<
    typeof useCreateReadReceiptsMutation
>
export type CreateReadReceiptsMutationResult =
    Apollo.MutationResult<CreateReadReceiptsMutation>
export type CreateReadReceiptsMutationOptions = Apollo.BaseMutationOptions<
    CreateReadReceiptsMutation,
    CreateReadReceiptsMutationVariables
>
export const DeleteReadReceiptDocument = gql`
    mutation deleteReadReceipt($contentId: Int!) {
        deleteReadReceipt(contentId: $contentId) {
            ...ReadReceiptItem
        }
    }
    ${ReadReceiptItemFragmentDoc}
`
export type DeleteReadReceiptMutationFn = Apollo.MutationFunction<
    DeleteReadReceiptMutation,
    DeleteReadReceiptMutationVariables
>

/**
 * __useDeleteReadReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteReadReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReadReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReadReceiptMutation, { data, loading, error }] = useDeleteReadReceiptMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteReadReceiptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReadReceiptMutation,
        DeleteReadReceiptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteReadReceiptMutation,
        DeleteReadReceiptMutationVariables
    >(DeleteReadReceiptDocument, options)
}
export type DeleteReadReceiptMutationHookResult = ReturnType<
    typeof useDeleteReadReceiptMutation
>
export type DeleteReadReceiptMutationResult =
    Apollo.MutationResult<DeleteReadReceiptMutation>
export type DeleteReadReceiptMutationOptions = Apollo.BaseMutationOptions<
    DeleteReadReceiptMutation,
    DeleteReadReceiptMutationVariables
>
export const DeleteReadReceiptsDocument = gql`
    mutation deleteReadReceipts($contentIds: [Int!]!) {
        deleteReadReceipts(contentIds: $contentIds) {
            ...ReadReceiptItem
        }
    }
    ${ReadReceiptItemFragmentDoc}
`
export type DeleteReadReceiptsMutationFn = Apollo.MutationFunction<
    DeleteReadReceiptsMutation,
    DeleteReadReceiptsMutationVariables
>

/**
 * __useDeleteReadReceiptsMutation__
 *
 * To run a mutation, you first call `useDeleteReadReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReadReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReadReceiptsMutation, { data, loading, error }] = useDeleteReadReceiptsMutation({
 *   variables: {
 *      contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useDeleteReadReceiptsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReadReceiptsMutation,
        DeleteReadReceiptsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteReadReceiptsMutation,
        DeleteReadReceiptsMutationVariables
    >(DeleteReadReceiptsDocument, options)
}
export type DeleteReadReceiptsMutationHookResult = ReturnType<
    typeof useDeleteReadReceiptsMutation
>
export type DeleteReadReceiptsMutationResult =
    Apollo.MutationResult<DeleteReadReceiptsMutation>
export type DeleteReadReceiptsMutationOptions = Apollo.BaseMutationOptions<
    DeleteReadReceiptsMutation,
    DeleteReadReceiptsMutationVariables
>
export const CreateNotificationConfigurationDocument = gql`
    mutation createNotificationConfiguration(
        $accountId: Int!
        $recipientListId: String!
        $notificationTemplateId: String!
    ) {
        createNotificationConfiguration(
            accountId: $accountId
            recipientListId: $recipientListId
            notificationTemplateId: $notificationTemplateId
        ) {
            ...NotificationConfigurationViewItem
        }
    }
    ${NotificationConfigurationViewItemFragmentDoc}
`
export type CreateNotificationConfigurationMutationFn = Apollo.MutationFunction<
    CreateNotificationConfigurationMutation,
    CreateNotificationConfigurationMutationVariables
>

/**
 * __useCreateNotificationConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationConfigurationMutation, { data, loading, error }] = useCreateNotificationConfigurationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      recipientListId: // value for 'recipientListId'
 *      notificationTemplateId: // value for 'notificationTemplateId'
 *   },
 * });
 */
export function useCreateNotificationConfigurationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNotificationConfigurationMutation,
        CreateNotificationConfigurationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateNotificationConfigurationMutation,
        CreateNotificationConfigurationMutationVariables
    >(CreateNotificationConfigurationDocument, options)
}
export type CreateNotificationConfigurationMutationHookResult = ReturnType<
    typeof useCreateNotificationConfigurationMutation
>
export type CreateNotificationConfigurationMutationResult =
    Apollo.MutationResult<CreateNotificationConfigurationMutation>
export type CreateNotificationConfigurationMutationOptions =
    Apollo.BaseMutationOptions<
        CreateNotificationConfigurationMutation,
        CreateNotificationConfigurationMutationVariables
    >
export const DeleteNotificationConfigurationDocument = gql`
    mutation deleteNotificationConfiguration(
        $notificationConfigurationId: Int!
    ) {
        deleteNotificationConfiguration(
            notificationConfigurationId: $notificationConfigurationId
        ) {
            ...NotificationConfigurationViewItem
        }
    }
    ${NotificationConfigurationViewItemFragmentDoc}
`
export type DeleteNotificationConfigurationMutationFn = Apollo.MutationFunction<
    DeleteNotificationConfigurationMutation,
    DeleteNotificationConfigurationMutationVariables
>

/**
 * __useDeleteNotificationConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationConfigurationMutation, { data, loading, error }] = useDeleteNotificationConfigurationMutation({
 *   variables: {
 *      notificationConfigurationId: // value for 'notificationConfigurationId'
 *   },
 * });
 */
export function useDeleteNotificationConfigurationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteNotificationConfigurationMutation,
        DeleteNotificationConfigurationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteNotificationConfigurationMutation,
        DeleteNotificationConfigurationMutationVariables
    >(DeleteNotificationConfigurationDocument, options)
}
export type DeleteNotificationConfigurationMutationHookResult = ReturnType<
    typeof useDeleteNotificationConfigurationMutation
>
export type DeleteNotificationConfigurationMutationResult =
    Apollo.MutationResult<DeleteNotificationConfigurationMutation>
export type DeleteNotificationConfigurationMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteNotificationConfigurationMutation,
        DeleteNotificationConfigurationMutationVariables
    >
export const UpdateNotificationConfigurationDocument = gql`
    mutation updateNotificationConfiguration(
        $notificationConfigurationId: Int!
        $accountId: Int!
        $recipientListId: String!
        $notificationTemplateId: String!
    ) {
        updateNotificationConfiguration(
            id: $notificationConfigurationId
            accountId: $accountId
            recipientListId: $recipientListId
            notificationTemplateId: $notificationTemplateId
        ) {
            ...NotificationConfigurationViewItem
        }
    }
    ${NotificationConfigurationViewItemFragmentDoc}
`
export type UpdateNotificationConfigurationMutationFn = Apollo.MutationFunction<
    UpdateNotificationConfigurationMutation,
    UpdateNotificationConfigurationMutationVariables
>

/**
 * __useUpdateNotificationConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationConfigurationMutation, { data, loading, error }] = useUpdateNotificationConfigurationMutation({
 *   variables: {
 *      notificationConfigurationId: // value for 'notificationConfigurationId'
 *      accountId: // value for 'accountId'
 *      recipientListId: // value for 'recipientListId'
 *      notificationTemplateId: // value for 'notificationTemplateId'
 *   },
 * });
 */
export function useUpdateNotificationConfigurationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateNotificationConfigurationMutation,
        UpdateNotificationConfigurationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateNotificationConfigurationMutation,
        UpdateNotificationConfigurationMutationVariables
    >(UpdateNotificationConfigurationDocument, options)
}
export type UpdateNotificationConfigurationMutationHookResult = ReturnType<
    typeof useUpdateNotificationConfigurationMutation
>
export type UpdateNotificationConfigurationMutationResult =
    Apollo.MutationResult<UpdateNotificationConfigurationMutation>
export type UpdateNotificationConfigurationMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateNotificationConfigurationMutation,
        UpdateNotificationConfigurationMutationVariables
    >
export const UpdateModerationBoundaryDocument = gql`
    mutation updateModerationBoundary(
        $moderationBoundaryInput: ModerationBoundaryInput!
    ) {
        updateModerationBoundary(moderationBoundary: $moderationBoundaryInput) {
            ...ModerationBoundaryViewItem
        }
    }
    ${ModerationBoundaryViewItemFragmentDoc}
`
export type UpdateModerationBoundaryMutationFn = Apollo.MutationFunction<
    UpdateModerationBoundaryMutation,
    UpdateModerationBoundaryMutationVariables
>

/**
 * __useUpdateModerationBoundaryMutation__
 *
 * To run a mutation, you first call `useUpdateModerationBoundaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModerationBoundaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModerationBoundaryMutation, { data, loading, error }] = useUpdateModerationBoundaryMutation({
 *   variables: {
 *      moderationBoundaryInput: // value for 'moderationBoundaryInput'
 *   },
 * });
 */
export function useUpdateModerationBoundaryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateModerationBoundaryMutation,
        UpdateModerationBoundaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateModerationBoundaryMutation,
        UpdateModerationBoundaryMutationVariables
    >(UpdateModerationBoundaryDocument, options)
}
export type UpdateModerationBoundaryMutationHookResult = ReturnType<
    typeof useUpdateModerationBoundaryMutation
>
export type UpdateModerationBoundaryMutationResult =
    Apollo.MutationResult<UpdateModerationBoundaryMutation>
export type UpdateModerationBoundaryMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateModerationBoundaryMutation,
        UpdateModerationBoundaryMutationVariables
    >
export const CreateModerationBoundaryDocument = gql`
    mutation createModerationBoundary(
        $moderationBoundary: ModerationBoundaryCreate!
    ) {
        createModerationBoundary(moderationBoundary: $moderationBoundary) {
            ...ModerationBoundaryViewItem
        }
    }
    ${ModerationBoundaryViewItemFragmentDoc}
`
export type CreateModerationBoundaryMutationFn = Apollo.MutationFunction<
    CreateModerationBoundaryMutation,
    CreateModerationBoundaryMutationVariables
>

/**
 * __useCreateModerationBoundaryMutation__
 *
 * To run a mutation, you first call `useCreateModerationBoundaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModerationBoundaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModerationBoundaryMutation, { data, loading, error }] = useCreateModerationBoundaryMutation({
 *   variables: {
 *      moderationBoundary: // value for 'moderationBoundary'
 *   },
 * });
 */
export function useCreateModerationBoundaryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateModerationBoundaryMutation,
        CreateModerationBoundaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateModerationBoundaryMutation,
        CreateModerationBoundaryMutationVariables
    >(CreateModerationBoundaryDocument, options)
}
export type CreateModerationBoundaryMutationHookResult = ReturnType<
    typeof useCreateModerationBoundaryMutation
>
export type CreateModerationBoundaryMutationResult =
    Apollo.MutationResult<CreateModerationBoundaryMutation>
export type CreateModerationBoundaryMutationOptions =
    Apollo.BaseMutationOptions<
        CreateModerationBoundaryMutation,
        CreateModerationBoundaryMutationVariables
    >
export const DeleteModerationBoundaryDocument = gql`
    mutation deleteModerationBoundary($moderationBoundaryId: Int!) {
        deleteModerationBoundary(moderationBoundaryId: $moderationBoundaryId) {
            ...ModerationBoundaryViewItem
        }
    }
    ${ModerationBoundaryViewItemFragmentDoc}
`
export type DeleteModerationBoundaryMutationFn = Apollo.MutationFunction<
    DeleteModerationBoundaryMutation,
    DeleteModerationBoundaryMutationVariables
>

/**
 * __useDeleteModerationBoundaryMutation__
 *
 * To run a mutation, you first call `useDeleteModerationBoundaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModerationBoundaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModerationBoundaryMutation, { data, loading, error }] = useDeleteModerationBoundaryMutation({
 *   variables: {
 *      moderationBoundaryId: // value for 'moderationBoundaryId'
 *   },
 * });
 */
export function useDeleteModerationBoundaryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteModerationBoundaryMutation,
        DeleteModerationBoundaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteModerationBoundaryMutation,
        DeleteModerationBoundaryMutationVariables
    >(DeleteModerationBoundaryDocument, options)
}
export type DeleteModerationBoundaryMutationHookResult = ReturnType<
    typeof useDeleteModerationBoundaryMutation
>
export type DeleteModerationBoundaryMutationResult =
    Apollo.MutationResult<DeleteModerationBoundaryMutation>
export type DeleteModerationBoundaryMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteModerationBoundaryMutation,
        DeleteModerationBoundaryMutationVariables
    >
export const BulkUpdateModerationBoundaryDocument = gql`
    mutation bulkUpdateModerationBoundary(
        $accountId: Int!
        $moderationBoundaries: [ModerationBoundaryCreate!]!
    ) {
        bulkUpdateModerationBoundary(
            accountId: $accountId
            moderationBoundaries: $moderationBoundaries
        )
    }
`
export type BulkUpdateModerationBoundaryMutationFn = Apollo.MutationFunction<
    BulkUpdateModerationBoundaryMutation,
    BulkUpdateModerationBoundaryMutationVariables
>

/**
 * __useBulkUpdateModerationBoundaryMutation__
 *
 * To run a mutation, you first call `useBulkUpdateModerationBoundaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateModerationBoundaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateModerationBoundaryMutation, { data, loading, error }] = useBulkUpdateModerationBoundaryMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      moderationBoundaries: // value for 'moderationBoundaries'
 *   },
 * });
 */
export function useBulkUpdateModerationBoundaryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BulkUpdateModerationBoundaryMutation,
        BulkUpdateModerationBoundaryMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        BulkUpdateModerationBoundaryMutation,
        BulkUpdateModerationBoundaryMutationVariables
    >(BulkUpdateModerationBoundaryDocument, options)
}
export type BulkUpdateModerationBoundaryMutationHookResult = ReturnType<
    typeof useBulkUpdateModerationBoundaryMutation
>
export type BulkUpdateModerationBoundaryMutationResult =
    Apollo.MutationResult<BulkUpdateModerationBoundaryMutation>
export type BulkUpdateModerationBoundaryMutationOptions =
    Apollo.BaseMutationOptions<
        BulkUpdateModerationBoundaryMutation,
        BulkUpdateModerationBoundaryMutationVariables
    >
export const CopyModerationBoundariesDocument = gql`
    mutation copyModerationBoundaries(
        $sourceAccountId: Int!
        $targetAccountIds: [Int!]!
    ) {
        copyModerationBoundaries(
            sourceAccountId: $sourceAccountId
            targetAccountIds: $targetAccountIds
        )
    }
`
export type CopyModerationBoundariesMutationFn = Apollo.MutationFunction<
    CopyModerationBoundariesMutation,
    CopyModerationBoundariesMutationVariables
>

/**
 * __useCopyModerationBoundariesMutation__
 *
 * To run a mutation, you first call `useCopyModerationBoundariesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyModerationBoundariesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyModerationBoundariesMutation, { data, loading, error }] = useCopyModerationBoundariesMutation({
 *   variables: {
 *      sourceAccountId: // value for 'sourceAccountId'
 *      targetAccountIds: // value for 'targetAccountIds'
 *   },
 * });
 */
export function useCopyModerationBoundariesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CopyModerationBoundariesMutation,
        CopyModerationBoundariesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CopyModerationBoundariesMutation,
        CopyModerationBoundariesMutationVariables
    >(CopyModerationBoundariesDocument, options)
}
export type CopyModerationBoundariesMutationHookResult = ReturnType<
    typeof useCopyModerationBoundariesMutation
>
export type CopyModerationBoundariesMutationResult =
    Apollo.MutationResult<CopyModerationBoundariesMutation>
export type CopyModerationBoundariesMutationOptions =
    Apollo.BaseMutationOptions<
        CopyModerationBoundariesMutation,
        CopyModerationBoundariesMutationVariables
    >
export const UpdateLlmClassificationPromptDocument = gql`
    mutation updateLlmClassificationPrompt(
        $promptId: Int!
        $prompt: String!
        $category: String!
    ) {
        updateLlmClassificationPrompt(
            promptId: $promptId
            prompt: $prompt
            category: $category
        ) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`
export type UpdateLlmClassificationPromptMutationFn = Apollo.MutationFunction<
    UpdateLlmClassificationPromptMutation,
    UpdateLlmClassificationPromptMutationVariables
>

/**
 * __useUpdateLlmClassificationPromptMutation__
 *
 * To run a mutation, you first call `useUpdateLlmClassificationPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLlmClassificationPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLlmClassificationPromptMutation, { data, loading, error }] = useUpdateLlmClassificationPromptMutation({
 *   variables: {
 *      promptId: // value for 'promptId'
 *      prompt: // value for 'prompt'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateLlmClassificationPromptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateLlmClassificationPromptMutation,
        UpdateLlmClassificationPromptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateLlmClassificationPromptMutation,
        UpdateLlmClassificationPromptMutationVariables
    >(UpdateLlmClassificationPromptDocument, options)
}
export type UpdateLlmClassificationPromptMutationHookResult = ReturnType<
    typeof useUpdateLlmClassificationPromptMutation
>
export type UpdateLlmClassificationPromptMutationResult =
    Apollo.MutationResult<UpdateLlmClassificationPromptMutation>
export type UpdateLlmClassificationPromptMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateLlmClassificationPromptMutation,
        UpdateLlmClassificationPromptMutationVariables
    >
export const CreateLlmClassificationPromptDocument = gql`
    mutation createLlmClassificationPrompt(
        $accountId: Int!
        $prompt: String!
        $category: String!
    ) {
        createLlmClassificationPrompt(
            accountId: $accountId
            prompt: $prompt
            category: $category
        ) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`
export type CreateLlmClassificationPromptMutationFn = Apollo.MutationFunction<
    CreateLlmClassificationPromptMutation,
    CreateLlmClassificationPromptMutationVariables
>

/**
 * __useCreateLlmClassificationPromptMutation__
 *
 * To run a mutation, you first call `useCreateLlmClassificationPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLlmClassificationPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLlmClassificationPromptMutation, { data, loading, error }] = useCreateLlmClassificationPromptMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      prompt: // value for 'prompt'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateLlmClassificationPromptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateLlmClassificationPromptMutation,
        CreateLlmClassificationPromptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateLlmClassificationPromptMutation,
        CreateLlmClassificationPromptMutationVariables
    >(CreateLlmClassificationPromptDocument, options)
}
export type CreateLlmClassificationPromptMutationHookResult = ReturnType<
    typeof useCreateLlmClassificationPromptMutation
>
export type CreateLlmClassificationPromptMutationResult =
    Apollo.MutationResult<CreateLlmClassificationPromptMutation>
export type CreateLlmClassificationPromptMutationOptions =
    Apollo.BaseMutationOptions<
        CreateLlmClassificationPromptMutation,
        CreateLlmClassificationPromptMutationVariables
    >
export const DeleteLlmClassificationPromptDocument = gql`
    mutation deleteLlmClassificationPrompt($promptId: Int!) {
        deleteLlmClassificationPrompt(promptId: $promptId)
    }
`
export type DeleteLlmClassificationPromptMutationFn = Apollo.MutationFunction<
    DeleteLlmClassificationPromptMutation,
    DeleteLlmClassificationPromptMutationVariables
>

/**
 * __useDeleteLlmClassificationPromptMutation__
 *
 * To run a mutation, you first call `useDeleteLlmClassificationPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLlmClassificationPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLlmClassificationPromptMutation, { data, loading, error }] = useDeleteLlmClassificationPromptMutation({
 *   variables: {
 *      promptId: // value for 'promptId'
 *   },
 * });
 */
export function useDeleteLlmClassificationPromptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteLlmClassificationPromptMutation,
        DeleteLlmClassificationPromptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteLlmClassificationPromptMutation,
        DeleteLlmClassificationPromptMutationVariables
    >(DeleteLlmClassificationPromptDocument, options)
}
export type DeleteLlmClassificationPromptMutationHookResult = ReturnType<
    typeof useDeleteLlmClassificationPromptMutation
>
export type DeleteLlmClassificationPromptMutationResult =
    Apollo.MutationResult<DeleteLlmClassificationPromptMutation>
export type DeleteLlmClassificationPromptMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteLlmClassificationPromptMutation,
        DeleteLlmClassificationPromptMutationVariables
    >
export const SaveLlmReplyPromptDocument = gql`
    mutation saveLlmReplyPrompt($accountId: Int!, $prompt: String!) {
        saveLlmReplyPrompt(accountId: $accountId, prompt: $prompt) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`
export type SaveLlmReplyPromptMutationFn = Apollo.MutationFunction<
    SaveLlmReplyPromptMutation,
    SaveLlmReplyPromptMutationVariables
>

/**
 * __useSaveLlmReplyPromptMutation__
 *
 * To run a mutation, you first call `useSaveLlmReplyPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLlmReplyPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLlmReplyPromptMutation, { data, loading, error }] = useSaveLlmReplyPromptMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useSaveLlmReplyPromptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SaveLlmReplyPromptMutation,
        SaveLlmReplyPromptMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SaveLlmReplyPromptMutation,
        SaveLlmReplyPromptMutationVariables
    >(SaveLlmReplyPromptDocument, options)
}
export type SaveLlmReplyPromptMutationHookResult = ReturnType<
    typeof useSaveLlmReplyPromptMutation
>
export type SaveLlmReplyPromptMutationResult =
    Apollo.MutationResult<SaveLlmReplyPromptMutation>
export type SaveLlmReplyPromptMutationOptions = Apollo.BaseMutationOptions<
    SaveLlmReplyPromptMutation,
    SaveLlmReplyPromptMutationVariables
>
export const LikeContentDocument = gql`
    mutation likeContent($like: Boolean!, $contentIds: [Int!]!) {
        likeContent(like: $like, contentIds: $contentIds) {
            ...ContentActionResult
        }
    }
    ${ContentActionResultFragmentDoc}
`
export type LikeContentMutationFn = Apollo.MutationFunction<
    LikeContentMutation,
    LikeContentMutationVariables
>

/**
 * __useLikeContentMutation__
 *
 * To run a mutation, you first call `useLikeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeContentMutation, { data, loading, error }] = useLikeContentMutation({
 *   variables: {
 *      like: // value for 'like'
 *      contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useLikeContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LikeContentMutation,
        LikeContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        LikeContentMutation,
        LikeContentMutationVariables
    >(LikeContentDocument, options)
}
export type LikeContentMutationHookResult = ReturnType<
    typeof useLikeContentMutation
>
export type LikeContentMutationResult =
    Apollo.MutationResult<LikeContentMutation>
export type LikeContentMutationOptions = Apollo.BaseMutationOptions<
    LikeContentMutation,
    LikeContentMutationVariables
>
export const ReplyToContentDocument = gql`
    mutation replyToContent($message: String!, $contentId: Int!) {
        replyToContent(message: $message, contentId: $contentId) {
            ...ContentActionView
        }
    }
    ${ContentActionViewFragmentDoc}
`
export type ReplyToContentMutationFn = Apollo.MutationFunction<
    ReplyToContentMutation,
    ReplyToContentMutationVariables
>

/**
 * __useReplyToContentMutation__
 *
 * To run a mutation, you first call `useReplyToContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToContentMutation, { data, loading, error }] = useReplyToContentMutation({
 *   variables: {
 *      message: // value for 'message'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useReplyToContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ReplyToContentMutation,
        ReplyToContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        ReplyToContentMutation,
        ReplyToContentMutationVariables
    >(ReplyToContentDocument, options)
}
export type ReplyToContentMutationHookResult = ReturnType<
    typeof useReplyToContentMutation
>
export type ReplyToContentMutationResult =
    Apollo.MutationResult<ReplyToContentMutation>
export type ReplyToContentMutationOptions = Apollo.BaseMutationOptions<
    ReplyToContentMutation,
    ReplyToContentMutationVariables
>
export const EditAccountTeamDocument = gql`
    mutation editAccountTeam($accountId: Int!, $teamId: Int) {
        editAccountTeam(accountId: $accountId, teamId: $teamId) {
            ...AccountRecordItem
        }
    }
    ${AccountRecordItemFragmentDoc}
`
export type EditAccountTeamMutationFn = Apollo.MutationFunction<
    EditAccountTeamMutation,
    EditAccountTeamMutationVariables
>

/**
 * __useEditAccountTeamMutation__
 *
 * To run a mutation, you first call `useEditAccountTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountTeamMutation, { data, loading, error }] = useEditAccountTeamMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useEditAccountTeamMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EditAccountTeamMutation,
        EditAccountTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        EditAccountTeamMutation,
        EditAccountTeamMutationVariables
    >(EditAccountTeamDocument, options)
}
export type EditAccountTeamMutationHookResult = ReturnType<
    typeof useEditAccountTeamMutation
>
export type EditAccountTeamMutationResult =
    Apollo.MutationResult<EditAccountTeamMutation>
export type EditAccountTeamMutationOptions = Apollo.BaseMutationOptions<
    EditAccountTeamMutation,
    EditAccountTeamMutationVariables
>
export const UpdateShadowBanningDocument = gql`
    mutation updateShadowBanning(
        $accountId: Int!
        $enabled: Boolean!
        $threshold: Int!
        $rangeInDays: Int!
    ) {
        updateShadowBanning(
            accountId: $accountId
            enabled: $enabled
            threshold: $threshold
            rangeInDays: $rangeInDays
        ) {
            ...AccountRecordItem
        }
    }
    ${AccountRecordItemFragmentDoc}
`
export type UpdateShadowBanningMutationFn = Apollo.MutationFunction<
    UpdateShadowBanningMutation,
    UpdateShadowBanningMutationVariables
>

/**
 * __useUpdateShadowBanningMutation__
 *
 * To run a mutation, you first call `useUpdateShadowBanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShadowBanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShadowBanningMutation, { data, loading, error }] = useUpdateShadowBanningMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      enabled: // value for 'enabled'
 *      threshold: // value for 'threshold'
 *      rangeInDays: // value for 'rangeInDays'
 *   },
 * });
 */
export function useUpdateShadowBanningMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateShadowBanningMutation,
        UpdateShadowBanningMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateShadowBanningMutation,
        UpdateShadowBanningMutationVariables
    >(UpdateShadowBanningDocument, options)
}
export type UpdateShadowBanningMutationHookResult = ReturnType<
    typeof useUpdateShadowBanningMutation
>
export type UpdateShadowBanningMutationResult =
    Apollo.MutationResult<UpdateShadowBanningMutation>
export type UpdateShadowBanningMutationOptions = Apollo.BaseMutationOptions<
    UpdateShadowBanningMutation,
    UpdateShadowBanningMutationVariables
>
export const RemoveSpamItemsDocument = gql`
    mutation removeSpamItems($contentIds: [Int!]!) {
        removeSpamItems(contentIds: $contentIds)
    }
`
export type RemoveSpamItemsMutationFn = Apollo.MutationFunction<
    RemoveSpamItemsMutation,
    RemoveSpamItemsMutationVariables
>

/**
 * __useRemoveSpamItemsMutation__
 *
 * To run a mutation, you first call `useRemoveSpamItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSpamItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSpamItemsMutation, { data, loading, error }] = useRemoveSpamItemsMutation({
 *   variables: {
 *      contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useRemoveSpamItemsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveSpamItemsMutation,
        RemoveSpamItemsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        RemoveSpamItemsMutation,
        RemoveSpamItemsMutationVariables
    >(RemoveSpamItemsDocument, options)
}
export type RemoveSpamItemsMutationHookResult = ReturnType<
    typeof useRemoveSpamItemsMutation
>
export type RemoveSpamItemsMutationResult =
    Apollo.MutationResult<RemoveSpamItemsMutation>
export type RemoveSpamItemsMutationOptions = Apollo.BaseMutationOptions<
    RemoveSpamItemsMutation,
    RemoveSpamItemsMutationVariables
>
export const CreateTeamDocument = gql`
    mutation createTeam($teamName: String!) {
        createTeam(teamName: $teamName) {
            id
            name
            createdAt
        }
    }
`
export type CreateTeamMutationFn = Apollo.MutationFunction<
    CreateTeamMutation,
    CreateTeamMutationVariables
>

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useCreateTeamMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTeamMutation,
        CreateTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
        CreateTeamDocument,
        options
    )
}
export type CreateTeamMutationHookResult = ReturnType<
    typeof useCreateTeamMutation
>
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
>
export const EditTeamDocument = gql`
    mutation editTeam($teamId: Int!, $name: String!) {
        editTeam(teamId: $teamId, name: $name) {
            id
            name
        }
    }
`
export type EditTeamMutationFn = Apollo.MutationFunction<
    EditTeamMutation,
    EditTeamMutationVariables
>

/**
 * __useEditTeamMutation__
 *
 * To run a mutation, you first call `useEditTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMutation, { data, loading, error }] = useEditTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditTeamMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EditTeamMutation,
        EditTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<EditTeamMutation, EditTeamMutationVariables>(
        EditTeamDocument,
        options
    )
}
export type EditTeamMutationHookResult = ReturnType<typeof useEditTeamMutation>
export type EditTeamMutationResult = Apollo.MutationResult<EditTeamMutation>
export type EditTeamMutationOptions = Apollo.BaseMutationOptions<
    EditTeamMutation,
    EditTeamMutationVariables
>
export const DeleteTeamDocument = gql`
    mutation deleteTeam($teamId: Int!) {
        deleteTeam(teamId: $teamId)
    }
`
export type DeleteTeamMutationFn = Apollo.MutationFunction<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
>

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTeamMutation,
        DeleteTeamMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
        DeleteTeamDocument,
        options
    )
}
export type DeleteTeamMutationHookResult = ReturnType<
    typeof useDeleteTeamMutation
>
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
>
export const CreateKeywordDocument = gql`
    mutation createKeyword($params: CreateKeywordParamsView!) {
        createKeyword(params: $params) {
            keyword
            regex
            allowableRegex
            description
            accountId
            classifierDetailsId
        }
    }
`
export type CreateKeywordMutationFn = Apollo.MutationFunction<
    CreateKeywordMutation,
    CreateKeywordMutationVariables
>

/**
 * __useCreateKeywordMutation__
 *
 * To run a mutation, you first call `useCreateKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeywordMutation, { data, loading, error }] = useCreateKeywordMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateKeywordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateKeywordMutation,
        CreateKeywordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateKeywordMutation,
        CreateKeywordMutationVariables
    >(CreateKeywordDocument, options)
}
export type CreateKeywordMutationHookResult = ReturnType<
    typeof useCreateKeywordMutation
>
export type CreateKeywordMutationResult =
    Apollo.MutationResult<CreateKeywordMutation>
export type CreateKeywordMutationOptions = Apollo.BaseMutationOptions<
    CreateKeywordMutation,
    CreateKeywordMutationVariables
>
export const DeleteKeywordDocument = gql`
    mutation deleteKeyword($keywordId: Int!) {
        deleteKeyword(keywordId: $keywordId)
    }
`
export type DeleteKeywordMutationFn = Apollo.MutationFunction<
    DeleteKeywordMutation,
    DeleteKeywordMutationVariables
>

/**
 * __useDeleteKeywordMutation__
 *
 * To run a mutation, you first call `useDeleteKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKeywordMutation, { data, loading, error }] = useDeleteKeywordMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useDeleteKeywordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteKeywordMutation,
        DeleteKeywordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteKeywordMutation,
        DeleteKeywordMutationVariables
    >(DeleteKeywordDocument, options)
}
export type DeleteKeywordMutationHookResult = ReturnType<
    typeof useDeleteKeywordMutation
>
export type DeleteKeywordMutationResult =
    Apollo.MutationResult<DeleteKeywordMutation>
export type DeleteKeywordMutationOptions = Apollo.BaseMutationOptions<
    DeleteKeywordMutation,
    DeleteKeywordMutationVariables
>
export const CopyKeywordsToAccountDocument = gql`
    mutation copyKeywordsToAccount(
        $sourceAccountId: Int!
        $targetAccountId: Int!
    ) {
        copyKeywordsToAccount(
            sourceAccountId: $sourceAccountId
            targetAccountId: $targetAccountId
        )
    }
`
export type CopyKeywordsToAccountMutationFn = Apollo.MutationFunction<
    CopyKeywordsToAccountMutation,
    CopyKeywordsToAccountMutationVariables
>

/**
 * __useCopyKeywordsToAccountMutation__
 *
 * To run a mutation, you first call `useCopyKeywordsToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyKeywordsToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyKeywordsToAccountMutation, { data, loading, error }] = useCopyKeywordsToAccountMutation({
 *   variables: {
 *      sourceAccountId: // value for 'sourceAccountId'
 *      targetAccountId: // value for 'targetAccountId'
 *   },
 * });
 */
export function useCopyKeywordsToAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CopyKeywordsToAccountMutation,
        CopyKeywordsToAccountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CopyKeywordsToAccountMutation,
        CopyKeywordsToAccountMutationVariables
    >(CopyKeywordsToAccountDocument, options)
}
export type CopyKeywordsToAccountMutationHookResult = ReturnType<
    typeof useCopyKeywordsToAccountMutation
>
export type CopyKeywordsToAccountMutationResult =
    Apollo.MutationResult<CopyKeywordsToAccountMutation>
export type CopyKeywordsToAccountMutationOptions = Apollo.BaseMutationOptions<
    CopyKeywordsToAccountMutation,
    CopyKeywordsToAccountMutationVariables
>
export const ValidateUserIdentityDocument = gql`
    mutation validateUserIdentity {
        validateUserIdentity {
            ...UserItem
        }
    }
    ${UserItemFragmentDoc}
`
export type ValidateUserIdentityMutationFn = Apollo.MutationFunction<
    ValidateUserIdentityMutation,
    ValidateUserIdentityMutationVariables
>

/**
 * __useValidateUserIdentityMutation__
 *
 * To run a mutation, you first call `useValidateUserIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateUserIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateUserIdentityMutation, { data, loading, error }] = useValidateUserIdentityMutation({
 *   variables: {
 *   },
 * });
 */
export function useValidateUserIdentityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ValidateUserIdentityMutation,
        ValidateUserIdentityMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        ValidateUserIdentityMutation,
        ValidateUserIdentityMutationVariables
    >(ValidateUserIdentityDocument, options)
}
export type ValidateUserIdentityMutationHookResult = ReturnType<
    typeof useValidateUserIdentityMutation
>
export type ValidateUserIdentityMutationResult =
    Apollo.MutationResult<ValidateUserIdentityMutation>
export type ValidateUserIdentityMutationOptions = Apollo.BaseMutationOptions<
    ValidateUserIdentityMutation,
    ValidateUserIdentityMutationVariables
>
export const CreateUserDocument = gql`
    mutation createUser($userParams: UserCreateParamsView!) {
        createUser(userParams: $userParams)
    }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
    CreateUserMutation,
    CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userParams: // value for 'userParams'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateUserMutation,
        CreateUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        options
    )
}
export type CreateUserMutationHookResult = ReturnType<
    typeof useCreateUserMutation
>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
    CreateUserMutation,
    CreateUserMutationVariables
>
export const EditUserDocument = gql`
    mutation editUser($userParams: UserEditParamsView!) {
        editUser(userParams: $userParams)
    }
`
export type EditUserMutationFn = Apollo.MutationFunction<
    EditUserMutation,
    EditUserMutationVariables
>

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userParams: // value for 'userParams'
 *   },
 * });
 */
export function useEditUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EditUserMutation,
        EditUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
        EditUserDocument,
        options
    )
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
    EditUserMutation,
    EditUserMutationVariables
>
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: Int!) {
        deleteUser(userId: $userId)
    }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<
    DeleteUserMutation,
    DeleteUserMutationVariables
>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        options
    )
}
export type DeleteUserMutationHookResult = ReturnType<
    typeof useDeleteUserMutation
>
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
>
export const MetaHistoricFetchDocument = gql`
    mutation metaHistoricFetch(
        $accountId: Int!
        $from: DateTime!
        $to: DateTime!
    ) {
        metaHistoricFetch(accountId: $accountId, from: $from, to: $to)
    }
`
export type MetaHistoricFetchMutationFn = Apollo.MutationFunction<
    MetaHistoricFetchMutation,
    MetaHistoricFetchMutationVariables
>

/**
 * __useMetaHistoricFetchMutation__
 *
 * To run a mutation, you first call `useMetaHistoricFetchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetaHistoricFetchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metaHistoricFetchMutation, { data, loading, error }] = useMetaHistoricFetchMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMetaHistoricFetchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MetaHistoricFetchMutation,
        MetaHistoricFetchMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        MetaHistoricFetchMutation,
        MetaHistoricFetchMutationVariables
    >(MetaHistoricFetchDocument, options)
}
export type MetaHistoricFetchMutationHookResult = ReturnType<
    typeof useMetaHistoricFetchMutation
>
export type MetaHistoricFetchMutationResult =
    Apollo.MutationResult<MetaHistoricFetchMutation>
export type MetaHistoricFetchMutationOptions = Apollo.BaseMutationOptions<
    MetaHistoricFetchMutation,
    MetaHistoricFetchMutationVariables
>
export const TiktokAuthCodeToAccessTokenDocument = gql`
    mutation tiktokAuthCodeToAccessToken($authCode: String!, $code: String!) {
        tiktokAuthCodeToAccessToken(authCode: $authCode, code: $code) {
            ...TikTokAccountSelectionItem
        }
    }
    ${TikTokAccountSelectionItemFragmentDoc}
`
export type TiktokAuthCodeToAccessTokenMutationFn = Apollo.MutationFunction<
    TiktokAuthCodeToAccessTokenMutation,
    TiktokAuthCodeToAccessTokenMutationVariables
>

/**
 * __useTiktokAuthCodeToAccessTokenMutation__
 *
 * To run a mutation, you first call `useTiktokAuthCodeToAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTiktokAuthCodeToAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tiktokAuthCodeToAccessTokenMutation, { data, loading, error }] = useTiktokAuthCodeToAccessTokenMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTiktokAuthCodeToAccessTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TiktokAuthCodeToAccessTokenMutation,
        TiktokAuthCodeToAccessTokenMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        TiktokAuthCodeToAccessTokenMutation,
        TiktokAuthCodeToAccessTokenMutationVariables
    >(TiktokAuthCodeToAccessTokenDocument, options)
}
export type TiktokAuthCodeToAccessTokenMutationHookResult = ReturnType<
    typeof useTiktokAuthCodeToAccessTokenMutation
>
export type TiktokAuthCodeToAccessTokenMutationResult =
    Apollo.MutationResult<TiktokAuthCodeToAccessTokenMutation>
export type TiktokAuthCodeToAccessTokenMutationOptions =
    Apollo.BaseMutationOptions<
        TiktokAuthCodeToAccessTokenMutation,
        TiktokAuthCodeToAccessTokenMutationVariables
    >
export const TiktokCreateUserAccountWithAuthDocument = gql`
    mutation tiktokCreateUserAccountWithAuth(
        $authCode: String!
        $code: String!
        $redirectUri: String!
    ) {
        tiktokCreateUserAccountWithAuth(
            authCode: $authCode
            code: $code
            redirectUri: $redirectUri
        ) {
            ...TikTokUserAccountSelectionItem
        }
    }
    ${TikTokUserAccountSelectionItemFragmentDoc}
`
export type TiktokCreateUserAccountWithAuthMutationFn = Apollo.MutationFunction<
    TiktokCreateUserAccountWithAuthMutation,
    TiktokCreateUserAccountWithAuthMutationVariables
>

/**
 * __useTiktokCreateUserAccountWithAuthMutation__
 *
 * To run a mutation, you first call `useTiktokCreateUserAccountWithAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTiktokCreateUserAccountWithAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tiktokCreateUserAccountWithAuthMutation, { data, loading, error }] = useTiktokCreateUserAccountWithAuthMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *      code: // value for 'code'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useTiktokCreateUserAccountWithAuthMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TiktokCreateUserAccountWithAuthMutation,
        TiktokCreateUserAccountWithAuthMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        TiktokCreateUserAccountWithAuthMutation,
        TiktokCreateUserAccountWithAuthMutationVariables
    >(TiktokCreateUserAccountWithAuthDocument, options)
}
export type TiktokCreateUserAccountWithAuthMutationHookResult = ReturnType<
    typeof useTiktokCreateUserAccountWithAuthMutation
>
export type TiktokCreateUserAccountWithAuthMutationResult =
    Apollo.MutationResult<TiktokCreateUserAccountWithAuthMutation>
export type TiktokCreateUserAccountWithAuthMutationOptions =
    Apollo.BaseMutationOptions<
        TiktokCreateUserAccountWithAuthMutation,
        TiktokCreateUserAccountWithAuthMutationVariables
    >
export const CreateTiktokAccountsDocument = gql`
    mutation createTiktokAccounts(
        $sessionKey: String!
        $adAccountIds: [String!]!
    ) {
        createTiktokAccounts(
            sessionKey: $sessionKey
            adAccountIds: $adAccountIds
        ) {
            ...TikTokAuthorisedAccountItem
        }
    }
    ${TikTokAuthorisedAccountItemFragmentDoc}
`
export type CreateTiktokAccountsMutationFn = Apollo.MutationFunction<
    CreateTiktokAccountsMutation,
    CreateTiktokAccountsMutationVariables
>

/**
 * __useCreateTiktokAccountsMutation__
 *
 * To run a mutation, you first call `useCreateTiktokAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTiktokAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTiktokAccountsMutation, { data, loading, error }] = useCreateTiktokAccountsMutation({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *      adAccountIds: // value for 'adAccountIds'
 *   },
 * });
 */
export function useCreateTiktokAccountsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTiktokAccountsMutation,
        CreateTiktokAccountsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateTiktokAccountsMutation,
        CreateTiktokAccountsMutationVariables
    >(CreateTiktokAccountsDocument, options)
}
export type CreateTiktokAccountsMutationHookResult = ReturnType<
    typeof useCreateTiktokAccountsMutation
>
export type CreateTiktokAccountsMutationResult =
    Apollo.MutationResult<CreateTiktokAccountsMutation>
export type CreateTiktokAccountsMutationOptions = Apollo.BaseMutationOptions<
    CreateTiktokAccountsMutation,
    CreateTiktokAccountsMutationVariables
>
export const GetMyContentFeedsDocument = gql`
    query GetMyContentFeeds {
        getMyContentFeeds {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`

/**
 * __useGetMyContentFeedsQuery__
 *
 * To run a query within a React component, call `useGetMyContentFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyContentFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyContentFeedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyContentFeedsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetMyContentFeedsQuery,
        GetMyContentFeedsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetMyContentFeedsQuery,
        GetMyContentFeedsQueryVariables
    >(GetMyContentFeedsDocument, options)
}
export function useGetMyContentFeedsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMyContentFeedsQuery,
        GetMyContentFeedsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetMyContentFeedsQuery,
        GetMyContentFeedsQueryVariables
    >(GetMyContentFeedsDocument, options)
}
export type GetMyContentFeedsQueryHookResult = ReturnType<
    typeof useGetMyContentFeedsQuery
>
export type GetMyContentFeedsLazyQueryHookResult = ReturnType<
    typeof useGetMyContentFeedsLazyQuery
>
export type GetMyContentFeedsQueryResult = Apollo.QueryResult<
    GetMyContentFeedsQuery,
    GetMyContentFeedsQueryVariables
>
export const GetContentFeedsForUserDocument = gql`
    query GetContentFeedsForUser($userId: Int!) {
        getContentFeedsForUser(userId: $userId) {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`

/**
 * __useGetContentFeedsForUserQuery__
 *
 * To run a query within a React component, call `useGetContentFeedsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentFeedsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentFeedsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetContentFeedsForUserQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetContentFeedsForUserQuery,
        GetContentFeedsForUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetContentFeedsForUserQuery,
        GetContentFeedsForUserQueryVariables
    >(GetContentFeedsForUserDocument, options)
}
export function useGetContentFeedsForUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetContentFeedsForUserQuery,
        GetContentFeedsForUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetContentFeedsForUserQuery,
        GetContentFeedsForUserQueryVariables
    >(GetContentFeedsForUserDocument, options)
}
export type GetContentFeedsForUserQueryHookResult = ReturnType<
    typeof useGetContentFeedsForUserQuery
>
export type GetContentFeedsForUserLazyQueryHookResult = ReturnType<
    typeof useGetContentFeedsForUserLazyQuery
>
export type GetContentFeedsForUserQueryResult = Apollo.QueryResult<
    GetContentFeedsForUserQuery,
    GetContentFeedsForUserQueryVariables
>
export const GetContentFeedDocument = gql`
    query GetContentFeed($contentFeedId: Int!) {
        getContentFeed(contentFeedId: $contentFeedId) {
            ...ContentFeedViewItem
        }
    }
    ${ContentFeedViewItemFragmentDoc}
`

/**
 * __useGetContentFeedQuery__
 *
 * To run a query within a React component, call `useGetContentFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentFeedQuery({
 *   variables: {
 *      contentFeedId: // value for 'contentFeedId'
 *   },
 * });
 */
export function useGetContentFeedQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetContentFeedQuery,
        GetContentFeedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetContentFeedQuery, GetContentFeedQueryVariables>(
        GetContentFeedDocument,
        options
    )
}
export function useGetContentFeedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetContentFeedQuery,
        GetContentFeedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetContentFeedQuery,
        GetContentFeedQueryVariables
    >(GetContentFeedDocument, options)
}
export type GetContentFeedQueryHookResult = ReturnType<
    typeof useGetContentFeedQuery
>
export type GetContentFeedLazyQueryHookResult = ReturnType<
    typeof useGetContentFeedLazyQuery
>
export type GetContentFeedQueryResult = Apollo.QueryResult<
    GetContentFeedQuery,
    GetContentFeedQueryVariables
>
export const GetNotificationConfigurationForAccountDocument = gql`
    query GetNotificationConfigurationForAccount($accountId: Int!) {
        getNotificationConfigurationForAccount(accountId: $accountId) {
            ...NotificationConfigurationViewItem
        }
    }
    ${NotificationConfigurationViewItemFragmentDoc}
`

/**
 * __useGetNotificationConfigurationForAccountQuery__
 *
 * To run a query within a React component, call `useGetNotificationConfigurationForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationConfigurationForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationConfigurationForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetNotificationConfigurationForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetNotificationConfigurationForAccountQuery,
        GetNotificationConfigurationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetNotificationConfigurationForAccountQuery,
        GetNotificationConfigurationForAccountQueryVariables
    >(GetNotificationConfigurationForAccountDocument, options)
}
export function useGetNotificationConfigurationForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetNotificationConfigurationForAccountQuery,
        GetNotificationConfigurationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetNotificationConfigurationForAccountQuery,
        GetNotificationConfigurationForAccountQueryVariables
    >(GetNotificationConfigurationForAccountDocument, options)
}
export type GetNotificationConfigurationForAccountQueryHookResult = ReturnType<
    typeof useGetNotificationConfigurationForAccountQuery
>
export type GetNotificationConfigurationForAccountLazyQueryHookResult =
    ReturnType<typeof useGetNotificationConfigurationForAccountLazyQuery>
export type GetNotificationConfigurationForAccountQueryResult =
    Apollo.QueryResult<
        GetNotificationConfigurationForAccountQuery,
        GetNotificationConfigurationForAccountQueryVariables
    >
export const GetNotificationConfigurationDocument = gql`
    query GetNotificationConfiguration($notificationConfigurationId: Int!) {
        getNotificationConfiguration(
            notificationConfigurationId: $notificationConfigurationId
        ) {
            ...NotificationConfigurationViewItem
        }
    }
    ${NotificationConfigurationViewItemFragmentDoc}
`

/**
 * __useGetNotificationConfigurationQuery__
 *
 * To run a query within a React component, call `useGetNotificationConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationConfigurationQuery({
 *   variables: {
 *      notificationConfigurationId: // value for 'notificationConfigurationId'
 *   },
 * });
 */
export function useGetNotificationConfigurationQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetNotificationConfigurationQuery,
        GetNotificationConfigurationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetNotificationConfigurationQuery,
        GetNotificationConfigurationQueryVariables
    >(GetNotificationConfigurationDocument, options)
}
export function useGetNotificationConfigurationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetNotificationConfigurationQuery,
        GetNotificationConfigurationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetNotificationConfigurationQuery,
        GetNotificationConfigurationQueryVariables
    >(GetNotificationConfigurationDocument, options)
}
export type GetNotificationConfigurationQueryHookResult = ReturnType<
    typeof useGetNotificationConfigurationQuery
>
export type GetNotificationConfigurationLazyQueryHookResult = ReturnType<
    typeof useGetNotificationConfigurationLazyQuery
>
export type GetNotificationConfigurationQueryResult = Apollo.QueryResult<
    GetNotificationConfigurationQuery,
    GetNotificationConfigurationQueryVariables
>
export const GetDashboardDocument = gql`
    query GetDashboard($dashboardId: Int!) {
        getDashboard(dashboardId: $dashboardId) {
            ...DashboardViewItem
        }
    }
    ${DashboardViewItemFragmentDoc}
`

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useGetDashboardQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDashboardQuery,
        GetDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(
        GetDashboardDocument,
        options
    )
}
export function useGetDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDashboardQuery,
        GetDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(
        GetDashboardDocument,
        options
    )
}
export type GetDashboardQueryHookResult = ReturnType<
    typeof useGetDashboardQuery
>
export type GetDashboardLazyQueryHookResult = ReturnType<
    typeof useGetDashboardLazyQuery
>
export type GetDashboardQueryResult = Apollo.QueryResult<
    GetDashboardQuery,
    GetDashboardQueryVariables
>
export const GetEditableDashboardDocument = gql`
    query GetEditableDashboard($dashboardId: Int!) {
        getEditableDashboard(dashboardId: $dashboardId) {
            ...EditableDashboardViewItem
        }
    }
    ${EditableDashboardViewItemFragmentDoc}
`

/**
 * __useGetEditableDashboardQuery__
 *
 * To run a query within a React component, call `useGetEditableDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditableDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditableDashboardQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useGetEditableDashboardQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEditableDashboardQuery,
        GetEditableDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetEditableDashboardQuery,
        GetEditableDashboardQueryVariables
    >(GetEditableDashboardDocument, options)
}
export function useGetEditableDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEditableDashboardQuery,
        GetEditableDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetEditableDashboardQuery,
        GetEditableDashboardQueryVariables
    >(GetEditableDashboardDocument, options)
}
export type GetEditableDashboardQueryHookResult = ReturnType<
    typeof useGetEditableDashboardQuery
>
export type GetEditableDashboardLazyQueryHookResult = ReturnType<
    typeof useGetEditableDashboardLazyQuery
>
export type GetEditableDashboardQueryResult = Apollo.QueryResult<
    GetEditableDashboardQuery,
    GetEditableDashboardQueryVariables
>
export const GetDashboardsDocument = gql`
    query GetDashboards {
        getDashboards {
            ...DashboardViewItem
        }
    }
    ${DashboardViewItemFragmentDoc}
`

/**
 * __useGetDashboardsQuery__
 *
 * To run a query within a React component, call `useGetDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetDashboardsQuery,
        GetDashboardsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(
        GetDashboardsDocument,
        options
    )
}
export function useGetDashboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDashboardsQuery,
        GetDashboardsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(
        GetDashboardsDocument,
        options
    )
}
export type GetDashboardsQueryHookResult = ReturnType<
    typeof useGetDashboardsQuery
>
export type GetDashboardsLazyQueryHookResult = ReturnType<
    typeof useGetDashboardsLazyQuery
>
export type GetDashboardsQueryResult = Apollo.QueryResult<
    GetDashboardsQuery,
    GetDashboardsQueryVariables
>
export const GetEmbeddedDashboardDocument = gql`
    query getEmbeddedDashboard($dashboardId: Int!) {
        getEmbeddedDashboard(dashboardId: $dashboardId) {
            ...EmbeddedDashboardViewItem
        }
    }
    ${EmbeddedDashboardViewItemFragmentDoc}
`

/**
 * __useGetEmbeddedDashboardQuery__
 *
 * To run a query within a React component, call `useGetEmbeddedDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmbeddedDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmbeddedDashboardQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useGetEmbeddedDashboardQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEmbeddedDashboardQuery,
        GetEmbeddedDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetEmbeddedDashboardQuery,
        GetEmbeddedDashboardQueryVariables
    >(GetEmbeddedDashboardDocument, options)
}
export function useGetEmbeddedDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEmbeddedDashboardQuery,
        GetEmbeddedDashboardQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetEmbeddedDashboardQuery,
        GetEmbeddedDashboardQueryVariables
    >(GetEmbeddedDashboardDocument, options)
}
export type GetEmbeddedDashboardQueryHookResult = ReturnType<
    typeof useGetEmbeddedDashboardQuery
>
export type GetEmbeddedDashboardLazyQueryHookResult = ReturnType<
    typeof useGetEmbeddedDashboardLazyQuery
>
export type GetEmbeddedDashboardQueryResult = Apollo.QueryResult<
    GetEmbeddedDashboardQuery,
    GetEmbeddedDashboardQueryVariables
>
export const GetModerationBoundariesDocument = gql`
    query GetModerationBoundaries($accountId: Int!) {
        getModerationBoundaries(accountId: $accountId) {
            ...ModerationBoundaryViewItem
        }
    }
    ${ModerationBoundaryViewItemFragmentDoc}
`

/**
 * __useGetModerationBoundariesQuery__
 *
 * To run a query within a React component, call `useGetModerationBoundariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModerationBoundariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModerationBoundariesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetModerationBoundariesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModerationBoundariesQuery,
        GetModerationBoundariesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetModerationBoundariesQuery,
        GetModerationBoundariesQueryVariables
    >(GetModerationBoundariesDocument, options)
}
export function useGetModerationBoundariesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModerationBoundariesQuery,
        GetModerationBoundariesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetModerationBoundariesQuery,
        GetModerationBoundariesQueryVariables
    >(GetModerationBoundariesDocument, options)
}
export type GetModerationBoundariesQueryHookResult = ReturnType<
    typeof useGetModerationBoundariesQuery
>
export type GetModerationBoundariesLazyQueryHookResult = ReturnType<
    typeof useGetModerationBoundariesLazyQuery
>
export type GetModerationBoundariesQueryResult = Apollo.QueryResult<
    GetModerationBoundariesQuery,
    GetModerationBoundariesQueryVariables
>
export const GetLlmClassificationPromptDocument = gql`
    query getLlmClassificationPrompt($promptId: Int!) {
        getLlmClassificationPrompt(promptId: $promptId) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`

/**
 * __useGetLlmClassificationPromptQuery__
 *
 * To run a query within a React component, call `useGetLlmClassificationPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmClassificationPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmClassificationPromptQuery({
 *   variables: {
 *      promptId: // value for 'promptId'
 *   },
 * });
 */
export function useGetLlmClassificationPromptQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLlmClassificationPromptQuery,
        GetLlmClassificationPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetLlmClassificationPromptQuery,
        GetLlmClassificationPromptQueryVariables
    >(GetLlmClassificationPromptDocument, options)
}
export function useGetLlmClassificationPromptLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLlmClassificationPromptQuery,
        GetLlmClassificationPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLlmClassificationPromptQuery,
        GetLlmClassificationPromptQueryVariables
    >(GetLlmClassificationPromptDocument, options)
}
export type GetLlmClassificationPromptQueryHookResult = ReturnType<
    typeof useGetLlmClassificationPromptQuery
>
export type GetLlmClassificationPromptLazyQueryHookResult = ReturnType<
    typeof useGetLlmClassificationPromptLazyQuery
>
export type GetLlmClassificationPromptQueryResult = Apollo.QueryResult<
    GetLlmClassificationPromptQuery,
    GetLlmClassificationPromptQueryVariables
>
export const GetLlmClassificationPromptsDocument = gql`
    query getLlmClassificationPrompts($accountId: Int!) {
        getLlmClassificationPrompts(accountId: $accountId) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`

/**
 * __useGetLlmClassificationPromptsQuery__
 *
 * To run a query within a React component, call `useGetLlmClassificationPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmClassificationPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmClassificationPromptsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetLlmClassificationPromptsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLlmClassificationPromptsQuery,
        GetLlmClassificationPromptsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetLlmClassificationPromptsQuery,
        GetLlmClassificationPromptsQueryVariables
    >(GetLlmClassificationPromptsDocument, options)
}
export function useGetLlmClassificationPromptsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLlmClassificationPromptsQuery,
        GetLlmClassificationPromptsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLlmClassificationPromptsQuery,
        GetLlmClassificationPromptsQueryVariables
    >(GetLlmClassificationPromptsDocument, options)
}
export type GetLlmClassificationPromptsQueryHookResult = ReturnType<
    typeof useGetLlmClassificationPromptsQuery
>
export type GetLlmClassificationPromptsLazyQueryHookResult = ReturnType<
    typeof useGetLlmClassificationPromptsLazyQuery
>
export type GetLlmClassificationPromptsQueryResult = Apollo.QueryResult<
    GetLlmClassificationPromptsQuery,
    GetLlmClassificationPromptsQueryVariables
>
export const GetLlmClassificationCategoryDocument = gql`
    query getLlmClassificationCategory(
        $accountId: Int!
        $llmClassifierDetailsName: String!
    ) {
        getLlmClassificationCategory(
            accountId: $accountId
            llmClassifierDetailsName: $llmClassifierDetailsName
        )
    }
`

/**
 * __useGetLlmClassificationCategoryQuery__
 *
 * To run a query within a React component, call `useGetLlmClassificationCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmClassificationCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmClassificationCategoryQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      llmClassifierDetailsName: // value for 'llmClassifierDetailsName'
 *   },
 * });
 */
export function useGetLlmClassificationCategoryQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLlmClassificationCategoryQuery,
        GetLlmClassificationCategoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetLlmClassificationCategoryQuery,
        GetLlmClassificationCategoryQueryVariables
    >(GetLlmClassificationCategoryDocument, options)
}
export function useGetLlmClassificationCategoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLlmClassificationCategoryQuery,
        GetLlmClassificationCategoryQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLlmClassificationCategoryQuery,
        GetLlmClassificationCategoryQueryVariables
    >(GetLlmClassificationCategoryDocument, options)
}
export type GetLlmClassificationCategoryQueryHookResult = ReturnType<
    typeof useGetLlmClassificationCategoryQuery
>
export type GetLlmClassificationCategoryLazyQueryHookResult = ReturnType<
    typeof useGetLlmClassificationCategoryLazyQuery
>
export type GetLlmClassificationCategoryQueryResult = Apollo.QueryResult<
    GetLlmClassificationCategoryQuery,
    GetLlmClassificationCategoryQueryVariables
>
export const GetLlmReplyPromptDocument = gql`
    query getLlmReplyPrompt($accountId: Int!) {
        getLlmReplyPrompt(accountId: $accountId) {
            ...PromptViewItem
        }
    }
    ${PromptViewItemFragmentDoc}
`

/**
 * __useGetLlmReplyPromptQuery__
 *
 * To run a query within a React component, call `useGetLlmReplyPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmReplyPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmReplyPromptQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetLlmReplyPromptQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLlmReplyPromptQuery,
        GetLlmReplyPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetLlmReplyPromptQuery,
        GetLlmReplyPromptQueryVariables
    >(GetLlmReplyPromptDocument, options)
}
export function useGetLlmReplyPromptLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLlmReplyPromptQuery,
        GetLlmReplyPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLlmReplyPromptQuery,
        GetLlmReplyPromptQueryVariables
    >(GetLlmReplyPromptDocument, options)
}
export type GetLlmReplyPromptQueryHookResult = ReturnType<
    typeof useGetLlmReplyPromptQuery
>
export type GetLlmReplyPromptLazyQueryHookResult = ReturnType<
    typeof useGetLlmReplyPromptLazyQuery
>
export type GetLlmReplyPromptQueryResult = Apollo.QueryResult<
    GetLlmReplyPromptQuery,
    GetLlmReplyPromptQueryVariables
>
export const GetAccountDocument = gql`
    query getAccount($accountId: Int!) {
        getAccount(accountId: $accountId) {
            ...AccountRecordItem
        }
    }
    ${AccountRecordItemFragmentDoc}
`

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAccountQuery,
        GetAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(
        GetAccountDocument,
        options
    )
}
export function useGetAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAccountQuery,
        GetAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(
        GetAccountDocument,
        options
    )
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>
export type GetAccountLazyQueryHookResult = ReturnType<
    typeof useGetAccountLazyQuery
>
export type GetAccountQueryResult = Apollo.QueryResult<
    GetAccountQuery,
    GetAccountQueryVariables
>
export const GetAccountsForMeDocument = gql`
    query getAccountsForMe {
        getAccountsForMe {
            ...AccountRecordItem
        }
    }
    ${AccountRecordItemFragmentDoc}
`

/**
 * __useGetAccountsForMeQuery__
 *
 * To run a query within a React component, call `useGetAccountsForMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsForMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsForMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsForMeQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAccountsForMeQuery,
        GetAccountsForMeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetAccountsForMeQuery,
        GetAccountsForMeQueryVariables
    >(GetAccountsForMeDocument, options)
}
export function useGetAccountsForMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAccountsForMeQuery,
        GetAccountsForMeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetAccountsForMeQuery,
        GetAccountsForMeQueryVariables
    >(GetAccountsForMeDocument, options)
}
export type GetAccountsForMeQueryHookResult = ReturnType<
    typeof useGetAccountsForMeQuery
>
export type GetAccountsForMeLazyQueryHookResult = ReturnType<
    typeof useGetAccountsForMeLazyQuery
>
export type GetAccountsForMeQueryResult = Apollo.QueryResult<
    GetAccountsForMeQuery,
    GetAccountsForMeQueryVariables
>
export const GetAccountsInTeamDocument = gql`
    query getAccountsInTeam($params: GetAccountsInTeamParamsView!) {
        getAccountsInTeam(params: $params) {
            ...AccountRecordItem
        }
    }
    ${AccountRecordItemFragmentDoc}
`

/**
 * __useGetAccountsInTeamQuery__
 *
 * To run a query within a React component, call `useGetAccountsInTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsInTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsInTeamQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetAccountsInTeamQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAccountsInTeamQuery,
        GetAccountsInTeamQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetAccountsInTeamQuery,
        GetAccountsInTeamQueryVariables
    >(GetAccountsInTeamDocument, options)
}
export function useGetAccountsInTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAccountsInTeamQuery,
        GetAccountsInTeamQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetAccountsInTeamQuery,
        GetAccountsInTeamQueryVariables
    >(GetAccountsInTeamDocument, options)
}
export type GetAccountsInTeamQueryHookResult = ReturnType<
    typeof useGetAccountsInTeamQuery
>
export type GetAccountsInTeamLazyQueryHookResult = ReturnType<
    typeof useGetAccountsInTeamLazyQuery
>
export type GetAccountsInTeamQueryResult = Apollo.QueryResult<
    GetAccountsInTeamQuery,
    GetAccountsInTeamQueryVariables
>
export const GetKeywordsForAccountDocument = gql`
    query getKeywordsForAccount($params: KeywordParamsList!) {
        getKeywordsForAccount(params: $params) {
            id
            keyword
            regex
            allowableRegex
            description
            accountId
            classifierDetailsDisplayName
            classifierDetailsId
            createdAt
        }
    }
`

/**
 * __useGetKeywordsForAccountQuery__
 *
 * To run a query within a React component, call `useGetKeywordsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsForAccountQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetKeywordsForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetKeywordsForAccountQuery,
        GetKeywordsForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetKeywordsForAccountQuery,
        GetKeywordsForAccountQueryVariables
    >(GetKeywordsForAccountDocument, options)
}
export function useGetKeywordsForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetKeywordsForAccountQuery,
        GetKeywordsForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetKeywordsForAccountQuery,
        GetKeywordsForAccountQueryVariables
    >(GetKeywordsForAccountDocument, options)
}
export type GetKeywordsForAccountQueryHookResult = ReturnType<
    typeof useGetKeywordsForAccountQuery
>
export type GetKeywordsForAccountLazyQueryHookResult = ReturnType<
    typeof useGetKeywordsForAccountLazyQuery
>
export type GetKeywordsForAccountQueryResult = Apollo.QueryResult<
    GetKeywordsForAccountQuery,
    GetKeywordsForAccountQueryVariables
>
export const GetUserByIdDocument = gql`
    query getUserById($id: Int!) {
        getUserById(id: $id) {
            ...UserItem
        }
    }
    ${UserItemFragmentDoc}
`

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUserByIdQuery,
        GetUserByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
        GetUserByIdDocument,
        options
    )
}
export function useGetUserByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserByIdQuery,
        GetUserByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
        GetUserByIdDocument,
        options
    )
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>
export type GetUserByIdLazyQueryHookResult = ReturnType<
    typeof useGetUserByIdLazyQuery
>
export type GetUserByIdQueryResult = Apollo.QueryResult<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
>
export const GetUsersByTeamIdDocument = gql`
    query getUsersByTeamId($teamId: Int!) {
        getUsersByTeamId(teamId: $teamId) {
            ...UserItem
        }
    }
    ${UserItemFragmentDoc}
`

/**
 * __useGetUsersByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetUsersByTeamIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUsersByTeamIdQuery,
        GetUsersByTeamIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetUsersByTeamIdQuery,
        GetUsersByTeamIdQueryVariables
    >(GetUsersByTeamIdDocument, options)
}
export function useGetUsersByTeamIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUsersByTeamIdQuery,
        GetUsersByTeamIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetUsersByTeamIdQuery,
        GetUsersByTeamIdQueryVariables
    >(GetUsersByTeamIdDocument, options)
}
export type GetUsersByTeamIdQueryHookResult = ReturnType<
    typeof useGetUsersByTeamIdQuery
>
export type GetUsersByTeamIdLazyQueryHookResult = ReturnType<
    typeof useGetUsersByTeamIdLazyQuery
>
export type GetUsersByTeamIdQueryResult = Apollo.QueryResult<
    GetUsersByTeamIdQuery,
    GetUsersByTeamIdQueryVariables
>
export const GetUserTeamsDocument = gql`
    query getUserTeams($userId: Int!) {
        getUserTeams(userId: $userId) {
            ...TeamItem
        }
    }
    ${TeamItemFragmentDoc}
`

/**
 * __useGetUserTeamsQuery__
 *
 * To run a query within a React component, call `useGetUserTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTeamsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserTeamsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUserTeamsQuery,
        GetUserTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
        GetUserTeamsDocument,
        options
    )
}
export function useGetUserTeamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserTeamsQuery,
        GetUserTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
        GetUserTeamsDocument,
        options
    )
}
export type GetUserTeamsQueryHookResult = ReturnType<
    typeof useGetUserTeamsQuery
>
export type GetUserTeamsLazyQueryHookResult = ReturnType<
    typeof useGetUserTeamsLazyQuery
>
export type GetUserTeamsQueryResult = Apollo.QueryResult<
    GetUserTeamsQuery,
    GetUserTeamsQueryVariables
>
export const GetUserIfAllowedDocument = gql`
    query getUserIfAllowed($id: Int!) {
        getUserIfAllowed(id: $id) {
            ...UserItem
        }
    }
    ${UserItemFragmentDoc}
`

/**
 * __useGetUserIfAllowedQuery__
 *
 * To run a query within a React component, call `useGetUserIfAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIfAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIfAllowedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserIfAllowedQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUserIfAllowedQuery,
        GetUserIfAllowedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetUserIfAllowedQuery,
        GetUserIfAllowedQueryVariables
    >(GetUserIfAllowedDocument, options)
}
export function useGetUserIfAllowedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserIfAllowedQuery,
        GetUserIfAllowedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetUserIfAllowedQuery,
        GetUserIfAllowedQueryVariables
    >(GetUserIfAllowedDocument, options)
}
export type GetUserIfAllowedQueryHookResult = ReturnType<
    typeof useGetUserIfAllowedQuery
>
export type GetUserIfAllowedLazyQueryHookResult = ReturnType<
    typeof useGetUserIfAllowedLazyQuery
>
export type GetUserIfAllowedQueryResult = Apollo.QueryResult<
    GetUserIfAllowedQuery,
    GetUserIfAllowedQueryVariables
>
export const SearchUsersDocument = gql`
    query searchUsers($params: UserSearchParams!) {
        searchUsers(params: $params) {
            ...UserItem
        }
    }
    ${UserItemFragmentDoc}
`

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchUsersQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchUsersQuery,
        SearchUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(
        SearchUsersDocument,
        options
    )
}
export function useSearchUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchUsersQuery,
        SearchUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(
        SearchUsersDocument,
        options
    )
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>
export type SearchUsersLazyQueryHookResult = ReturnType<
    typeof useSearchUsersLazyQuery
>
export type SearchUsersQueryResult = Apollo.QueryResult<
    SearchUsersQuery,
    SearchUsersQueryVariables
>
export const SearchUsersCountDocument = gql`
    query searchUsersCount($params: UserSearchParams!) {
        searchUsersCount(params: $params)
    }
`

/**
 * __useSearchUsersCountQuery__
 *
 * To run a query within a React component, call `useSearchUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersCountQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchUsersCountQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchUsersCountQuery,
        SearchUsersCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        SearchUsersCountQuery,
        SearchUsersCountQueryVariables
    >(SearchUsersCountDocument, options)
}
export function useSearchUsersCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchUsersCountQuery,
        SearchUsersCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        SearchUsersCountQuery,
        SearchUsersCountQueryVariables
    >(SearchUsersCountDocument, options)
}
export type SearchUsersCountQueryHookResult = ReturnType<
    typeof useSearchUsersCountQuery
>
export type SearchUsersCountLazyQueryHookResult = ReturnType<
    typeof useSearchUsersCountLazyQuery
>
export type SearchUsersCountQueryResult = Apollo.QueryResult<
    SearchUsersCountQuery,
    SearchUsersCountQueryVariables
>
export const GetCurrentAuthorisationValidityDocument = gql`
    query getCurrentAuthorisationValidity($accountId: Int!) {
        getCurrentAuthorisationValidity(accountId: $accountId)
    }
`

/**
 * __useGetCurrentAuthorisationValidityQuery__
 *
 * To run a query within a React component, call `useGetCurrentAuthorisationValidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAuthorisationValidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAuthorisationValidityQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCurrentAuthorisationValidityQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetCurrentAuthorisationValidityQuery,
        GetCurrentAuthorisationValidityQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetCurrentAuthorisationValidityQuery,
        GetCurrentAuthorisationValidityQueryVariables
    >(GetCurrentAuthorisationValidityDocument, options)
}
export function useGetCurrentAuthorisationValidityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCurrentAuthorisationValidityQuery,
        GetCurrentAuthorisationValidityQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetCurrentAuthorisationValidityQuery,
        GetCurrentAuthorisationValidityQueryVariables
    >(GetCurrentAuthorisationValidityDocument, options)
}
export type GetCurrentAuthorisationValidityQueryHookResult = ReturnType<
    typeof useGetCurrentAuthorisationValidityQuery
>
export type GetCurrentAuthorisationValidityLazyQueryHookResult = ReturnType<
    typeof useGetCurrentAuthorisationValidityLazyQuery
>
export type GetCurrentAuthorisationValidityQueryResult = Apollo.QueryResult<
    GetCurrentAuthorisationValidityQuery,
    GetCurrentAuthorisationValidityQueryVariables
>
export const CheckMetaSubscriptionForAccountDocument = gql`
    query checkMetaSubscriptionForAccount($accountId: Int!) {
        checkMetaSubscriptionForAccount(accountId: $accountId)
    }
`

/**
 * __useCheckMetaSubscriptionForAccountQuery__
 *
 * To run a query within a React component, call `useCheckMetaSubscriptionForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckMetaSubscriptionForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckMetaSubscriptionForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCheckMetaSubscriptionForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        CheckMetaSubscriptionForAccountQuery,
        CheckMetaSubscriptionForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        CheckMetaSubscriptionForAccountQuery,
        CheckMetaSubscriptionForAccountQueryVariables
    >(CheckMetaSubscriptionForAccountDocument, options)
}
export function useCheckMetaSubscriptionForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CheckMetaSubscriptionForAccountQuery,
        CheckMetaSubscriptionForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        CheckMetaSubscriptionForAccountQuery,
        CheckMetaSubscriptionForAccountQueryVariables
    >(CheckMetaSubscriptionForAccountDocument, options)
}
export type CheckMetaSubscriptionForAccountQueryHookResult = ReturnType<
    typeof useCheckMetaSubscriptionForAccountQuery
>
export type CheckMetaSubscriptionForAccountLazyQueryHookResult = ReturnType<
    typeof useCheckMetaSubscriptionForAccountLazyQuery
>
export type CheckMetaSubscriptionForAccountQueryResult = Apollo.QueryResult<
    CheckMetaSubscriptionForAccountQuery,
    CheckMetaSubscriptionForAccountQueryVariables
>
export const CreateMetaSubscriptionForAccountDocument = gql`
    query createMetaSubscriptionForAccount($accountId: Int!) {
        createMetaSubscriptionForAccount(accountId: $accountId)
    }
`

/**
 * __useCreateMetaSubscriptionForAccountQuery__
 *
 * To run a query within a React component, call `useCreateMetaSubscriptionForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMetaSubscriptionForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMetaSubscriptionForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreateMetaSubscriptionForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        CreateMetaSubscriptionForAccountQuery,
        CreateMetaSubscriptionForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        CreateMetaSubscriptionForAccountQuery,
        CreateMetaSubscriptionForAccountQueryVariables
    >(CreateMetaSubscriptionForAccountDocument, options)
}
export function useCreateMetaSubscriptionForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CreateMetaSubscriptionForAccountQuery,
        CreateMetaSubscriptionForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        CreateMetaSubscriptionForAccountQuery,
        CreateMetaSubscriptionForAccountQueryVariables
    >(CreateMetaSubscriptionForAccountDocument, options)
}
export type CreateMetaSubscriptionForAccountQueryHookResult = ReturnType<
    typeof useCreateMetaSubscriptionForAccountQuery
>
export type CreateMetaSubscriptionForAccountLazyQueryHookResult = ReturnType<
    typeof useCreateMetaSubscriptionForAccountLazyQuery
>
export type CreateMetaSubscriptionForAccountQueryResult = Apollo.QueryResult<
    CreateMetaSubscriptionForAccountQuery,
    CreateMetaSubscriptionForAccountQueryVariables
>
export const DebugMetaAuthorisationForAccountDocument = gql`
    query debugMetaAuthorisationForAccount($accountId: Int!) {
        debugMetaAuthorisationForAccount(accountId: $accountId) {
            ...DebugTokenResponseViewItem
        }
    }
    ${DebugTokenResponseViewItemFragmentDoc}
`

/**
 * __useDebugMetaAuthorisationForAccountQuery__
 *
 * To run a query within a React component, call `useDebugMetaAuthorisationForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebugMetaAuthorisationForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebugMetaAuthorisationForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDebugMetaAuthorisationForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        DebugMetaAuthorisationForAccountQuery,
        DebugMetaAuthorisationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        DebugMetaAuthorisationForAccountQuery,
        DebugMetaAuthorisationForAccountQueryVariables
    >(DebugMetaAuthorisationForAccountDocument, options)
}
export function useDebugMetaAuthorisationForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DebugMetaAuthorisationForAccountQuery,
        DebugMetaAuthorisationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        DebugMetaAuthorisationForAccountQuery,
        DebugMetaAuthorisationForAccountQueryVariables
    >(DebugMetaAuthorisationForAccountDocument, options)
}
export type DebugMetaAuthorisationForAccountQueryHookResult = ReturnType<
    typeof useDebugMetaAuthorisationForAccountQuery
>
export type DebugMetaAuthorisationForAccountLazyQueryHookResult = ReturnType<
    typeof useDebugMetaAuthorisationForAccountLazyQuery
>
export type DebugMetaAuthorisationForAccountQueryResult = Apollo.QueryResult<
    DebugMetaAuthorisationForAccountQuery,
    DebugMetaAuthorisationForAccountQueryVariables
>
export const CheckMetaAuthorisationForAccountDocument = gql`
    query checkMetaAuthorisationForAccount($accountId: Int!) {
        checkMetaAuthorisationForAccount(accountId: $accountId)
    }
`

/**
 * __useCheckMetaAuthorisationForAccountQuery__
 *
 * To run a query within a React component, call `useCheckMetaAuthorisationForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckMetaAuthorisationForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckMetaAuthorisationForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCheckMetaAuthorisationForAccountQuery(
    baseOptions: Apollo.QueryHookOptions<
        CheckMetaAuthorisationForAccountQuery,
        CheckMetaAuthorisationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        CheckMetaAuthorisationForAccountQuery,
        CheckMetaAuthorisationForAccountQueryVariables
    >(CheckMetaAuthorisationForAccountDocument, options)
}
export function useCheckMetaAuthorisationForAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CheckMetaAuthorisationForAccountQuery,
        CheckMetaAuthorisationForAccountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        CheckMetaAuthorisationForAccountQuery,
        CheckMetaAuthorisationForAccountQueryVariables
    >(CheckMetaAuthorisationForAccountDocument, options)
}
export type CheckMetaAuthorisationForAccountQueryHookResult = ReturnType<
    typeof useCheckMetaAuthorisationForAccountQuery
>
export type CheckMetaAuthorisationForAccountLazyQueryHookResult = ReturnType<
    typeof useCheckMetaAuthorisationForAccountLazyQuery
>
export type CheckMetaAuthorisationForAccountQueryResult = Apollo.QueryResult<
    CheckMetaAuthorisationForAccountQuery,
    CheckMetaAuthorisationForAccountQueryVariables
>
export const GetUsersPermissionsDocument = gql`
    query getUsersPermissions($usernames: [String!]!) {
        getUserPermissions(usernames: $usernames) {
            userPermissions
        }
    }
`

/**
 * __useGetUsersPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUsersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPermissionsQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useGetUsersPermissionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUsersPermissionsQuery,
        GetUsersPermissionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetUsersPermissionsQuery,
        GetUsersPermissionsQueryVariables
    >(GetUsersPermissionsDocument, options)
}
export function useGetUsersPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUsersPermissionsQuery,
        GetUsersPermissionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetUsersPermissionsQuery,
        GetUsersPermissionsQueryVariables
    >(GetUsersPermissionsDocument, options)
}
export type GetUsersPermissionsQueryHookResult = ReturnType<
    typeof useGetUsersPermissionsQuery
>
export type GetUsersPermissionsLazyQueryHookResult = ReturnType<
    typeof useGetUsersPermissionsLazyQuery
>
export type GetUsersPermissionsQueryResult = Apollo.QueryResult<
    GetUsersPermissionsQuery,
    GetUsersPermissionsQueryVariables
>
export const GetTeamByIdDocument = gql`
    query getTeamById($teamId: Int!) {
        getTeamById(teamId: $teamId) {
            ...TeamItem
        }
    }
    ${TeamItemFragmentDoc}
`

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTeamByIdQuery,
        GetTeamByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(
        GetTeamByIdDocument,
        options
    )
}
export function useGetTeamByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTeamByIdQuery,
        GetTeamByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(
        GetTeamByIdDocument,
        options
    )
}
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>
export type GetTeamByIdLazyQueryHookResult = ReturnType<
    typeof useGetTeamByIdLazyQuery
>
export type GetTeamByIdQueryResult = Apollo.QueryResult<
    GetTeamByIdQuery,
    GetTeamByIdQueryVariables
>
export const GetTeamsDocument = gql`
    query getTeams {
        getTeams {
            ...TeamItem
        }
    }
    ${TeamItemFragmentDoc}
`

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(
        GetTeamsDocument,
        options
    )
}
export function useGetTeamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTeamsQuery,
        GetTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(
        GetTeamsDocument,
        options
    )
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>
export type GetTeamsLazyQueryHookResult = ReturnType<
    typeof useGetTeamsLazyQuery
>
export type GetTeamsQueryResult = Apollo.QueryResult<
    GetTeamsQuery,
    GetTeamsQueryVariables
>
export const GetOauthUrlDocument = gql`
    query getOauthUrl($socialMediaService: String!, $baseUrl: String!) {
        getOauthUrl(socialMediaService: $socialMediaService, baseUrl: $baseUrl)
    }
`

/**
 * __useGetOauthUrlQuery__
 *
 * To run a query within a React component, call `useGetOauthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOauthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOauthUrlQuery({
 *   variables: {
 *      socialMediaService: // value for 'socialMediaService'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useGetOauthUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetOauthUrlQuery,
        GetOauthUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetOauthUrlQuery, GetOauthUrlQueryVariables>(
        GetOauthUrlDocument,
        options
    )
}
export function useGetOauthUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetOauthUrlQuery,
        GetOauthUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetOauthUrlQuery, GetOauthUrlQueryVariables>(
        GetOauthUrlDocument,
        options
    )
}
export type GetOauthUrlQueryHookResult = ReturnType<typeof useGetOauthUrlQuery>
export type GetOauthUrlLazyQueryHookResult = ReturnType<
    typeof useGetOauthUrlLazyQuery
>
export type GetOauthUrlQueryResult = Apollo.QueryResult<
    GetOauthUrlQuery,
    GetOauthUrlQueryVariables
>
export const SearchTeamsDocument = gql`
    query searchTeams($params: TeamsSearchParamsView!) {
        searchTeams(params: $params) {
            ...TeamItem
        }
    }
    ${TeamItemFragmentDoc}
`

/**
 * __useSearchTeamsQuery__
 *
 * To run a query within a React component, call `useSearchTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTeamsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchTeamsQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchTeamsQuery,
        SearchTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<SearchTeamsQuery, SearchTeamsQueryVariables>(
        SearchTeamsDocument,
        options
    )
}
export function useSearchTeamsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchTeamsQuery,
        SearchTeamsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<SearchTeamsQuery, SearchTeamsQueryVariables>(
        SearchTeamsDocument,
        options
    )
}
export type SearchTeamsQueryHookResult = ReturnType<typeof useSearchTeamsQuery>
export type SearchTeamsLazyQueryHookResult = ReturnType<
    typeof useSearchTeamsLazyQuery
>
export type SearchTeamsQueryResult = Apollo.QueryResult<
    SearchTeamsQuery,
    SearchTeamsQueryVariables
>
export const GetProfilesByIdsDocument = gql`
    query getProfilesByIds($profileIds: [Int!]!) {
        getProfilesByIds(profileIds: $profileIds) {
            ...ProfileItem
        }
    }
    ${ProfileItemFragmentDoc}
`

/**
 * __useGetProfilesByIdsQuery__
 *
 * To run a query within a React component, call `useGetProfilesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesByIdsQuery({
 *   variables: {
 *      profileIds: // value for 'profileIds'
 *   },
 * });
 */
export function useGetProfilesByIdsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetProfilesByIdsQuery,
        GetProfilesByIdsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetProfilesByIdsQuery,
        GetProfilesByIdsQueryVariables
    >(GetProfilesByIdsDocument, options)
}
export function useGetProfilesByIdsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProfilesByIdsQuery,
        GetProfilesByIdsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetProfilesByIdsQuery,
        GetProfilesByIdsQueryVariables
    >(GetProfilesByIdsDocument, options)
}
export type GetProfilesByIdsQueryHookResult = ReturnType<
    typeof useGetProfilesByIdsQuery
>
export type GetProfilesByIdsLazyQueryHookResult = ReturnType<
    typeof useGetProfilesByIdsLazyQuery
>
export type GetProfilesByIdsQueryResult = Apollo.QueryResult<
    GetProfilesByIdsQuery,
    GetProfilesByIdsQueryVariables
>
export const GetProfileDocument = gql`
    query getProfile($contentId: Int!) {
        getProfile(contentId: $contentId) {
            ...ProfileItem
        }
    }
    ${ProfileItemFragmentDoc}
`

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetProfileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export function useGetProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<
    typeof useGetProfileLazyQuery
>
export type GetProfileQueryResult = Apollo.QueryResult<
    GetProfileQuery,
    GetProfileQueryVariables
>
export const SearchProfilesDocument = gql`
    query searchProfiles(
        $filter: ProfileFilterInput
        $sort: ProfileSortInput
        $limit: Int!
        $offset: Int!
    ) {
        searchProfiles(
            filter: $filter
            sort: $sort
            limit: $limit
            offset: $offset
        ) {
            ...ProfileItem
        }
    }
    ${ProfileItemFragmentDoc}
`

/**
 * __useSearchProfilesQuery__
 *
 * To run a query within a React component, call `useSearchProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProfilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchProfilesQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchProfilesQuery,
        SearchProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<SearchProfilesQuery, SearchProfilesQueryVariables>(
        SearchProfilesDocument,
        options
    )
}
export function useSearchProfilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchProfilesQuery,
        SearchProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        SearchProfilesQuery,
        SearchProfilesQueryVariables
    >(SearchProfilesDocument, options)
}
export type SearchProfilesQueryHookResult = ReturnType<
    typeof useSearchProfilesQuery
>
export type SearchProfilesLazyQueryHookResult = ReturnType<
    typeof useSearchProfilesLazyQuery
>
export type SearchProfilesQueryResult = Apollo.QueryResult<
    SearchProfilesQuery,
    SearchProfilesQueryVariables
>
export const CountProfilesDocument = gql`
    query countProfiles($filter: ProfileFilterInput) {
        countProfiles(filter: $filter)
    }
`

/**
 * __useCountProfilesQuery__
 *
 * To run a query within a React component, call `useCountProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountProfilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountProfilesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CountProfilesQuery,
        CountProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CountProfilesQuery, CountProfilesQueryVariables>(
        CountProfilesDocument,
        options
    )
}
export function useCountProfilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CountProfilesQuery,
        CountProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<CountProfilesQuery, CountProfilesQueryVariables>(
        CountProfilesDocument,
        options
    )
}
export type CountProfilesQueryHookResult = ReturnType<
    typeof useCountProfilesQuery
>
export type CountProfilesLazyQueryHookResult = ReturnType<
    typeof useCountProfilesLazyQuery
>
export type CountProfilesQueryResult = Apollo.QueryResult<
    CountProfilesQuery,
    CountProfilesQueryVariables
>
export const SearchAccountsDocument = gql`
    query searchAccounts($params: SearchAccountsParamsView!) {
        searchAccounts(params: $params) {
            accounts {
                ...AccountRecordItem
            }
            total
        }
    }
    ${AccountRecordItemFragmentDoc}
`

/**
 * __useSearchAccountsQuery__
 *
 * To run a query within a React component, call `useSearchAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAccountsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchAccountsQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchAccountsQuery,
        SearchAccountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<SearchAccountsQuery, SearchAccountsQueryVariables>(
        SearchAccountsDocument,
        options
    )
}
export function useSearchAccountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchAccountsQuery,
        SearchAccountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        SearchAccountsQuery,
        SearchAccountsQueryVariables
    >(SearchAccountsDocument, options)
}
export type SearchAccountsQueryHookResult = ReturnType<
    typeof useSearchAccountsQuery
>
export type SearchAccountsLazyQueryHookResult = ReturnType<
    typeof useSearchAccountsLazyQuery
>
export type SearchAccountsQueryResult = Apollo.QueryResult<
    SearchAccountsQuery,
    SearchAccountsQueryVariables
>
export const GetSummaryForAccountsDocument = gql`
    query getSummaryForAccounts($params: GetSummaryMetricsParams!) {
        getSummaryForAccounts(params: $params) {
            ...ContentSummaryItem
        }
    }
    ${ContentSummaryItemFragmentDoc}
`

/**
 * __useGetSummaryForAccountsQuery__
 *
 * To run a query within a React component, call `useGetSummaryForAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSummaryForAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSummaryForAccountsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSummaryForAccountsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetSummaryForAccountsQuery,
        GetSummaryForAccountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetSummaryForAccountsQuery,
        GetSummaryForAccountsQueryVariables
    >(GetSummaryForAccountsDocument, options)
}
export function useGetSummaryForAccountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSummaryForAccountsQuery,
        GetSummaryForAccountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetSummaryForAccountsQuery,
        GetSummaryForAccountsQueryVariables
    >(GetSummaryForAccountsDocument, options)
}
export type GetSummaryForAccountsQueryHookResult = ReturnType<
    typeof useGetSummaryForAccountsQuery
>
export type GetSummaryForAccountsLazyQueryHookResult = ReturnType<
    typeof useGetSummaryForAccountsLazyQuery
>
export type GetSummaryForAccountsQueryResult = Apollo.QueryResult<
    GetSummaryForAccountsQuery,
    GetSummaryForAccountsQueryVariables
>
export const SearchContentOutcomeDocument = gql`
    query searchContentOutcome($params: SearchParamsForContentOutcome!) {
        searchContentOutcome(params: $params) {
            ...ContentOutcomeViewItem
        }
    }
    ${ContentOutcomeViewItemFragmentDoc}
`

/**
 * __useSearchContentOutcomeQuery__
 *
 * To run a query within a React component, call `useSearchContentOutcomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentOutcomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentOutcomeQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchContentOutcomeQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchContentOutcomeQuery,
        SearchContentOutcomeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        SearchContentOutcomeQuery,
        SearchContentOutcomeQueryVariables
    >(SearchContentOutcomeDocument, options)
}
export function useSearchContentOutcomeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchContentOutcomeQuery,
        SearchContentOutcomeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        SearchContentOutcomeQuery,
        SearchContentOutcomeQueryVariables
    >(SearchContentOutcomeDocument, options)
}
export type SearchContentOutcomeQueryHookResult = ReturnType<
    typeof useSearchContentOutcomeQuery
>
export type SearchContentOutcomeLazyQueryHookResult = ReturnType<
    typeof useSearchContentOutcomeLazyQuery
>
export type SearchContentOutcomeQueryResult = Apollo.QueryResult<
    SearchContentOutcomeQuery,
    SearchContentOutcomeQueryVariables
>
export const SearchContentOutcomeCountDocument = gql`
    query searchContentOutcomeCount($params: SearchParamsForContentOutcome!) {
        searchContentOutcomeCount(params: $params)
    }
`

/**
 * __useSearchContentOutcomeCountQuery__
 *
 * To run a query within a React component, call `useSearchContentOutcomeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentOutcomeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentOutcomeCountQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSearchContentOutcomeCountQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchContentOutcomeCountQuery,
        SearchContentOutcomeCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        SearchContentOutcomeCountQuery,
        SearchContentOutcomeCountQueryVariables
    >(SearchContentOutcomeCountDocument, options)
}
export function useSearchContentOutcomeCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchContentOutcomeCountQuery,
        SearchContentOutcomeCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        SearchContentOutcomeCountQuery,
        SearchContentOutcomeCountQueryVariables
    >(SearchContentOutcomeCountDocument, options)
}
export type SearchContentOutcomeCountQueryHookResult = ReturnType<
    typeof useSearchContentOutcomeCountQuery
>
export type SearchContentOutcomeCountLazyQueryHookResult = ReturnType<
    typeof useSearchContentOutcomeCountLazyQuery
>
export type SearchContentOutcomeCountQueryResult = Apollo.QueryResult<
    SearchContentOutcomeCountQuery,
    SearchContentOutcomeCountQueryVariables
>
export const GetContentByIdDocument = gql`
    query getContentById($id: Int!) {
        getContentById(id: $id) {
            ...ContentOutcomeViewItem
        }
    }
    ${ContentOutcomeViewItemFragmentDoc}
`

/**
 * __useGetContentByIdQuery__
 *
 * To run a query within a React component, call `useGetContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetContentByIdQuery,
        GetContentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(
        GetContentByIdDocument,
        options
    )
}
export function useGetContentByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetContentByIdQuery,
        GetContentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetContentByIdQuery,
        GetContentByIdQueryVariables
    >(GetContentByIdDocument, options)
}
export type GetContentByIdQueryHookResult = ReturnType<
    typeof useGetContentByIdQuery
>
export type GetContentByIdLazyQueryHookResult = ReturnType<
    typeof useGetContentByIdLazyQuery
>
export type GetContentByIdQueryResult = Apollo.QueryResult<
    GetContentByIdQuery,
    GetContentByIdQueryVariables
>
export const GetContentClassifierMetadataDocument = gql`
    query getContentClassifierMetadata(
        $params: SearchParamsForContentOutcome!
    ) {
        getContentClassifierMetadata(params: $params) {
            ...ContentClassifierMetadataItem
        }
    }
    ${ContentClassifierMetadataItemFragmentDoc}
`

/**
 * __useGetContentClassifierMetadataQuery__
 *
 * To run a query within a React component, call `useGetContentClassifierMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentClassifierMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentClassifierMetadataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetContentClassifierMetadataQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetContentClassifierMetadataQuery,
        GetContentClassifierMetadataQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetContentClassifierMetadataQuery,
        GetContentClassifierMetadataQueryVariables
    >(GetContentClassifierMetadataDocument, options)
}
export function useGetContentClassifierMetadataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetContentClassifierMetadataQuery,
        GetContentClassifierMetadataQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetContentClassifierMetadataQuery,
        GetContentClassifierMetadataQueryVariables
    >(GetContentClassifierMetadataDocument, options)
}
export type GetContentClassifierMetadataQueryHookResult = ReturnType<
    typeof useGetContentClassifierMetadataQuery
>
export type GetContentClassifierMetadataLazyQueryHookResult = ReturnType<
    typeof useGetContentClassifierMetadataLazyQuery
>
export type GetContentClassifierMetadataQueryResult = Apollo.QueryResult<
    GetContentClassifierMetadataQuery,
    GetContentClassifierMetadataQueryVariables
>
export const GetHeatmapDocument = gql`
    query getHeatmap($params: SearchParamsForContentOutcome!) {
        getHeatmap(params: $params) {
            ...HeatmapItem
        }
    }
    ${HeatmapItemFragmentDoc}
`

/**
 * __useGetHeatmapQuery__
 *
 * To run a query within a React component, call `useGetHeatmapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeatmapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeatmapQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetHeatmapQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetHeatmapQuery,
        GetHeatmapQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetHeatmapQuery, GetHeatmapQueryVariables>(
        GetHeatmapDocument,
        options
    )
}
export function useGetHeatmapLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetHeatmapQuery,
        GetHeatmapQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetHeatmapQuery, GetHeatmapQueryVariables>(
        GetHeatmapDocument,
        options
    )
}
export type GetHeatmapQueryHookResult = ReturnType<typeof useGetHeatmapQuery>
export type GetHeatmapLazyQueryHookResult = ReturnType<
    typeof useGetHeatmapLazyQuery
>
export type GetHeatmapQueryResult = Apollo.QueryResult<
    GetHeatmapQuery,
    GetHeatmapQueryVariables
>
export const GetUserAuthEventsDocument = gql`
    query getUserAuthEvents($username: String!) {
        getUserAuthEvents(username: $username) {
            ...UserAuthEventItem
        }
    }
    ${UserAuthEventItemFragmentDoc}
`

/**
 * __useGetUserAuthEventsQuery__
 *
 * To run a query within a React component, call `useGetUserAuthEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuthEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuthEventsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserAuthEventsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUserAuthEventsQuery,
        GetUserAuthEventsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetUserAuthEventsQuery,
        GetUserAuthEventsQueryVariables
    >(GetUserAuthEventsDocument, options)
}
export function useGetUserAuthEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserAuthEventsQuery,
        GetUserAuthEventsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetUserAuthEventsQuery,
        GetUserAuthEventsQueryVariables
    >(GetUserAuthEventsDocument, options)
}
export type GetUserAuthEventsQueryHookResult = ReturnType<
    typeof useGetUserAuthEventsQuery
>
export type GetUserAuthEventsLazyQueryHookResult = ReturnType<
    typeof useGetUserAuthEventsLazyQuery
>
export type GetUserAuthEventsQueryResult = Apollo.QueryResult<
    GetUserAuthEventsQuery,
    GetUserAuthEventsQueryVariables
>
export const GetLineGraphDocument = gql`
    query getLineGraph($params: SearchParamsForContentOutcome!) {
        getLineGraph(params: $params) {
            ...DateMapItem
        }
    }
    ${DateMapItemFragmentDoc}
`

/**
 * __useGetLineGraphQuery__
 *
 * To run a query within a React component, call `useGetLineGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLineGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLineGraphQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetLineGraphQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLineGraphQuery,
        GetLineGraphQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetLineGraphQuery, GetLineGraphQueryVariables>(
        GetLineGraphDocument,
        options
    )
}
export function useGetLineGraphLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLineGraphQuery,
        GetLineGraphQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetLineGraphQuery, GetLineGraphQueryVariables>(
        GetLineGraphDocument,
        options
    )
}
export type GetLineGraphQueryHookResult = ReturnType<
    typeof useGetLineGraphQuery
>
export type GetLineGraphLazyQueryHookResult = ReturnType<
    typeof useGetLineGraphLazyQuery
>
export type GetLineGraphQueryResult = Apollo.QueryResult<
    GetLineGraphQuery,
    GetLineGraphQueryVariables
>
export const DetectLanguagesDocument = gql`
    query detectLanguages($sourceTexts: [String!]!) {
        detectLanguages(sourceTexts: $sourceTexts) {
            ...DetectedLanguageItem
        }
    }
    ${DetectedLanguageItemFragmentDoc}
`

/**
 * __useDetectLanguagesQuery__
 *
 * To run a query within a React component, call `useDetectLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetectLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectLanguagesQuery({
 *   variables: {
 *      sourceTexts: // value for 'sourceTexts'
 *   },
 * });
 */
export function useDetectLanguagesQuery(
    baseOptions: Apollo.QueryHookOptions<
        DetectLanguagesQuery,
        DetectLanguagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<DetectLanguagesQuery, DetectLanguagesQueryVariables>(
        DetectLanguagesDocument,
        options
    )
}
export function useDetectLanguagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DetectLanguagesQuery,
        DetectLanguagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        DetectLanguagesQuery,
        DetectLanguagesQueryVariables
    >(DetectLanguagesDocument, options)
}
export type DetectLanguagesQueryHookResult = ReturnType<
    typeof useDetectLanguagesQuery
>
export type DetectLanguagesLazyQueryHookResult = ReturnType<
    typeof useDetectLanguagesLazyQuery
>
export type DetectLanguagesQueryResult = Apollo.QueryResult<
    DetectLanguagesQuery,
    DetectLanguagesQueryVariables
>
export const TranslateDocument = gql`
    query translate($sourceTexts: [String!]!, $targetLanguage: String!) {
        translate(sourceTexts: $sourceTexts, targetLanguage: $targetLanguage) {
            ...TranslatedTextItem
        }
    }
    ${TranslatedTextItemFragmentDoc}
`

/**
 * __useTranslateQuery__
 *
 * To run a query within a React component, call `useTranslateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateQuery({
 *   variables: {
 *      sourceTexts: // value for 'sourceTexts'
 *      targetLanguage: // value for 'targetLanguage'
 *   },
 * });
 */
export function useTranslateQuery(
    baseOptions: Apollo.QueryHookOptions<
        TranslateQuery,
        TranslateQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<TranslateQuery, TranslateQueryVariables>(
        TranslateDocument,
        options
    )
}
export function useTranslateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TranslateQuery,
        TranslateQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<TranslateQuery, TranslateQueryVariables>(
        TranslateDocument,
        options
    )
}
export type TranslateQueryHookResult = ReturnType<typeof useTranslateQuery>
export type TranslateLazyQueryHookResult = ReturnType<
    typeof useTranslateLazyQuery
>
export type TranslateQueryResult = Apollo.QueryResult<
    TranslateQuery,
    TranslateQueryVariables
>
export const CreateAccountDocument = gql`
    mutation CreateAccount(
        $serviceAccountId: String!
        $accountName: String!
        $serviceAccountUsername: String!
        $serviceName: String!
    ) {
        createAccount(
            serviceAccountId: $serviceAccountId
            accountName: $accountName
            serviceAccountUsername: $serviceAccountUsername
            serviceName: $serviceName
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type CreateAccountMutationFn = Apollo.MutationFunction<
    CreateAccountMutation,
    CreateAccountMutationVariables
>

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      serviceAccountId: // value for 'serviceAccountId'
 *      accountName: // value for 'accountName'
 *      serviceAccountUsername: // value for 'serviceAccountUsername'
 *      serviceName: // value for 'serviceName'
 *   },
 * });
 */
export function useCreateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAccountMutation,
        CreateAccountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateAccountMutation,
        CreateAccountMutationVariables
    >(CreateAccountDocument, options)
}
export type CreateAccountMutationHookResult = ReturnType<
    typeof useCreateAccountMutation
>
export type CreateAccountMutationResult =
    Apollo.MutationResult<CreateAccountMutation>
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
    CreateAccountMutation,
    CreateAccountMutationVariables
>
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($accountId: Int!, $authCode: String) {
        deleteAccount(accountId: $accountId, authCode: $authCode)
    }
`
export type DeleteAccountMutationFn = Apollo.MutationFunction<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useDeleteAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAccountMutation,
        DeleteAccountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteAccountMutation,
        DeleteAccountMutationVariables
    >(DeleteAccountDocument, options)
}
export type DeleteAccountMutationHookResult = ReturnType<
    typeof useDeleteAccountMutation
>
export type DeleteAccountMutationResult =
    Apollo.MutationResult<DeleteAccountMutation>
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
>
export const DeleteAuthorisationDocument = gql`
    mutation DeleteAuthorisation($accountId: Int!) {
        deleteAuthorisation(accountId: $accountId)
    }
`
export type DeleteAuthorisationMutationFn = Apollo.MutationFunction<
    DeleteAuthorisationMutation,
    DeleteAuthorisationMutationVariables
>

/**
 * __useDeleteAuthorisationMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorisationMutation, { data, loading, error }] = useDeleteAuthorisationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteAuthorisationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAuthorisationMutation,
        DeleteAuthorisationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteAuthorisationMutation,
        DeleteAuthorisationMutationVariables
    >(DeleteAuthorisationDocument, options)
}
export type DeleteAuthorisationMutationHookResult = ReturnType<
    typeof useDeleteAuthorisationMutation
>
export type DeleteAuthorisationMutationResult =
    Apollo.MutationResult<DeleteAuthorisationMutation>
export type DeleteAuthorisationMutationOptions = Apollo.BaseMutationOptions<
    DeleteAuthorisationMutation,
    DeleteAuthorisationMutationVariables
>
export const SetAutoMuteSevereAccountDocument = gql`
    mutation SetAutoMuteSevereAccount(
        $id: Int!
        $autoMuteSevereAccount: Boolean
    ) {
        updateAccount(id: $id, autoMuteSevereAccount: $autoMuteSevereAccount)
    }
`
export type SetAutoMuteSevereAccountMutationFn = Apollo.MutationFunction<
    SetAutoMuteSevereAccountMutation,
    SetAutoMuteSevereAccountMutationVariables
>

/**
 * __useSetAutoMuteSevereAccountMutation__
 *
 * To run a mutation, you first call `useSetAutoMuteSevereAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAutoMuteSevereAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAutoMuteSevereAccountMutation, { data, loading, error }] = useSetAutoMuteSevereAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      autoMuteSevereAccount: // value for 'autoMuteSevereAccount'
 *   },
 * });
 */
export function useSetAutoMuteSevereAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetAutoMuteSevereAccountMutation,
        SetAutoMuteSevereAccountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SetAutoMuteSevereAccountMutation,
        SetAutoMuteSevereAccountMutationVariables
    >(SetAutoMuteSevereAccountDocument, options)
}
export type SetAutoMuteSevereAccountMutationHookResult = ReturnType<
    typeof useSetAutoMuteSevereAccountMutation
>
export type SetAutoMuteSevereAccountMutationResult =
    Apollo.MutationResult<SetAutoMuteSevereAccountMutation>
export type SetAutoMuteSevereAccountMutationOptions =
    Apollo.BaseMutationOptions<
        SetAutoMuteSevereAccountMutation,
        SetAutoMuteSevereAccountMutationVariables
    >
export const SetAutoHideSevereContentDocument = gql`
    mutation SetAutoHideSevereContent(
        $id: Int!
        $autoHideSevereContent: Boolean
    ) {
        updateAccount(id: $id, autoHideSevereContent: $autoHideSevereContent)
    }
`
export type SetAutoHideSevereContentMutationFn = Apollo.MutationFunction<
    SetAutoHideSevereContentMutation,
    SetAutoHideSevereContentMutationVariables
>

/**
 * __useSetAutoHideSevereContentMutation__
 *
 * To run a mutation, you first call `useSetAutoHideSevereContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAutoHideSevereContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAutoHideSevereContentMutation, { data, loading, error }] = useSetAutoHideSevereContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      autoHideSevereContent: // value for 'autoHideSevereContent'
 *   },
 * });
 */
export function useSetAutoHideSevereContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetAutoHideSevereContentMutation,
        SetAutoHideSevereContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SetAutoHideSevereContentMutation,
        SetAutoHideSevereContentMutationVariables
    >(SetAutoHideSevereContentDocument, options)
}
export type SetAutoHideSevereContentMutationHookResult = ReturnType<
    typeof useSetAutoHideSevereContentMutation
>
export type SetAutoHideSevereContentMutationResult =
    Apollo.MutationResult<SetAutoHideSevereContentMutation>
export type SetAutoHideSevereContentMutationOptions =
    Apollo.BaseMutationOptions<
        SetAutoHideSevereContentMutation,
        SetAutoHideSevereContentMutationVariables
    >
export const SetAutoDeleteSevereContentDocument = gql`
    mutation SetAutoDeleteSevereContent(
        $id: Int!
        $autoDeleteSevereContent: Boolean
    ) {
        updateAccount(
            id: $id
            autoDeleteSevereContent: $autoDeleteSevereContent
        )
    }
`
export type SetAutoDeleteSevereContentMutationFn = Apollo.MutationFunction<
    SetAutoDeleteSevereContentMutation,
    SetAutoDeleteSevereContentMutationVariables
>

/**
 * __useSetAutoDeleteSevereContentMutation__
 *
 * To run a mutation, you first call `useSetAutoDeleteSevereContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAutoDeleteSevereContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAutoDeleteSevereContentMutation, { data, loading, error }] = useSetAutoDeleteSevereContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      autoDeleteSevereContent: // value for 'autoDeleteSevereContent'
 *   },
 * });
 */
export function useSetAutoDeleteSevereContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetAutoDeleteSevereContentMutation,
        SetAutoDeleteSevereContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SetAutoDeleteSevereContentMutation,
        SetAutoDeleteSevereContentMutationVariables
    >(SetAutoDeleteSevereContentDocument, options)
}
export type SetAutoDeleteSevereContentMutationHookResult = ReturnType<
    typeof useSetAutoDeleteSevereContentMutation
>
export type SetAutoDeleteSevereContentMutationResult =
    Apollo.MutationResult<SetAutoDeleteSevereContentMutation>
export type SetAutoDeleteSevereContentMutationOptions =
    Apollo.BaseMutationOptions<
        SetAutoDeleteSevereContentMutation,
        SetAutoDeleteSevereContentMutationVariables
    >
export const UpdateAccountDocument = gql`
    mutation UpdateAccount(
        $id: Int!
        $accountName: String
        $autoMuteSevereAccount: Boolean
        $autoHideSevereContent: Boolean
        $autoDeleteSevereContent: Boolean
    ) {
        updateAccount(
            id: $id
            accountName: $accountName
            autoMuteSevereAccount: $autoMuteSevereAccount
            autoHideSevereContent: $autoHideSevereContent
            autoDeleteSevereContent: $autoDeleteSevereContent
        )
    }
`
export type UpdateAccountMutationFn = Apollo.MutationFunction<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
>

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountName: // value for 'accountName'
 *      autoMuteSevereAccount: // value for 'autoMuteSevereAccount'
 *      autoHideSevereContent: // value for 'autoHideSevereContent'
 *      autoDeleteSevereContent: // value for 'autoDeleteSevereContent'
 *   },
 * });
 */
export function useUpdateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAccountMutation,
        UpdateAccountMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateAccountMutation,
        UpdateAccountMutationVariables
    >(UpdateAccountDocument, options)
}
export type UpdateAccountMutationHookResult = ReturnType<
    typeof useUpdateAccountMutation
>
export type UpdateAccountMutationResult =
    Apollo.MutationResult<UpdateAccountMutation>
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
>
export const SaveAuthorisationCodeDocument = gql`
    mutation SaveAuthorisationCode(
        $accountId: Int
        $socialMediaServiceName: String
        $baseUrl: String
        $authCode: String!
        $authVerifier: String
    ) {
        saveAuthorisationCode(
            accountId: $accountId
            socialMediaServiceName: $socialMediaServiceName
            baseUrl: $baseUrl
            authCode: $authCode
            authVerifier: $authVerifier
        ) {
            ...SaveAuthorisationCodeResponseItem
        }
    }
    ${SaveAuthorisationCodeResponseItemFragmentDoc}
`
export type SaveAuthorisationCodeMutationFn = Apollo.MutationFunction<
    SaveAuthorisationCodeMutation,
    SaveAuthorisationCodeMutationVariables
>

/**
 * __useSaveAuthorisationCodeMutation__
 *
 * To run a mutation, you first call `useSaveAuthorisationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAuthorisationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAuthorisationCodeMutation, { data, loading, error }] = useSaveAuthorisationCodeMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      socialMediaServiceName: // value for 'socialMediaServiceName'
 *      baseUrl: // value for 'baseUrl'
 *      authCode: // value for 'authCode'
 *      authVerifier: // value for 'authVerifier'
 *   },
 * });
 */
export function useSaveAuthorisationCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SaveAuthorisationCodeMutation,
        SaveAuthorisationCodeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SaveAuthorisationCodeMutation,
        SaveAuthorisationCodeMutationVariables
    >(SaveAuthorisationCodeDocument, options)
}
export type SaveAuthorisationCodeMutationHookResult = ReturnType<
    typeof useSaveAuthorisationCodeMutation
>
export type SaveAuthorisationCodeMutationResult =
    Apollo.MutationResult<SaveAuthorisationCodeMutation>
export type SaveAuthorisationCodeMutationOptions = Apollo.BaseMutationOptions<
    SaveAuthorisationCodeMutation,
    SaveAuthorisationCodeMutationVariables
>
export const TakeContentActionDocument = gql`
    mutation TakeContentAction(
        $contentId: Int!
        $action: ContentAction!
        $toggle: Boolean!
        $baseUrl: String
    ) {
        takeContentAction(
            contentId: $contentId
            action: $action
            toggle: $toggle
            baseUrl: $baseUrl
        ) {
            ...TakeContentActionResponseItem
        }
    }
    ${TakeContentActionResponseItemFragmentDoc}
`
export type TakeContentActionMutationFn = Apollo.MutationFunction<
    TakeContentActionMutation,
    TakeContentActionMutationVariables
>

/**
 * __useTakeContentActionMutation__
 *
 * To run a mutation, you first call `useTakeContentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeContentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeContentActionMutation, { data, loading, error }] = useTakeContentActionMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      action: // value for 'action'
 *      toggle: // value for 'toggle'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useTakeContentActionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TakeContentActionMutation,
        TakeContentActionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        TakeContentActionMutation,
        TakeContentActionMutationVariables
    >(TakeContentActionDocument, options)
}
export type TakeContentActionMutationHookResult = ReturnType<
    typeof useTakeContentActionMutation
>
export type TakeContentActionMutationResult =
    Apollo.MutationResult<TakeContentActionMutation>
export type TakeContentActionMutationOptions = Apollo.BaseMutationOptions<
    TakeContentActionMutation,
    TakeContentActionMutationVariables
>
export const UpdateAutoFetchContentDocument = gql`
    mutation UpdateAutoFetchContent(
        $accountId: Int!
        $autoFetchContent: Boolean
    ) {
        updateAutoFetchContent(
            accountId: $accountId
            autoFetchContent: $autoFetchContent
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateAutoFetchContentMutationFn = Apollo.MutationFunction<
    UpdateAutoFetchContentMutation,
    UpdateAutoFetchContentMutationVariables
>

/**
 * __useUpdateAutoFetchContentMutation__
 *
 * To run a mutation, you first call `useUpdateAutoFetchContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoFetchContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoFetchContentMutation, { data, loading, error }] = useUpdateAutoFetchContentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      autoFetchContent: // value for 'autoFetchContent'
 *   },
 * });
 */
export function useUpdateAutoFetchContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAutoFetchContentMutation,
        UpdateAutoFetchContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateAutoFetchContentMutation,
        UpdateAutoFetchContentMutationVariables
    >(UpdateAutoFetchContentDocument, options)
}
export type UpdateAutoFetchContentMutationHookResult = ReturnType<
    typeof useUpdateAutoFetchContentMutation
>
export type UpdateAutoFetchContentMutationResult =
    Apollo.MutationResult<UpdateAutoFetchContentMutation>
export type UpdateAutoFetchContentMutationOptions = Apollo.BaseMutationOptions<
    UpdateAutoFetchContentMutation,
    UpdateAutoFetchContentMutationVariables
>
export const UpdateProfileCollectionEnabledDocument = gql`
    mutation UpdateProfileCollectionEnabled(
        $accountId: Int!
        $profileCollectionEnabled: Boolean
    ) {
        updateProfileCollectionEnabled(
            accountId: $accountId
            profileCollectionEnabled: $profileCollectionEnabled
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateProfileCollectionEnabledMutationFn = Apollo.MutationFunction<
    UpdateProfileCollectionEnabledMutation,
    UpdateProfileCollectionEnabledMutationVariables
>

/**
 * __useUpdateProfileCollectionEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateProfileCollectionEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileCollectionEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileCollectionEnabledMutation, { data, loading, error }] = useUpdateProfileCollectionEnabledMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      profileCollectionEnabled: // value for 'profileCollectionEnabled'
 *   },
 * });
 */
export function useUpdateProfileCollectionEnabledMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProfileCollectionEnabledMutation,
        UpdateProfileCollectionEnabledMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateProfileCollectionEnabledMutation,
        UpdateProfileCollectionEnabledMutationVariables
    >(UpdateProfileCollectionEnabledDocument, options)
}
export type UpdateProfileCollectionEnabledMutationHookResult = ReturnType<
    typeof useUpdateProfileCollectionEnabledMutation
>
export type UpdateProfileCollectionEnabledMutationResult =
    Apollo.MutationResult<UpdateProfileCollectionEnabledMutation>
export type UpdateProfileCollectionEnabledMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateProfileCollectionEnabledMutation,
        UpdateProfileCollectionEnabledMutationVariables
    >
export const UpdateProfileModerationEnabledDocument = gql`
    mutation UpdateProfileModerationEnabled(
        $accountId: Int!
        $profileModerationEnabled: Boolean
    ) {
        updateProfileModerationEnabled(
            accountId: $accountId
            profileModerationEnabled: $profileModerationEnabled
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateProfileModerationEnabledMutationFn = Apollo.MutationFunction<
    UpdateProfileModerationEnabledMutation,
    UpdateProfileModerationEnabledMutationVariables
>

/**
 * __useUpdateProfileModerationEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateProfileModerationEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileModerationEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileModerationEnabledMutation, { data, loading, error }] = useUpdateProfileModerationEnabledMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      profileModerationEnabled: // value for 'profileModerationEnabled'
 *   },
 * });
 */
export function useUpdateProfileModerationEnabledMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProfileModerationEnabledMutation,
        UpdateProfileModerationEnabledMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateProfileModerationEnabledMutation,
        UpdateProfileModerationEnabledMutationVariables
    >(UpdateProfileModerationEnabledDocument, options)
}
export type UpdateProfileModerationEnabledMutationHookResult = ReturnType<
    typeof useUpdateProfileModerationEnabledMutation
>
export type UpdateProfileModerationEnabledMutationResult =
    Apollo.MutationResult<UpdateProfileModerationEnabledMutation>
export type UpdateProfileModerationEnabledMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateProfileModerationEnabledMutation,
        UpdateProfileModerationEnabledMutationVariables
    >
export const UpdateLlmClassificationEnabledDocument = gql`
    mutation UpdateLlmClassificationEnabled(
        $accountId: Int!
        $llmClassificationEnabled: Boolean
    ) {
        updateLlmClassificationEnabled(
            accountId: $accountId
            llmClassificationEnabled: $llmClassificationEnabled
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateLlmClassificationEnabledMutationFn = Apollo.MutationFunction<
    UpdateLlmClassificationEnabledMutation,
    UpdateLlmClassificationEnabledMutationVariables
>

/**
 * __useUpdateLlmClassificationEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateLlmClassificationEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLlmClassificationEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLlmClassificationEnabledMutation, { data, loading, error }] = useUpdateLlmClassificationEnabledMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      llmClassificationEnabled: // value for 'llmClassificationEnabled'
 *   },
 * });
 */
export function useUpdateLlmClassificationEnabledMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateLlmClassificationEnabledMutation,
        UpdateLlmClassificationEnabledMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateLlmClassificationEnabledMutation,
        UpdateLlmClassificationEnabledMutationVariables
    >(UpdateLlmClassificationEnabledDocument, options)
}
export type UpdateLlmClassificationEnabledMutationHookResult = ReturnType<
    typeof useUpdateLlmClassificationEnabledMutation
>
export type UpdateLlmClassificationEnabledMutationResult =
    Apollo.MutationResult<UpdateLlmClassificationEnabledMutation>
export type UpdateLlmClassificationEnabledMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateLlmClassificationEnabledMutation,
        UpdateLlmClassificationEnabledMutationVariables
    >
export const UpdateLlmSuggestReplyEnabledDocument = gql`
    mutation UpdateLlmSuggestReplyEnabled(
        $accountId: Int!
        $llmSuggestedReplyEnabled: Boolean
    ) {
        updateLlmSuggestedReplyEnabled(
            accountId: $accountId
            llmSuggestedReplyEnabled: $llmSuggestedReplyEnabled
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateLlmSuggestReplyEnabledMutationFn = Apollo.MutationFunction<
    UpdateLlmSuggestReplyEnabledMutation,
    UpdateLlmSuggestReplyEnabledMutationVariables
>

/**
 * __useUpdateLlmSuggestReplyEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateLlmSuggestReplyEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLlmSuggestReplyEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLlmSuggestReplyEnabledMutation, { data, loading, error }] = useUpdateLlmSuggestReplyEnabledMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      llmSuggestedReplyEnabled: // value for 'llmSuggestedReplyEnabled'
 *   },
 * });
 */
export function useUpdateLlmSuggestReplyEnabledMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateLlmSuggestReplyEnabledMutation,
        UpdateLlmSuggestReplyEnabledMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateLlmSuggestReplyEnabledMutation,
        UpdateLlmSuggestReplyEnabledMutationVariables
    >(UpdateLlmSuggestReplyEnabledDocument, options)
}
export type UpdateLlmSuggestReplyEnabledMutationHookResult = ReturnType<
    typeof useUpdateLlmSuggestReplyEnabledMutation
>
export type UpdateLlmSuggestReplyEnabledMutationResult =
    Apollo.MutationResult<UpdateLlmSuggestReplyEnabledMutation>
export type UpdateLlmSuggestReplyEnabledMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateLlmSuggestReplyEnabledMutation,
        UpdateLlmSuggestReplyEnabledMutationVariables
    >
export const UpdateIntelligenceEnabledDocument = gql`
    mutation UpdateIntelligenceEnabled(
        $accountId: Int!
        $intelligenceEnabled: Boolean
    ) {
        updateIntelligenceEnabled(
            accountId: $accountId
            intelligenceEnabled: $intelligenceEnabled
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateIntelligenceEnabledMutationFn = Apollo.MutationFunction<
    UpdateIntelligenceEnabledMutation,
    UpdateIntelligenceEnabledMutationVariables
>

/**
 * __useUpdateIntelligenceEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateIntelligenceEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntelligenceEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntelligenceEnabledMutation, { data, loading, error }] = useUpdateIntelligenceEnabledMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      intelligenceEnabled: // value for 'intelligenceEnabled'
 *   },
 * });
 */
export function useUpdateIntelligenceEnabledMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateIntelligenceEnabledMutation,
        UpdateIntelligenceEnabledMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateIntelligenceEnabledMutation,
        UpdateIntelligenceEnabledMutationVariables
    >(UpdateIntelligenceEnabledDocument, options)
}
export type UpdateIntelligenceEnabledMutationHookResult = ReturnType<
    typeof useUpdateIntelligenceEnabledMutation
>
export type UpdateIntelligenceEnabledMutationResult =
    Apollo.MutationResult<UpdateIntelligenceEnabledMutation>
export type UpdateIntelligenceEnabledMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateIntelligenceEnabledMutation,
        UpdateIntelligenceEnabledMutationVariables
    >
export const UpdateFetchAdsDocument = gql`
    mutation UpdateFetchAds($accountId: Int!, $fetchAds: Boolean) {
        updateFetchAds(accountId: $accountId, fetchAds: $fetchAds) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateFetchAdsMutationFn = Apollo.MutationFunction<
    UpdateFetchAdsMutation,
    UpdateFetchAdsMutationVariables
>

/**
 * __useUpdateFetchAdsMutation__
 *
 * To run a mutation, you first call `useUpdateFetchAdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFetchAdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFetchAdsMutation, { data, loading, error }] = useUpdateFetchAdsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      fetchAds: // value for 'fetchAds'
 *   },
 * });
 */
export function useUpdateFetchAdsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateFetchAdsMutation,
        UpdateFetchAdsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateFetchAdsMutation,
        UpdateFetchAdsMutationVariables
    >(UpdateFetchAdsDocument, options)
}
export type UpdateFetchAdsMutationHookResult = ReturnType<
    typeof useUpdateFetchAdsMutation
>
export type UpdateFetchAdsMutationResult =
    Apollo.MutationResult<UpdateFetchAdsMutation>
export type UpdateFetchAdsMutationOptions = Apollo.BaseMutationOptions<
    UpdateFetchAdsMutation,
    UpdateFetchAdsMutationVariables
>
export const UpdateFetchPostsDocument = gql`
    mutation UpdateFetchPosts($accountId: Int!, $fetchPosts: Boolean) {
        updateFetchPosts(accountId: $accountId, fetchPosts: $fetchPosts) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateFetchPostsMutationFn = Apollo.MutationFunction<
    UpdateFetchPostsMutation,
    UpdateFetchPostsMutationVariables
>

/**
 * __useUpdateFetchPostsMutation__
 *
 * To run a mutation, you first call `useUpdateFetchPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFetchPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFetchPostsMutation, { data, loading, error }] = useUpdateFetchPostsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      fetchPosts: // value for 'fetchPosts'
 *   },
 * });
 */
export function useUpdateFetchPostsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateFetchPostsMutation,
        UpdateFetchPostsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateFetchPostsMutation,
        UpdateFetchPostsMutationVariables
    >(UpdateFetchPostsDocument, options)
}
export type UpdateFetchPostsMutationHookResult = ReturnType<
    typeof useUpdateFetchPostsMutation
>
export type UpdateFetchPostsMutationResult =
    Apollo.MutationResult<UpdateFetchPostsMutation>
export type UpdateFetchPostsMutationOptions = Apollo.BaseMutationOptions<
    UpdateFetchPostsMutation,
    UpdateFetchPostsMutationVariables
>
export const UpdateAutoHideAdCommentsDocument = gql`
    mutation UpdateAutoHideAdComments(
        $accountId: Int!
        $autoHideAdComments: Boolean
    ) {
        updateAutoHideAdComments(
            accountId: $accountId
            autoHideAdComments: $autoHideAdComments
        ) {
            ...AccountItem
        }
    }
    ${AccountItemFragmentDoc}
`
export type UpdateAutoHideAdCommentsMutationFn = Apollo.MutationFunction<
    UpdateAutoHideAdCommentsMutation,
    UpdateAutoHideAdCommentsMutationVariables
>

/**
 * __useUpdateAutoHideAdCommentsMutation__
 *
 * To run a mutation, you first call `useUpdateAutoHideAdCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoHideAdCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoHideAdCommentsMutation, { data, loading, error }] = useUpdateAutoHideAdCommentsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      autoHideAdComments: // value for 'autoHideAdComments'
 *   },
 * });
 */
export function useUpdateAutoHideAdCommentsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAutoHideAdCommentsMutation,
        UpdateAutoHideAdCommentsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateAutoHideAdCommentsMutation,
        UpdateAutoHideAdCommentsMutationVariables
    >(UpdateAutoHideAdCommentsDocument, options)
}
export type UpdateAutoHideAdCommentsMutationHookResult = ReturnType<
    typeof useUpdateAutoHideAdCommentsMutation
>
export type UpdateAutoHideAdCommentsMutationResult =
    Apollo.MutationResult<UpdateAutoHideAdCommentsMutation>
export type UpdateAutoHideAdCommentsMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateAutoHideAdCommentsMutation,
        UpdateAutoHideAdCommentsMutationVariables
    >
export const ClassifyContentDocument = gql`
    mutation ClassifyContent($reason: String) {
        classifyContent(reason: $reason)
    }
`
export type ClassifyContentMutationFn = Apollo.MutationFunction<
    ClassifyContentMutation,
    ClassifyContentMutationVariables
>

/**
 * __useClassifyContentMutation__
 *
 * To run a mutation, you first call `useClassifyContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifyContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifyContentMutation, { data, loading, error }] = useClassifyContentMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useClassifyContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ClassifyContentMutation,
        ClassifyContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        ClassifyContentMutation,
        ClassifyContentMutationVariables
    >(ClassifyContentDocument, options)
}
export type ClassifyContentMutationHookResult = ReturnType<
    typeof useClassifyContentMutation
>
export type ClassifyContentMutationResult =
    Apollo.MutationResult<ClassifyContentMutation>
export type ClassifyContentMutationOptions = Apollo.BaseMutationOptions<
    ClassifyContentMutation,
    ClassifyContentMutationVariables
>
export const ModerateContentDocument = gql`
    mutation ModerateContent($reason: String) {
        moderateContent(reason: $reason)
    }
`
export type ModerateContentMutationFn = Apollo.MutationFunction<
    ModerateContentMutation,
    ModerateContentMutationVariables
>

/**
 * __useModerateContentMutation__
 *
 * To run a mutation, you first call `useModerateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModerateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moderateContentMutation, { data, loading, error }] = useModerateContentMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useModerateContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ModerateContentMutation,
        ModerateContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        ModerateContentMutation,
        ModerateContentMutationVariables
    >(ModerateContentDocument, options)
}
export type ModerateContentMutationHookResult = ReturnType<
    typeof useModerateContentMutation
>
export type ModerateContentMutationResult =
    Apollo.MutationResult<ModerateContentMutation>
export type ModerateContentMutationOptions = Apollo.BaseMutationOptions<
    ModerateContentMutation,
    ModerateContentMutationVariables
>
export const FetchHistoricContentDocument = gql`
    mutation FetchHistoricContent($accountId: Int!, $tweetsRequested: Int!) {
        fetchHistoricContent(
            accountId: $accountId
            tweetsRequested: $tweetsRequested
        )
    }
`
export type FetchHistoricContentMutationFn = Apollo.MutationFunction<
    FetchHistoricContentMutation,
    FetchHistoricContentMutationVariables
>

/**
 * __useFetchHistoricContentMutation__
 *
 * To run a mutation, you first call `useFetchHistoricContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchHistoricContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchHistoricContentMutation, { data, loading, error }] = useFetchHistoricContentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      tweetsRequested: // value for 'tweetsRequested'
 *   },
 * });
 */
export function useFetchHistoricContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        FetchHistoricContentMutation,
        FetchHistoricContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        FetchHistoricContentMutation,
        FetchHistoricContentMutationVariables
    >(FetchHistoricContentDocument, options)
}
export type FetchHistoricContentMutationHookResult = ReturnType<
    typeof useFetchHistoricContentMutation
>
export type FetchHistoricContentMutationResult =
    Apollo.MutationResult<FetchHistoricContentMutation>
export type FetchHistoricContentMutationOptions = Apollo.BaseMutationOptions<
    FetchHistoricContentMutation,
    FetchHistoricContentMutationVariables
>
export const FetchHistoricYouTubeVideosDocument = gql`
    mutation FetchHistoricYouTubeVideos($accountId: Int!) {
        fetchHistoricYouTubeVideos(accountId: $accountId)
    }
`
export type FetchHistoricYouTubeVideosMutationFn = Apollo.MutationFunction<
    FetchHistoricYouTubeVideosMutation,
    FetchHistoricYouTubeVideosMutationVariables
>

/**
 * __useFetchHistoricYouTubeVideosMutation__
 *
 * To run a mutation, you first call `useFetchHistoricYouTubeVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchHistoricYouTubeVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchHistoricYouTubeVideosMutation, { data, loading, error }] = useFetchHistoricYouTubeVideosMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useFetchHistoricYouTubeVideosMutation(
    baseOptions?: Apollo.MutationHookOptions<
        FetchHistoricYouTubeVideosMutation,
        FetchHistoricYouTubeVideosMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        FetchHistoricYouTubeVideosMutation,
        FetchHistoricYouTubeVideosMutationVariables
    >(FetchHistoricYouTubeVideosDocument, options)
}
export type FetchHistoricYouTubeVideosMutationHookResult = ReturnType<
    typeof useFetchHistoricYouTubeVideosMutation
>
export type FetchHistoricYouTubeVideosMutationResult =
    Apollo.MutationResult<FetchHistoricYouTubeVideosMutation>
export type FetchHistoricYouTubeVideosMutationOptions =
    Apollo.BaseMutationOptions<
        FetchHistoricYouTubeVideosMutation,
        FetchHistoricYouTubeVideosMutationVariables
    >
export const AddTwitterSubscriptionDocument = gql`
    mutation AddTwitterSubscription($accountId: Int!) {
        addTwitterSubscription(accountId: $accountId)
    }
`
export type AddTwitterSubscriptionMutationFn = Apollo.MutationFunction<
    AddTwitterSubscriptionMutation,
    AddTwitterSubscriptionMutationVariables
>

/**
 * __useAddTwitterSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddTwitterSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTwitterSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTwitterSubscriptionMutation, { data, loading, error }] = useAddTwitterSubscriptionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAddTwitterSubscriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddTwitterSubscriptionMutation,
        AddTwitterSubscriptionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        AddTwitterSubscriptionMutation,
        AddTwitterSubscriptionMutationVariables
    >(AddTwitterSubscriptionDocument, options)
}
export type AddTwitterSubscriptionMutationHookResult = ReturnType<
    typeof useAddTwitterSubscriptionMutation
>
export type AddTwitterSubscriptionMutationResult =
    Apollo.MutationResult<AddTwitterSubscriptionMutation>
export type AddTwitterSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    AddTwitterSubscriptionMutation,
    AddTwitterSubscriptionMutationVariables
>
export const RemoveTwitterSubscriptionDocument = gql`
    mutation removeTwitterSubscription($accountId: Int!) {
        removeTwitterSubscription(accountId: $accountId)
    }
`
export type RemoveTwitterSubscriptionMutationFn = Apollo.MutationFunction<
    RemoveTwitterSubscriptionMutation,
    RemoveTwitterSubscriptionMutationVariables
>

/**
 * __useRemoveTwitterSubscriptionMutation__
 *
 * To run a mutation, you first call `useRemoveTwitterSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTwitterSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTwitterSubscriptionMutation, { data, loading, error }] = useRemoveTwitterSubscriptionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRemoveTwitterSubscriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveTwitterSubscriptionMutation,
        RemoveTwitterSubscriptionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        RemoveTwitterSubscriptionMutation,
        RemoveTwitterSubscriptionMutationVariables
    >(RemoveTwitterSubscriptionDocument, options)
}
export type RemoveTwitterSubscriptionMutationHookResult = ReturnType<
    typeof useRemoveTwitterSubscriptionMutation
>
export type RemoveTwitterSubscriptionMutationResult =
    Apollo.MutationResult<RemoveTwitterSubscriptionMutation>
export type RemoveTwitterSubscriptionMutationOptions =
    Apollo.BaseMutationOptions<
        RemoveTwitterSubscriptionMutation,
        RemoveTwitterSubscriptionMutationVariables
    >
export const UpdateScrapeContentDocument = gql`
    mutation UpdateScrapeContent($accountId: Int!, $scrapeContent: Boolean) {
        updateScrapeContent(
            accountId: $accountId
            scrapeContent: $scrapeContent
        )
    }
`
export type UpdateScrapeContentMutationFn = Apollo.MutationFunction<
    UpdateScrapeContentMutation,
    UpdateScrapeContentMutationVariables
>

/**
 * __useUpdateScrapeContentMutation__
 *
 * To run a mutation, you first call `useUpdateScrapeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScrapeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScrapeContentMutation, { data, loading, error }] = useUpdateScrapeContentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      scrapeContent: // value for 'scrapeContent'
 *   },
 * });
 */
export function useUpdateScrapeContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateScrapeContentMutation,
        UpdateScrapeContentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateScrapeContentMutation,
        UpdateScrapeContentMutationVariables
    >(UpdateScrapeContentDocument, options)
}
export type UpdateScrapeContentMutationHookResult = ReturnType<
    typeof useUpdateScrapeContentMutation
>
export type UpdateScrapeContentMutationResult =
    Apollo.MutationResult<UpdateScrapeContentMutation>
export type UpdateScrapeContentMutationOptions = Apollo.BaseMutationOptions<
    UpdateScrapeContentMutation,
    UpdateScrapeContentMutationVariables
>
export const CreatePaymentPortalDocument = gql`
    mutation createPaymentPortal($successUrl: String!, $cancelUrl: String!) {
        createPaymentPortal(successUrl: $successUrl, cancelUrl: $cancelUrl)
    }
`
export type CreatePaymentPortalMutationFn = Apollo.MutationFunction<
    CreatePaymentPortalMutation,
    CreatePaymentPortalMutationVariables
>

/**
 * __useCreatePaymentPortalMutation__
 *
 * To run a mutation, you first call `useCreatePaymentPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentPortalMutation, { data, loading, error }] = useCreatePaymentPortalMutation({
 *   variables: {
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useCreatePaymentPortalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePaymentPortalMutation,
        CreatePaymentPortalMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreatePaymentPortalMutation,
        CreatePaymentPortalMutationVariables
    >(CreatePaymentPortalDocument, options)
}
export type CreatePaymentPortalMutationHookResult = ReturnType<
    typeof useCreatePaymentPortalMutation
>
export type CreatePaymentPortalMutationResult =
    Apollo.MutationResult<CreatePaymentPortalMutation>
export type CreatePaymentPortalMutationOptions = Apollo.BaseMutationOptions<
    CreatePaymentPortalMutation,
    CreatePaymentPortalMutationVariables
>
export const AddYouTubeSubscriptionDocument = gql`
    mutation addYouTubeSubscription($channelId: String) {
        addYouTubeSubscription(channelId: $channelId) {
            success
        }
    }
`
export type AddYouTubeSubscriptionMutationFn = Apollo.MutationFunction<
    AddYouTubeSubscriptionMutation,
    AddYouTubeSubscriptionMutationVariables
>

/**
 * __useAddYouTubeSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddYouTubeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddYouTubeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addYouTubeSubscriptionMutation, { data, loading, error }] = useAddYouTubeSubscriptionMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAddYouTubeSubscriptionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddYouTubeSubscriptionMutation,
        AddYouTubeSubscriptionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        AddYouTubeSubscriptionMutation,
        AddYouTubeSubscriptionMutationVariables
    >(AddYouTubeSubscriptionDocument, options)
}
export type AddYouTubeSubscriptionMutationHookResult = ReturnType<
    typeof useAddYouTubeSubscriptionMutation
>
export type AddYouTubeSubscriptionMutationResult =
    Apollo.MutationResult<AddYouTubeSubscriptionMutation>
export type AddYouTubeSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    AddYouTubeSubscriptionMutation,
    AddYouTubeSubscriptionMutationVariables
>
export const AddDefaultKeywordsDocument = gql`
    mutation addDefaultKeywords($accountId: Int!, $reset: Boolean!) {
        addDefaultKeywords(accountId: $accountId, reset: $reset)
    }
`
export type AddDefaultKeywordsMutationFn = Apollo.MutationFunction<
    AddDefaultKeywordsMutation,
    AddDefaultKeywordsMutationVariables
>

/**
 * __useAddDefaultKeywordsMutation__
 *
 * To run a mutation, you first call `useAddDefaultKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDefaultKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDefaultKeywordsMutation, { data, loading, error }] = useAddDefaultKeywordsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      reset: // value for 'reset'
 *   },
 * });
 */
export function useAddDefaultKeywordsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddDefaultKeywordsMutation,
        AddDefaultKeywordsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        AddDefaultKeywordsMutation,
        AddDefaultKeywordsMutationVariables
    >(AddDefaultKeywordsDocument, options)
}
export type AddDefaultKeywordsMutationHookResult = ReturnType<
    typeof useAddDefaultKeywordsMutation
>
export type AddDefaultKeywordsMutationResult =
    Apollo.MutationResult<AddDefaultKeywordsMutation>
export type AddDefaultKeywordsMutationOptions = Apollo.BaseMutationOptions<
    AddDefaultKeywordsMutation,
    AddDefaultKeywordsMutationVariables
>
export const RefreshTokenDocument = gql`
    mutation refreshToken($accountId: Int!) {
        refreshToken(accountId: $accountId)
    }
`
export type RefreshTokenMutationFn = Apollo.MutationFunction<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRefreshTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
    >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
    typeof useRefreshTokenMutation
>
export type RefreshTokenMutationResult =
    Apollo.MutationResult<RefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>
export const RemoveItemFromSpamModelDocument = gql`
    mutation removeItemFromSpamModel($contentId: Int!) {
        removeItemFromSpamModel(contentId: $contentId)
    }
`
export type RemoveItemFromSpamModelMutationFn = Apollo.MutationFunction<
    RemoveItemFromSpamModelMutation,
    RemoveItemFromSpamModelMutationVariables
>

/**
 * __useRemoveItemFromSpamModelMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromSpamModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromSpamModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromSpamModelMutation, { data, loading, error }] = useRemoveItemFromSpamModelMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useRemoveItemFromSpamModelMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveItemFromSpamModelMutation,
        RemoveItemFromSpamModelMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        RemoveItemFromSpamModelMutation,
        RemoveItemFromSpamModelMutationVariables
    >(RemoveItemFromSpamModelDocument, options)
}
export type RemoveItemFromSpamModelMutationHookResult = ReturnType<
    typeof useRemoveItemFromSpamModelMutation
>
export type RemoveItemFromSpamModelMutationResult =
    Apollo.MutationResult<RemoveItemFromSpamModelMutation>
export type RemoveItemFromSpamModelMutationOptions = Apollo.BaseMutationOptions<
    RemoveItemFromSpamModelMutation,
    RemoveItemFromSpamModelMutationVariables
>
export const TestLlmClassificationPromptDocument = gql`
    query TestLlmClassificationPrompt($prompt: String!, $testMessage: String!) {
        testLlmClassificationPrompt(prompt: $prompt, testMessage: $testMessage)
    }
`

/**
 * __useTestLlmClassificationPromptQuery__
 *
 * To run a query within a React component, call `useTestLlmClassificationPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestLlmClassificationPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestLlmClassificationPromptQuery({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      testMessage: // value for 'testMessage'
 *   },
 * });
 */
export function useTestLlmClassificationPromptQuery(
    baseOptions: Apollo.QueryHookOptions<
        TestLlmClassificationPromptQuery,
        TestLlmClassificationPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        TestLlmClassificationPromptQuery,
        TestLlmClassificationPromptQueryVariables
    >(TestLlmClassificationPromptDocument, options)
}
export function useTestLlmClassificationPromptLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TestLlmClassificationPromptQuery,
        TestLlmClassificationPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        TestLlmClassificationPromptQuery,
        TestLlmClassificationPromptQueryVariables
    >(TestLlmClassificationPromptDocument, options)
}
export type TestLlmClassificationPromptQueryHookResult = ReturnType<
    typeof useTestLlmClassificationPromptQuery
>
export type TestLlmClassificationPromptLazyQueryHookResult = ReturnType<
    typeof useTestLlmClassificationPromptLazyQuery
>
export type TestLlmClassificationPromptQueryResult = Apollo.QueryResult<
    TestLlmClassificationPromptQuery,
    TestLlmClassificationPromptQueryVariables
>
export const GetCustomerPaymentPortalUrlDocument = gql`
    query getCustomerPaymentPortalUrl($returnUrl: String!) {
        getCustomerPaymentPortalUrl(returnUrl: $returnUrl) {
            url
        }
    }
`

/**
 * __useGetCustomerPaymentPortalUrlQuery__
 *
 * To run a query within a React component, call `useGetCustomerPaymentPortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPaymentPortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPaymentPortalUrlQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useGetCustomerPaymentPortalUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetCustomerPaymentPortalUrlQuery,
        GetCustomerPaymentPortalUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetCustomerPaymentPortalUrlQuery,
        GetCustomerPaymentPortalUrlQueryVariables
    >(GetCustomerPaymentPortalUrlDocument, options)
}
export function useGetCustomerPaymentPortalUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCustomerPaymentPortalUrlQuery,
        GetCustomerPaymentPortalUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetCustomerPaymentPortalUrlQuery,
        GetCustomerPaymentPortalUrlQueryVariables
    >(GetCustomerPaymentPortalUrlDocument, options)
}
export type GetCustomerPaymentPortalUrlQueryHookResult = ReturnType<
    typeof useGetCustomerPaymentPortalUrlQuery
>
export type GetCustomerPaymentPortalUrlLazyQueryHookResult = ReturnType<
    typeof useGetCustomerPaymentPortalUrlLazyQuery
>
export type GetCustomerPaymentPortalUrlQueryResult = Apollo.QueryResult<
    GetCustomerPaymentPortalUrlQuery,
    GetCustomerPaymentPortalUrlQueryVariables
>
export const GetYouTubeSubscriptionDiagnosticUrlDocument = gql`
    query getYouTubeSubscriptionDiagnosticUrl($channelId: String) {
        getYouTubeSubscriptionDiagnosticUrl(channelId: $channelId) {
            url
        }
    }
`

/**
 * __useGetYouTubeSubscriptionDiagnosticUrlQuery__
 *
 * To run a query within a React component, call `useGetYouTubeSubscriptionDiagnosticUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYouTubeSubscriptionDiagnosticUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYouTubeSubscriptionDiagnosticUrlQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetYouTubeSubscriptionDiagnosticUrlQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetYouTubeSubscriptionDiagnosticUrlQuery,
        GetYouTubeSubscriptionDiagnosticUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetYouTubeSubscriptionDiagnosticUrlQuery,
        GetYouTubeSubscriptionDiagnosticUrlQueryVariables
    >(GetYouTubeSubscriptionDiagnosticUrlDocument, options)
}
export function useGetYouTubeSubscriptionDiagnosticUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetYouTubeSubscriptionDiagnosticUrlQuery,
        GetYouTubeSubscriptionDiagnosticUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetYouTubeSubscriptionDiagnosticUrlQuery,
        GetYouTubeSubscriptionDiagnosticUrlQueryVariables
    >(GetYouTubeSubscriptionDiagnosticUrlDocument, options)
}
export type GetYouTubeSubscriptionDiagnosticUrlQueryHookResult = ReturnType<
    typeof useGetYouTubeSubscriptionDiagnosticUrlQuery
>
export type GetYouTubeSubscriptionDiagnosticUrlLazyQueryHookResult = ReturnType<
    typeof useGetYouTubeSubscriptionDiagnosticUrlLazyQuery
>
export type GetYouTubeSubscriptionDiagnosticUrlQueryResult = Apollo.QueryResult<
    GetYouTubeSubscriptionDiagnosticUrlQuery,
    GetYouTubeSubscriptionDiagnosticUrlQueryVariables
>
export const GetClassifierDetailsDocument = gql`
    query getClassifierDetails {
        getClassifierDetails {
            id
            shortName
            displayName
            shortDescription
            longDescription
        }
    }
`

/**
 * __useGetClassifierDetailsQuery__
 *
 * To run a query within a React component, call `useGetClassifierDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassifierDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassifierDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassifierDetailsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetClassifierDetailsQuery,
        GetClassifierDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetClassifierDetailsQuery,
        GetClassifierDetailsQueryVariables
    >(GetClassifierDetailsDocument, options)
}
export function useGetClassifierDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetClassifierDetailsQuery,
        GetClassifierDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetClassifierDetailsQuery,
        GetClassifierDetailsQueryVariables
    >(GetClassifierDetailsDocument, options)
}
export type GetClassifierDetailsQueryHookResult = ReturnType<
    typeof useGetClassifierDetailsQuery
>
export type GetClassifierDetailsLazyQueryHookResult = ReturnType<
    typeof useGetClassifierDetailsLazyQuery
>
export type GetClassifierDetailsQueryResult = Apollo.QueryResult<
    GetClassifierDetailsQuery,
    GetClassifierDetailsQueryVariables
>
export const GetSocialMediaServicesDocument = gql`
    query getSocialMediaServices {
        getSocialMediaServices {
            id
            displayName
            shortName
            shortDescription
            longDescription
        }
    }
`

/**
 * __useGetSocialMediaServicesQuery__
 *
 * To run a query within a React component, call `useGetSocialMediaServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialMediaServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSocialMediaServicesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetSocialMediaServicesQuery,
        GetSocialMediaServicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetSocialMediaServicesQuery,
        GetSocialMediaServicesQueryVariables
    >(GetSocialMediaServicesDocument, options)
}
export function useGetSocialMediaServicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSocialMediaServicesQuery,
        GetSocialMediaServicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetSocialMediaServicesQuery,
        GetSocialMediaServicesQueryVariables
    >(GetSocialMediaServicesDocument, options)
}
export type GetSocialMediaServicesQueryHookResult = ReturnType<
    typeof useGetSocialMediaServicesQuery
>
export type GetSocialMediaServicesLazyQueryHookResult = ReturnType<
    typeof useGetSocialMediaServicesLazyQuery
>
export type GetSocialMediaServicesQueryResult = Apollo.QueryResult<
    GetSocialMediaServicesQuery,
    GetSocialMediaServicesQueryVariables
>
export const CountContentDocument = gql`
    query CountContent(
        $startDateTime: AWSDateTime!
        $endDateTime: AWSDateTime!
        $filter: ContentFilterInput
        $keyword: String
    ) {
        countContent(
            filter: $filter
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            keyword: $keyword
        ) {
            total
        }
    }
`

/**
 * __useCountContentQuery__
 *
 * To run a query within a React component, call `useCountContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountContentQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      filter: // value for 'filter'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useCountContentQuery(
    baseOptions: Apollo.QueryHookOptions<
        CountContentQuery,
        CountContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CountContentQuery, CountContentQueryVariables>(
        CountContentDocument,
        options
    )
}
export function useCountContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CountContentQuery,
        CountContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<CountContentQuery, CountContentQueryVariables>(
        CountContentDocument,
        options
    )
}
export type CountContentQueryHookResult = ReturnType<
    typeof useCountContentQuery
>
export type CountContentLazyQueryHookResult = ReturnType<
    typeof useCountContentLazyQuery
>
export type CountContentQueryResult = Apollo.QueryResult<
    CountContentQuery,
    CountContentQueryVariables
>
export const FetchUserDetailsDocument = gql`
    query FetchUserDetails(
        $socialMediaService: SocialMediaServiceEnum!
        $serviceAccountUsername: String!
    ) {
        fetchUserDetails(
            socialMediaService: $socialMediaService
            serviceAccountUsername: $serviceAccountUsername
        ) {
            error {
                message
                type
            }
            userDetails {
                username
                serviceAccountId
                name
            }
        }
    }
`

/**
 * __useFetchUserDetailsQuery__
 *
 * To run a query within a React component, call `useFetchUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserDetailsQuery({
 *   variables: {
 *      socialMediaService: // value for 'socialMediaService'
 *      serviceAccountUsername: // value for 'serviceAccountUsername'
 *   },
 * });
 */
export function useFetchUserDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchUserDetailsQuery,
        FetchUserDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        FetchUserDetailsQuery,
        FetchUserDetailsQueryVariables
    >(FetchUserDetailsDocument, options)
}
export function useFetchUserDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUserDetailsQuery,
        FetchUserDetailsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        FetchUserDetailsQuery,
        FetchUserDetailsQueryVariables
    >(FetchUserDetailsDocument, options)
}
export type FetchUserDetailsQueryHookResult = ReturnType<
    typeof useFetchUserDetailsQuery
>
export type FetchUserDetailsLazyQueryHookResult = ReturnType<
    typeof useFetchUserDetailsLazyQuery
>
export type FetchUserDetailsQueryResult = Apollo.QueryResult<
    FetchUserDetailsQuery,
    FetchUserDetailsQueryVariables
>
export const GetClassificationResultsDocument = gql`
    query GetClassificationResults($contentId: Int) {
        getClassificationResults(contentId: $contentId) {
            ...ClassificationResultItem
        }
    }
    ${ClassificationResultItemFragmentDoc}
`

/**
 * __useGetClassificationResultsQuery__
 *
 * To run a query within a React component, call `useGetClassificationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationResultsQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetClassificationResultsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetClassificationResultsQuery,
        GetClassificationResultsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetClassificationResultsQuery,
        GetClassificationResultsQueryVariables
    >(GetClassificationResultsDocument, options)
}
export function useGetClassificationResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetClassificationResultsQuery,
        GetClassificationResultsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetClassificationResultsQuery,
        GetClassificationResultsQueryVariables
    >(GetClassificationResultsDocument, options)
}
export type GetClassificationResultsQueryHookResult = ReturnType<
    typeof useGetClassificationResultsQuery
>
export type GetClassificationResultsLazyQueryHookResult = ReturnType<
    typeof useGetClassificationResultsLazyQuery
>
export type GetClassificationResultsQueryResult = Apollo.QueryResult<
    GetClassificationResultsQuery,
    GetClassificationResultsQueryVariables
>
export const GetManyClassificationResultsDocument = gql`
    query GetManyClassificationResults($contentIds: [Int!]!) {
        getManyClassificationResults(contentIds: $contentIds) {
            ...ClassificationResultItem
        }
    }
    ${ClassificationResultItemFragmentDoc}
`

/**
 * __useGetManyClassificationResultsQuery__
 *
 * To run a query within a React component, call `useGetManyClassificationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyClassificationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyClassificationResultsQuery({
 *   variables: {
 *      contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useGetManyClassificationResultsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetManyClassificationResultsQuery,
        GetManyClassificationResultsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetManyClassificationResultsQuery,
        GetManyClassificationResultsQueryVariables
    >(GetManyClassificationResultsDocument, options)
}
export function useGetManyClassificationResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetManyClassificationResultsQuery,
        GetManyClassificationResultsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetManyClassificationResultsQuery,
        GetManyClassificationResultsQueryVariables
    >(GetManyClassificationResultsDocument, options)
}
export type GetManyClassificationResultsQueryHookResult = ReturnType<
    typeof useGetManyClassificationResultsQuery
>
export type GetManyClassificationResultsLazyQueryHookResult = ReturnType<
    typeof useGetManyClassificationResultsLazyQuery
>
export type GetManyClassificationResultsQueryResult = Apollo.QueryResult<
    GetManyClassificationResultsQuery,
    GetManyClassificationResultsQueryVariables
>
export const GetAuthorisationUrlDocument = gql`
    query GetAuthorisationUrl(
        $socialMediaService: SocialMediaServiceEnum!
        $accountId: Int!
        $baseUrl: String!
    ) {
        getAuthorisationUrl(
            socialMediaService: $socialMediaService
            accountId: $accountId
            baseUrl: $baseUrl
        )
    }
`

/**
 * __useGetAuthorisationUrlQuery__
 *
 * To run a query within a React component, call `useGetAuthorisationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorisationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorisationUrlQuery({
 *   variables: {
 *      socialMediaService: // value for 'socialMediaService'
 *      accountId: // value for 'accountId'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useGetAuthorisationUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAuthorisationUrlQuery,
        GetAuthorisationUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetAuthorisationUrlQuery,
        GetAuthorisationUrlQueryVariables
    >(GetAuthorisationUrlDocument, options)
}
export function useGetAuthorisationUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAuthorisationUrlQuery,
        GetAuthorisationUrlQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetAuthorisationUrlQuery,
        GetAuthorisationUrlQueryVariables
    >(GetAuthorisationUrlDocument, options)
}
export type GetAuthorisationUrlQueryHookResult = ReturnType<
    typeof useGetAuthorisationUrlQuery
>
export type GetAuthorisationUrlLazyQueryHookResult = ReturnType<
    typeof useGetAuthorisationUrlLazyQuery
>
export type GetAuthorisationUrlQueryResult = Apollo.QueryResult<
    GetAuthorisationUrlQuery,
    GetAuthorisationUrlQueryVariables
>
export const IsSubscribedDocument = gql`
    query IsSubscribed($accountId: Int!) {
        isSubscribed(accountId: $accountId)
    }
`

/**
 * __useIsSubscribedQuery__
 *
 * To run a query within a React component, call `useIsSubscribedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSubscribedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSubscribedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useIsSubscribedQuery(
    baseOptions: Apollo.QueryHookOptions<
        IsSubscribedQuery,
        IsSubscribedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<IsSubscribedQuery, IsSubscribedQueryVariables>(
        IsSubscribedDocument,
        options
    )
}
export function useIsSubscribedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        IsSubscribedQuery,
        IsSubscribedQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<IsSubscribedQuery, IsSubscribedQueryVariables>(
        IsSubscribedDocument,
        options
    )
}
export type IsSubscribedQueryHookResult = ReturnType<
    typeof useIsSubscribedQuery
>
export type IsSubscribedLazyQueryHookResult = ReturnType<
    typeof useIsSubscribedLazyQuery
>
export type IsSubscribedQueryResult = Apollo.QueryResult<
    IsSubscribedQuery,
    IsSubscribedQueryVariables
>
export const GetUserSubscriptionDocument = gql`
    query GetUserSubscription($referrer: String) {
        getUserSubscription(referrer: $referrer) {
            ...User
        }
    }
    ${UserFragmentDoc}
`

/**
 * __useGetUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionQuery({
 *   variables: {
 *      referrer: // value for 'referrer'
 *   },
 * });
 */
export function useGetUserSubscriptionQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetUserSubscriptionQuery,
        GetUserSubscriptionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetUserSubscriptionQuery,
        GetUserSubscriptionQueryVariables
    >(GetUserSubscriptionDocument, options)
}
export function useGetUserSubscriptionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserSubscriptionQuery,
        GetUserSubscriptionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetUserSubscriptionQuery,
        GetUserSubscriptionQueryVariables
    >(GetUserSubscriptionDocument, options)
}
export type GetUserSubscriptionQueryHookResult = ReturnType<
    typeof useGetUserSubscriptionQuery
>
export type GetUserSubscriptionLazyQueryHookResult = ReturnType<
    typeof useGetUserSubscriptionLazyQuery
>
export type GetUserSubscriptionQueryResult = Apollo.QueryResult<
    GetUserSubscriptionQuery,
    GetUserSubscriptionQueryVariables
>
export const GetTeamDocument = gql`
    query GetTeam($teamId: Int!) {
        getTeam(teamId: $teamId) {
            id
            name
            createdAt
            updatedAt
            users {
                id
                username
            }
            accounts {
                accountName
                socialMediaService {
                    displayName
                }
            }
        }
    }
`

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamQuery(
    baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(
        GetTeamDocument,
        options
    )
}
export function useGetTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTeamQuery,
        GetTeamQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(
        GetTeamDocument,
        options
    )
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>
export type GetTeamQueryResult = Apollo.QueryResult<
    GetTeamQuery,
    GetTeamQueryVariables
>
export const GetLabelledSpamDocument = gql`
    query GetLabelledSpam(
        $message: String
        $platform: String
        $threshold: Float
    ) {
        getLabelledSpam(
            message: $message
            platform: $platform
            threshold: $threshold
        ) {
            ...SpamSearch
        }
    }
    ${SpamSearchFragmentDoc}
`

/**
 * __useGetLabelledSpamQuery__
 *
 * To run a query within a React component, call `useGetLabelledSpamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelledSpamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelledSpamQuery({
 *   variables: {
 *      message: // value for 'message'
 *      platform: // value for 'platform'
 *      threshold: // value for 'threshold'
 *   },
 * });
 */
export function useGetLabelledSpamQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetLabelledSpamQuery,
        GetLabelledSpamQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetLabelledSpamQuery, GetLabelledSpamQueryVariables>(
        GetLabelledSpamDocument,
        options
    )
}
export function useGetLabelledSpamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLabelledSpamQuery,
        GetLabelledSpamQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLabelledSpamQuery,
        GetLabelledSpamQueryVariables
    >(GetLabelledSpamDocument, options)
}
export type GetLabelledSpamQueryHookResult = ReturnType<
    typeof useGetLabelledSpamQuery
>
export type GetLabelledSpamLazyQueryHookResult = ReturnType<
    typeof useGetLabelledSpamLazyQuery
>
export type GetLabelledSpamQueryResult = Apollo.QueryResult<
    GetLabelledSpamQuery,
    GetLabelledSpamQueryVariables
>
export const EnrichContentDocument = gql`
    query enrichContent($contents: [ContentToEnrich!]!) {
        enrichContent(contents: $contents) {
            ...EnrichedContentItem
        }
    }
    ${EnrichedContentItemFragmentDoc}
`

/**
 * __useEnrichContentQuery__
 *
 * To run a query within a React component, call `useEnrichContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrichContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrichContentQuery({
 *   variables: {
 *      contents: // value for 'contents'
 *   },
 * });
 */
export function useEnrichContentQuery(
    baseOptions: Apollo.QueryHookOptions<
        EnrichContentQuery,
        EnrichContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<EnrichContentQuery, EnrichContentQueryVariables>(
        EnrichContentDocument,
        options
    )
}
export function useEnrichContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EnrichContentQuery,
        EnrichContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<EnrichContentQuery, EnrichContentQueryVariables>(
        EnrichContentDocument,
        options
    )
}
export type EnrichContentQueryHookResult = ReturnType<
    typeof useEnrichContentQuery
>
export type EnrichContentLazyQueryHookResult = ReturnType<
    typeof useEnrichContentLazyQuery
>
export type EnrichContentQueryResult = Apollo.QueryResult<
    EnrichContentQuery,
    EnrichContentQueryVariables
>
export const GetSuggestedRepliesDocument = gql`
    query getSuggestedReplies($contentId: Int!, $numberOfSuggestions: Int!) {
        getSuggestedReplies(
            contentId: $contentId
            numberOfSuggestions: $numberOfSuggestions
        )
    }
`

/**
 * __useGetSuggestedRepliesQuery__
 *
 * To run a query within a React component, call `useGetSuggestedRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedRepliesQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      numberOfSuggestions: // value for 'numberOfSuggestions'
 *   },
 * });
 */
export function useGetSuggestedRepliesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetSuggestedRepliesQuery,
        GetSuggestedRepliesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetSuggestedRepliesQuery,
        GetSuggestedRepliesQueryVariables
    >(GetSuggestedRepliesDocument, options)
}
export function useGetSuggestedRepliesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSuggestedRepliesQuery,
        GetSuggestedRepliesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetSuggestedRepliesQuery,
        GetSuggestedRepliesQueryVariables
    >(GetSuggestedRepliesDocument, options)
}
export type GetSuggestedRepliesQueryHookResult = ReturnType<
    typeof useGetSuggestedRepliesQuery
>
export type GetSuggestedRepliesLazyQueryHookResult = ReturnType<
    typeof useGetSuggestedRepliesLazyQuery
>
export type GetSuggestedRepliesQueryResult = Apollo.QueryResult<
    GetSuggestedRepliesQuery,
    GetSuggestedRepliesQueryVariables
>
export const TestLlmReplyPromptDocument = gql`
    query TestLlmReplyPrompt(
        $prompt: String!
        $testMessage: String!
        $numberOfSuggestions: Int
    ) {
        testLlmReplyPrompt(
            prompt: $prompt
            testMessage: $testMessage
            numberOfSuggestions: $numberOfSuggestions
        )
    }
`

/**
 * __useTestLlmReplyPromptQuery__
 *
 * To run a query within a React component, call `useTestLlmReplyPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestLlmReplyPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestLlmReplyPromptQuery({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      testMessage: // value for 'testMessage'
 *      numberOfSuggestions: // value for 'numberOfSuggestions'
 *   },
 * });
 */
export function useTestLlmReplyPromptQuery(
    baseOptions: Apollo.QueryHookOptions<
        TestLlmReplyPromptQuery,
        TestLlmReplyPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        TestLlmReplyPromptQuery,
        TestLlmReplyPromptQueryVariables
    >(TestLlmReplyPromptDocument, options)
}
export function useTestLlmReplyPromptLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TestLlmReplyPromptQuery,
        TestLlmReplyPromptQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        TestLlmReplyPromptQuery,
        TestLlmReplyPromptQueryVariables
    >(TestLlmReplyPromptDocument, options)
}
export type TestLlmReplyPromptQueryHookResult = ReturnType<
    typeof useTestLlmReplyPromptQuery
>
export type TestLlmReplyPromptLazyQueryHookResult = ReturnType<
    typeof useTestLlmReplyPromptLazyQuery
>
export type TestLlmReplyPromptQueryResult = Apollo.QueryResult<
    TestLlmReplyPromptQuery,
    TestLlmReplyPromptQueryVariables
>
