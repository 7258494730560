import clsx from 'clsx'
import _ from 'lodash'
import {
    ClassifierSearchParamsView,
    ContentFeedView,
    useDeleteContentFeedMutation,
    useSearchContentOutcomeCountQuery
} from '../../api/client'
import { toastError } from '../Notification'
import { ContentFeedKPILoadingSkeleton } from './ContentFeedLoadingSkeleton'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'

type Props = {
    contentFeed: ContentFeedView
    // We need to let the parent component know when we're deleted
    onDeleted: (id: number) => void
}

/**
 * Displays a ContentFeed as a Card
 */
export default function ContentFeedCard({ contentFeed, onDeleted }: Props) {
    // This is a little messy. The searchParams model is from the API and includes the __typename property used by GraphQL
    // When using this as a search parameter for the count this throws an error as __typename is not a valid property to
    // include in the query.
    // To resolve this we clondDeep (which includes nested objects) and remove the __typename property from the root object
    // and also from the child objects, in this case just the classifier definitions.
    const params = _.cloneDeep(contentFeed.searchParameters)
    delete params.__typename

    if (
        params.classifierSearchParams &&
        Array.isArray(params.classifierSearchParams)
    ) {
        params.classifierSearchParams.forEach(
            (param: ClassifierSearchParamsView) => {
                if (param && typeof param === 'object' && param.__typename) {
                    delete param.__typename
                }
            }
        )
    }

    const { data: countData, loading: countLoading } =
        useSearchContentOutcomeCountQuery({
            fetchPolicy: 'no-cache',
            variables: {
                params
            },
            onError: (error) => {
                console.error("Couldn't fetch content count", error)
                toastError('There was an error fetching feed metrics.')
            }
        })

    const [deleteContentFeedMutation] = useDeleteContentFeedMutation({
        onError: (error) => {
            toastError(error.message)
        }
    })

    // State to track which dashboard is being deleted
    const [deletingContentFeedId, setDeletingContentFeedId] =
        useState<number | null>(null)

    const handleDelete = async (contentFeedId: number) => {
        setDeletingContentFeedId(contentFeedId)
        try {
            await deleteContentFeedMutation({
                variables: { contentFeedId }
            })
            onDeleted(contentFeedId)
        } finally {
            setDeletingContentFeedId(null)
        }
    }

    const total = countData?.searchContentOutcomeCount || 0
    // We use a link rather than an onClick handler + navigate because a link allows
    // our users to 'open in a new tab'.
    return (
        <>
            <Link
                key={contentFeed.id}
                to={`content-search/feed/${contentFeed.id}`}
                className="w-60"
            >
                <div
                    className={clsx(
                        'bg-white cursor-pointer no-underline',
                        'prose prose-stone prose-lg',
                        'py-6 px-8',
                        'block border border-gray-200 rounded-lg shadow-md transition-shadow ',
                        'hover:shadow-lg'
                    )}
                >
                    <dl>
                        <dt className="text  text-gray-900 truncate pb-2">
                            {contentFeed.displayName}
                        </dt>
                        <dd>
                            <div className="text-lg font-medium text-gray-900">
                                <div className="flex flex-row items-center justify-between w-full">
                                    {countLoading ? (
                                        <ContentFeedKPILoadingSkeleton />
                                    ) : (
                                        <>{total}</>
                                    )}
                                    {deletingContentFeedId ===
                                    contentFeed.id ? (
                                        <div className="h-5 w-5 border-2 border-gray-300 border-t-primary-500 rounded-full animate-spin"></div>
                                    ) : (
                                        <TrashIcon
                                            className="h-5 w-5 text-gray-600 hover:text-primary-700 cursor-pointer flex-shrink-0"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                handleDelete(contentFeed.id)
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </Link>
        </>
    )
}
